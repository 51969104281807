import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Collapse,
  Toolbar,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  Add,
  Category,
  LocalMall,
  Receipt,
  Storefront,
  Business,
  ManageAccounts,
  Settings,
  ShoppingCart,
  Report,
  Dashboard as DashboardIcon,
  ViewList,
  Undo, // For Sales Return
  SwapHoriz,
  Shop2Outlined, // For Stock Transfer
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

function Sidebar({ role, username }) {
  const [openSales, setOpenSales] = useState(false);
  const [openOperations, setOpenOperations] = useState(false);
  const [openStore, setOpenStore] = useState(false);
  const [openSalesPeople, setOpenSalesPeople] = useState(false);

  const closeAll = () => {
    setOpenSales(false);
    setOpenOperations(false);
    setOpenStore(false);
    setOpenSalesPeople(false);
  };

  const toggleSales = () => {
    closeAll();
    setOpenSales(!openSales);
  };

  const toggleOperations = () => {
    closeAll();
    setOpenOperations(!openOperations);
  };

  const toggleStore = () => {
    closeAll();
    setOpenStore(!openStore);
  };

  const toggleSalesPeople = () => {
    closeAll();
    setOpenSalesPeople(!openSalesPeople);
  };

  const isAdmin = role === 'admin';

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <div
        style={{
          padding: '16px',
          textAlign: 'center',
          backgroundColor: '#f7f7f7',
          minHeight: '100vh',
          overflow: 'auto',
        }}
      >
        <div
          style={{
            backgroundColor: '#388E3C',
            color: 'white',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '24px',
          }}
        >
          {username.charAt(0).toUpperCase()}
        </div>
        <h3 style={{ color: '#424242' }}>{username}</h3>
        <Divider />
        <List>
          <ListItem button component={Link} to={isAdmin ? "/dashboard" : "/userdashboard"}>
            <ListItemIcon>
              <DashboardIcon style={{ color: '#388E3C' }} />
            </ListItemIcon>
            <ListItemText primary="Dashboard" style={{ color: '#424242' }} />
          </ListItem>

          <ListItem button onClick={toggleSales}>
            <ListItemIcon>
              <ShoppingCart style={{ color: '#388E3C' }} />
            </ListItemIcon>
            <ListItemText primary="Sales" style={{ color: '#424242' }} />
            {openSales ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSales} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/sales">
                <ListItemIcon>
                  <ShoppingCart style={{ color: '#424242' }} />
                </ListItemIcon>
                <ListItemText primary="POS" style={{ color: '#424242' }} />
              </ListItem>
              <ListItem button component={Link} to="/sales-return">
                <ListItemIcon>
                  <Undo style={{ color: '#424242' }} />
                </ListItemIcon>
                <ListItemText primary="Sales Return" style={{ color: '#424242' }} />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={toggleOperations}>
            <ListItemIcon>
              <Business style={{ color: '#388E3C' }} />
            </ListItemIcon>
            <ListItemText primary="Operations" style={{ color: '#424242' }} />
            {openOperations ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openOperations} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {isAdmin && (
                <>
                  <ListItem button component={Link} to="/add-category">
                    <ListItemIcon>
                      <Category style={{ color: '#424242' }} />
                    </ListItemIcon>
                    <ListItemText primary="Create Category" style={{ color: '#424242' }} />
                  </ListItem>
                  <ListItem button component={Link} to="/create-product">
                    <ListItemIcon>
                      <LocalMall style={{ color: '#424242' }} />
                    </ListItemIcon>
                    <ListItemText primary="Add Product" style={{ color: '#424242' }} />
                  </ListItem>
                  <ListItem button component={Link} to="/receive-product">
                    <ListItemIcon>
                      <Receipt style={{ color: '#424242' }} />
                    </ListItemIcon>
                    <ListItemText primary="Receive Products" style={{ color: '#424242' }} />
                  </ListItem>
                  <ListItem button component={Link} to="/edit-product">
                    <ListItemIcon>
                      <Settings style={{ color: '#424242' }} />
                    </ListItemIcon>
                    <ListItemText primary="Edit Products" style={{ color: '#424242' }} />
                  </ListItem>
                </>
              )}
              <ListItem button component={Link} to="/view-product">
                <ListItemIcon>
                  <ViewList style={{ color: '#424242' }} />
                </ListItemIcon>
                <ListItemText primary="View Products" style={{ color: '#424242' }} />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={toggleStore}>
            <ListItemIcon>
              <Storefront style={{ color: '#388E3C' }} />
            </ListItemIcon>
            <ListItemText primary="Shop" style={{ color: '#424242' }} />
            {openStore ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openStore} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
            {isAdmin && (
                <ListItem button component={Link} to="/add-shop">
                  <ListItemIcon>
                    <Shop2Outlined style={{ color: '#424242' }} />
                  </ListItemIcon>
                  <ListItemText primary="Add Shop" style={{ color: '#424242' }} />
                </ListItem>
              )}
              <ListItem button component={Link} to="/approve-products">
                <ListItemIcon>
                  <Add style={{ color: '#424242' }} />
                </ListItemIcon>
                <ListItemText primary="Approve Products" style={{ color: '#424242' }} />
              </ListItem>
              {isAdmin && (
                <ListItem button component={Link} to="/stock-transfer">
                  <ListItemIcon>
                    <SwapHoriz style={{ color: '#424242' }} />
                  </ListItemIcon>
                  <ListItemText primary="Stock Transfer" style={{ color: '#424242' }} />
                </ListItem>
              )}
            </List>
          </Collapse>

          {isAdmin && (
            <ListItem button onClick={toggleSalesPeople}>
              <ListItemIcon>
                <ManageAccounts style={{ color: '#388E3C' }} />
              </ListItemIcon>
              <ListItemText primary="Employee" style={{ color: '#424242' }} />
              {openSalesPeople ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          )}
          <Collapse in={openSalesPeople} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/add-salesperson">
                <ListItemIcon>
                  <Add style={{ color: '#424242' }} />
                </ListItemIcon>
                <ListItemText primary="Add Employee" style={{ color: '#424242' }} />
              </ListItem>
              <ListItem button component={Link} to="/manage-salespeople">
                <ListItemIcon>
                  <ManageAccounts style={{ color: '#424242' }} />
                </ListItemIcon>
                <ListItemText primary="Manage Employee" style={{ color: '#424242' }} />
              </ListItem>
              <ListItem button component={Link} to="/view-employees">
                <ListItemIcon>
                  <ManageAccounts style={{ color: '#424242' }} />
                </ListItemIcon>
                <ListItemText primary="View Employee" style={{ color: '#2C9B5EFF' }} />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button component={Link} to="/reports">
            <ListItemIcon>
              <Report style={{ color: '#388E3C' }} />
            </ListItemIcon>
            <ListItemText primary="Reports" style={{ color: '#424242' }} />
          </ListItem>

          {isAdmin && (
            <ListItem button component={Link} to="/system-settings">
              <ListItemIcon>
                <Settings style={{ color: '#388E3C' }} />
              </ListItemIcon>
              <ListItemText primary="Settings" style={{ color: '#424242' }} />
            </ListItem>
          )}
        </List>
      </div>
    </Drawer>
  );
}

export default Sidebar;
