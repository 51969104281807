import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { getDatabase, ref, onValue } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getFirestore,where,query, doc, getDoc, collection, getDocs } from "firebase/firestore";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, CssBaseline, Switch, FormControlLabel, IconButton, Typography, TextField, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';

const getMuiTheme = (darkMode) => createTheme({
  palette: {
    mode: darkMode ? 'dark' : 'light',
    primary: {
      main: '#4CAF50',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          backgroundColor: '#4CAF50',
          '&:hover': {
            backgroundColor: '#388E3C',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px 8px',
          fontSize: '0.725rem',
        },
        head: {
          fontSize: '0.78rem',
          backgroundColor: darkMode ? '#000000' : '#ffffff',
          color: darkMode ? '#FFFFFF' : '#000000',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: darkMode ? '#333333' : '#f5f5f5',
          },
          '&:nth-of-type(even)': {
            backgroundColor: darkMode ? '#444444' : '#ffffff',
          },
        },
      },
    },
  },
});

const MpesaTillPayments = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showSelection, setShowSelection] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [role,setrole] = useState('');
  const [shopNames, setShopNames] = useState([]);
  const [selectedShop, setSelectedShop] = useState('');

  useEffect(() => {
    const fetchRoleAndShop = async () => {
      const auth = getAuth();
      const db = getFirestore();
      const user = auth.currentUser;
  
      if (!user) return;
  
      try {
        const q = query(collection(db, "employees"), where("email", "==", user.email));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0]; // Assuming email is unique, so taking the first doc
          const { role, assignedShop } = userDoc.data();
          fetchData(role, assignedShop);
          fetchShopNames();
          setrole(role);
        }
      } catch (error) {
        console.error("Error fetching role and shop:", error);
      }
    };
  
    fetchRoleAndShop();
  }, []);
  

  const fetchShopNames = async () => {
    const db = getFirestore();
    const shopDocs = await getDocs(collection(db, "shoplist"));
    const shopNamesArray = shopDocs.docs.map(doc => doc.data().shopName);
    setShopNames(shopNamesArray);
  };

  const fetchData = async (role, assignedShop) => {
    const db = getDatabase();
    const dbRef = ref(db, 'transactions');

    onValue(dbRef, (snapshot) => {
      const transactions = snapshot.val();
      if (transactions) {
        let filteredTransactions = Object.keys(transactions)
          .map(key => ({ id: key, ...transactions[key] }));

        if (role === 'shopmanager') {
          filteredTransactions = filteredTransactions.filter(
            transaction => transaction.resultCode === 0 && transaction.shopname === assignedShop
          );
        }else if(role === 'admin'){
          filteredTransactions = filteredTransactions.filter(
            transaction => transaction.resultCode === 0 
          );

        }

        setData(filteredTransactions);
      }
    });
  };

  useEffect(() => {
    let filtered = data || [];
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999);
      filtered = filtered.filter(item => {
        if (!item.transactionDate) return false;

        const value = item.transactionDate.toString();
        const year = parseInt(value.substring(0, 4), 10);
        const month = parseInt(value.substring(4, 6), 10) - 1;
        const day = parseInt(value.substring(6, 8), 10);
        const hour = parseInt(value.substring(8, 10), 10);
        const minute = parseInt(value.substring(10, 12), 10);
        const second = parseInt(value.substring(12, 14), 10);
        const date = new Date(year, month, day, hour, minute, second);

        return date >= start && date <= end;
      });
    }
    if (selectedShop) {
      filtered = filtered.filter(item => item.shopname === selectedShop);
    }
    if (searchTerm) {
      filtered = filtered.filter(item => {
        return Object.values(item).some(val => val && val.toString().toLowerCase().includes(searchTerm.toLowerCase()));
      });
    }
    setFilteredData(filtered);

    const totalAmount = filtered.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
    setTotalAmount(totalAmount);
  }, [data, startDate, endDate, searchTerm, selectedShop]);

  const columns = [
    {
      name: "index",
      label: "#",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => tableMeta.rowIndex + 1
      }
    },
    {
      name: "transactionDate",
      label: "Datetime",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (!value) return "";
          value = value.toString();
          const year = parseInt(value.substring(0, 4), 10);
          const month = parseInt(value.substring(4, 6), 10) - 1;
          const day = parseInt(value.substring(6, 8), 10);
          const hour = parseInt(value.substring(8, 10), 10);
          const minute = parseInt(value.substring(10, 12), 10);
          const second = parseInt(value.substring(12, 14), 10);
          const date = new Date(year, month, day, hour, minute, second);

          return date.toLocaleString();
        },
      },
    },
    {
      name: "shopname",
      label: "Shopname",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "mpesaReceiptNumber",
      label: "Mpesa Receipt Number",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
      }
    },
  ];

  const options = {
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 15, 20, 100],
    selectableRows: showSelection ? 'multiple' : 'none',
    downloadOptions: {
      filename: 'payment_data.csv',
      separator: ',',
    },
    expandableRows: showDetails,
    renderExpandableRow: (rowData, rowMeta) => {
      const rowIndex = rowMeta.rowIndex;
      return (
        <tr>
          <td colSpan={rowData.length}>
            <Box margin={2}>
              <p><strong>Additional Details:</strong></p>
              <p>{`Row index: ${rowIndex}`}</p>
              <p>{`Transaction ID: ${rowData[2]}`}</p>
              <p>{`Unique Key: ${filteredData[rowIndex]?.id || 'N/A'}`}</p>
            </Box>
          </td>
        </tr>
      );
    },
  };

  return (
    <ThemeProvider theme={getMuiTheme(darkMode)}>
      <CssBaseline />
      <Box display="flex" flexDirection="column" marginBottom={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
          <FormControlLabel
            control={<Switch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />}
            label="Dark Mode"
          />
          <IconButton onClick={() => fetchData()}>
            <RefreshIcon />
          </IconButton>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TextField
            type="date"
            label="Start Date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            size="small"
          />
          <TextField
            type="date"
            label="End Date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            size="small"
          />
          <FormControl variant="outlined" size="small">
            <InputLabel id="shop-select-label">Shop Name</InputLabel>
            <Select
              labelId="shop-select-label"
              disabled={role !== "admin" ? 'true' : "false"}
              value={selectedShop}
              onChange={(e) => setSelectedShop(e.target.value)}
              label="Shop Name"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {shopNames.map((shop, index) => (
                <MenuItem key={index} value={shop}>
                  {shop}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            type="text"
            label="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            size="small"
          />
          <Typography variant="h6">Total Amount: {totalAmount}</Typography>
        </Box>
      </Box>
      <MUIDataTable
        title={"Till Payments"}
        data={filteredData}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  );
};

export default MpesaTillPayments;
