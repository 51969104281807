// ViewEmployees.js
import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Divider,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { Person as PersonIcon, Refresh } from '@mui/icons-material';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import { getShopList, getEmployeesData, getSalesPeopleSales } from './EmployeeDataservice';

function ViewEmployees() {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    const fetchEmployeesAndSales = async () => {
      try {
        setLoading(true);
        const shops = await getShopList();
        let allEmployees = [];

        for (const shop of shops) {
          const shopEmployees = await getEmployeesData(shop);
          // Map and format employee data as needed
          const formattedEmployees = shopEmployees.map(emp => ({
            id: emp.id,
            name: emp.name,
            email: emp.email,
            shopAssigned: emp.assignedShop || shop,
            role: emp.role,
            sales: emp.sales || 0, // Default to 0 if not available
            profit: emp.profit || 0, // Default to 0 if not available
            salary: emp.salary || 0, // Default to 0 if not available
            photoUrl: emp.passportPhoto || '', // Use passportPhoto or any other field for photo
          }));
          allEmployees = [...allEmployees, ...formattedEmployees];
        }

        // Fetch sales data
        const salesData = await getSalesPeopleSales();

        // Merge sales data with employee data
        const mergedEmployees = allEmployees.map(emp => {
          const salesInfo = salesData.find(sale => sale.name === emp.name && sale.shop === emp.shopAssigned);
          return {
            ...emp,
            sales: salesInfo?.totalSales || emp.sales,
            profit: salesInfo?.totalProfit || emp.profit,
            commission: salesInfo?.commission || 0,
          };
        });

        setEmployees(mergedEmployees);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching employees or sales:', err);
        setError('Failed to load employees or sales data. Please try again later.');
        setLoading(false);
      }
    };

    fetchEmployeesAndSales();
  }, []);

  const handleEmployeeClick = (employee) => {
    setSelectedEmployee(employee);
  };

  const resetPin = async (employeeEmail) => {
    try {
      await sendPasswordResetEmail(auth, employeeEmail);
      setSnackbar({
        open: true,
        message: `Password reset email sent to ${employeeEmail}`,
        severity: 'success',
      });
    } catch (err) {
      console.error('Error sending password reset email:', err);
      setSnackbar({
        open: true,
        message: 'Failed to send password reset email.',
        severity: 'error',
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <div style={{ padding: '16px', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ padding: '16px', textAlign: 'center' }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </div>
    );
  }

  return (
    <div style={{ padding: '16px' }}>
      <Typography variant="h4" style={{ color: '#388E3C', marginBottom: '16px' }}>
        Employee List
      </Typography>
      <Grid container spacing={3}>
        {/* Employee List */}
        <Grid item xs={12} sm={4}>
          {employees.map((employee) => (
            <Card
              key={employee.id}
              onClick={() => handleEmployeeClick(employee)}
              style={{
                marginBottom: '16px',
                cursor: 'pointer',
                backgroundColor: selectedEmployee?.id === employee.id ? '#e0f7fa' : '#f7f7f7',
              }}
            >
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    {employee.photoUrl ? (
                      <Avatar src={employee.photoUrl} alt={employee.name} />
                    ) : (
                      <Avatar style={{ backgroundColor: '#388E3C' }}>
                        <PersonIcon />
                      </Avatar>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" style={{ color: '#424242' }}>
                      {employee.name}
                    </Typography>
                    <Typography variant="body2" style={{ color: '#424242' }}>
                      {employee.role}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
        </Grid>

        {/* Employee Details */}
        <Grid item xs={12} sm={8}>
          {selectedEmployee ? (
            <Card style={{ backgroundColor: '#f7f7f7' }}>
              <CardContent>
                <Typography variant="h5" style={{ color: '#388E3C' }}>
                  {selectedEmployee.name}
                </Typography>
                <Divider style={{ margin: '16px 0' }} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    {selectedEmployee.photoUrl ? (
                      <Avatar
                        src={selectedEmployee.photoUrl}
                        alt={selectedEmployee.name}
                        sx={{ width: 120, height: 120 }}
                      />
                    ) : (
                      <Avatar
                        style={{ backgroundColor: '#388E3C', width: 120, height: 120 }}
                      >
                        <PersonIcon sx={{ fontSize: 80 }} />
                      </Avatar>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1" style={{ color: '#424242' }}>
                      <strong>ID:</strong> {selectedEmployee.id}
                    </Typography>
                    <Typography variant="body1" style={{ color: '#424242' }}>
                      <strong>Email:</strong> {selectedEmployee.email}
                    </Typography>
                    <Typography variant="body1" style={{ color: '#424242' }}>
                      <strong>Shop Assigned:</strong> {selectedEmployee.shopAssigned}
                    </Typography>
                    <Typography variant="body1" style={{ color: '#424242' }}>
                      <strong>Role:</strong> {selectedEmployee.role}
                    </Typography>
                    <Typography variant="body1" style={{ color: '#424242' }}>
                      <strong>Sales:</strong> KES {selectedEmployee.sales.toLocaleString()}
                    </Typography>
                    <Typography variant="body1" style={{ color: '#424242' }}>
                      <strong>Profit:</strong> KES {selectedEmployee.profit.toLocaleString()}
                    </Typography>
                    <Typography variant="body1" style={{ color: '#424242' }}>
                      <strong>Salary:</strong> KES {selectedEmployee.salary.toLocaleString()}
                    </Typography>
                    {selectedEmployee.commission && (
                      <Typography variant="body1" style={{ color: '#424242' }}>
                        <strong>Commission:</strong> KES {parseFloat(selectedEmployee.commission).toLocaleString()}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  startIcon={<Refresh />}
                  style={{ backgroundColor: '#388E3C', color: '#FFFFFF', marginTop: '16px' }}
                  onClick={() => resetPin(selectedEmployee.email)}
                >
                  Reset PIN
                </Button>
              </CardContent>
            </Card>
          ) : (
            <Typography variant="h6" style={{ color: '#424242' }}>
              Select an employee to view details
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ViewEmployees;
