import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import CategoryIcon from "@mui/icons-material/Category";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import StoreIcon from "@mui/icons-material/Store";

const categories = [
  "Electronics",
  "Clothing",
  "Groceries",
  "Accessories",
  "Other",
];

function InventoryEditProduct() {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        p: 3,
        borderRadius: 2,
        backgroundColor: "#f9f9f9",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        maxWidth: 600,
        margin: "0 auto",
      }}
    >
      <TextField
        fullWidth
        select
        label="Category"
        variant="outlined"
        margin="normal"
        defaultValue=""
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CategoryIcon color="success" />
            </InputAdornment>
          ),
        }}
        sx={{ bgcolor: "white" }}
      >
        {categories.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        fullWidth
        label="Product Name"
        variant="outlined"
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <StoreIcon color="success" />
            </InputAdornment>
          ),
        }}
        sx={{ bgcolor: "white" }}
      />

      <TextField
        fullWidth
        label="Buying Price"
        variant="outlined"
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon color="success" />
            </InputAdornment>
          ),
        }}
        sx={{ bgcolor: "white" }}
      />

      <TextField
        fullWidth
        label="Selling Price"
        variant="outlined"
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PriceCheckIcon color="success" />
            </InputAdornment>
          ),
        }}
        sx={{ bgcolor: "white" }}
      />

      <Button
        variant="contained"
        color="success"
        sx={{
          mt: 3,
          bgcolor: "#00b341", // Safaricom green
          "&:hover": {
            bgcolor: "#008b2d",
          },
        }}
        fullWidth
      >
        Save Changes
      </Button>
    </Box>
  );
}

export default InventoryEditProduct;
