// src/App.js
import React, { useState, useEffect } from 'react';
import { CssBaseline, Box } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import ShopCard from './components/ShopCard';
import Sales from './components/POSPage';
import AddSalesperson from './components/AddSalespersonPage';
import ManageSalespeople from './components/ManageSalespeoplePage';
import AddCategory from './components/AddCategoryPage';
import CreateProduct from './components/CreateProductPage';
import ReceiveProduct from './components/ReceiveProductPage';
import EditProduct from './components/EditProductPage';
import SystemSettings from './Settings/SystemSettingsPage';
import ReportsPage from './reports/ReportsPage';
import DailySales from './reports/DailyReportsPage';
import WeeklySales from './reports/WeeklyReportsPage';
import MonthlySales from './reports/MonthlyReportsPage';
import MpesaTillStatement from './reports/MpesaTillStatement';
import DevicesPurchases from './reports/DevicesPurchases';
import DailyProfits from './reports/DailyProfits';
import WeeklyProfits from './reports/WeeklyProfits';
import MonthlyProfits from './reports/MonthlyProfits';
import Login from './components/Login';
import ViewProducts from './components/ViewProducts';
import AddShopPage from './components/AddShopPage';
import SalesReturnReports from './reports/SalesReturnReports';
import ApprovedProductsReportPage from './reports/ApprovedProductsReportPage';
import UserDashboard from './components/UserDashboard';
import useSessionTimeout from './hooks/useSessionTimeOut'; // Import the custom hook
import SalesReturnPage from './components/SalesReturnPage ';
import ApproveProducts from './components/ApproveProducts';
import StockTransferPage from './components/StockTransferPage';
import ViewEmployees from './components/ViewEmployees'; // Import the ViewEmployees component



function App() {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({ role: '', username: '' });
  const auth = getAuth();
  const db = getFirestore();
  useSessionTimeout(10 * 60 * 1000); // Set session timeout to 10 minutes

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        // Fetch username and role
        try {
          const usersRef = collection(db, 'users');
          const userQuery = query(usersRef, where('email', '==', currentUser.email));
          const userSnapshot = await getDocs(userQuery);
          let username = '';
          userSnapshot.forEach((doc) => {
            username = doc.data().username;
          });

          const employeesRef = collection(db, 'employees');
          const roleQuery = query(employeesRef, where('email', '==', currentUser.email));
          const roleSnapshot = await getDocs(roleQuery);
          let role = '';
          roleSnapshot.forEach((doc) => {
            role = doc.data().role;
          });

          if (role) {
            setUserData({ role, username });
          } else {
            // User is not authorized, sign them out
            await signOut(auth);
            setUser(null);
            setUserData({ role: '', username: '' });
            toast.error('Unauthorized user. You have been signed out.');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          toast.error('Error fetching user data.');
        }
      } else {
        setUser(null);
        setUserData({ role: '', username: '' });
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      // Navigate to login page after sign out
      window.location.href = '/login';
    } catch (error) {
      toast.error('Error signing out: ' + error.message);
    }
  };

  const isAuthenticated = user !== null;
  const isAdmin = userData.role === 'admin';

  return (
    <Router>
      <CssBaseline />
      <Box display="flex" flexDirection="column" minHeight="100vh">
        {isAuthenticated && <Navbar onSignOut={handleSignOut} />}
        <Box display="flex" flexGrow={1}>
          {isAuthenticated && <Sidebar role={userData.role} username={userData.username} />}
          <Box
            component="main"
            flexGrow={1}
            p={3}
            sx={{
              paddingTop: isAuthenticated ? '64px' : '0px',
              paddingLeft: isAuthenticated ? '10px' : '0px',
            }}
          >
            <Routes>
              <Route path="/" element={isAuthenticated ? <Navigate to={isAdmin ? "/dashboard" : "/userdashboard"} /> : <Navigate to="/login" />} />
              <Route path="/login" element={isAuthenticated ? <Navigate to={isAdmin ? "/dashboard" : "/userdashboard"} /> : <Login onLogin={setUser} />} />
              {isAuthenticated && (
                <>
                    <Route path="/dashboard" element={isAdmin ? <Dashboard /> : <Navigate to="/userdashboard" />} />
                  <Route path="/userdashboard" element={!isAdmin ? <UserDashboard /> : <Navigate to="/dashboard" />} />
                  <Route path="/shopcard" element={<ShopCard />} />
                  <Route path="/add-shop" element={<AddShopPage />} />
                  <Route path="/sales" element={<Sales />} />
                  <Route path="/reports" element={<ReportsPage />} />
                  <Route path="/add-salesperson" element={<AddSalesperson />} />
                  <Route path="/view-product" element={<ViewProducts />} />
                  <Route path="/manage-salespeople" element={<ManageSalespeople />} />
                  <Route path="/add-category" element={<AddCategory />} />
                  <Route path="/create-product" element={<CreateProduct />} />
                  <Route path="/receive-product" element={<ReceiveProduct />} />
                  <Route path="/edit-product" element={<EditProduct />} />
                  <Route path="/system-settings" element={isAdmin ? <SystemSettings /> : <Navigate to="/dashboard" />} />
                  <Route path="/reports/DailyReportsPage" element={<DailySales />} />
                  <Route path="/reports/WeeklyReportsPage" element={<WeeklySales />} />
                  <Route path="/reports/MonthlyReportsPage" element={<MonthlySales />} />
                  <Route path="/reports/MpesaTillStatement" element={<MpesaTillStatement />} />
                  <Route path="/reports/DevicesPurchases" element={<DevicesPurchases />} />
                  <Route path="/reports/SalesReturnReports" element={<SalesReturnReports />} />
                  <Route path="/reports/DailyProfits" element={<DailyProfits />} />
                  <Route path="/reports/WeeklyProfits" element={<WeeklyProfits />} />
                  <Route path="/reports/MonthlyProfits" element={<MonthlyProfits />} />
                  <Route path="/sales-return" element={<SalesReturnPage />} /> 
                  <Route path="/approve-products" element={<ApproveProducts />} /> 
                  <Route path="/stock-transfer" element={<StockTransferPage />} /> 
                  <Route path="/view-employees" element={<ViewEmployees />} /> 
                  <Route path="/reports/ApprovedProductsReportPage" element={<ApprovedProductsReportPage />} /> 
                  
                  
                   </> 
              )}
            </Routes>
          </Box>
        </Box>
      </Box>
      <ToastContainer />
    </Router>
  );
}

export default App;
