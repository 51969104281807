import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  MenuItem,
  Tooltip,
  Box,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { styled } from "@mui/system";
import { collection, getDocs, doc, deleteDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase"; // Adjust the path according to your file structure
import { toast } from "react-toastify"; // Ensure you have react-toastify installed

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold",
  color: "#388E3C", // Safaricom green for header
});

const ActionButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "rgba(76, 175, 80, 0.1)", // Safaricom green hover effect
  },
}));

const DeleteButton = styled(Button)({
  backgroundColor: "#F44336", // Red for delete
  color: "#FFF",
  "&:hover": {
    backgroundColor: "#D32F2F",
  },
});

const ManageEmployees = () => {
  const [employees, setEmployees] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editEmployee, setEditEmployee] = useState(null);
  const [shops, setShops] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      const querySnapshot = await getDocs(collection(db, "employees"));
      const employeeList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEmployees(employeeList);
    };

    const fetchShops = async () => {
      const querySnapshot = await getDocs(collection(db, "shoplist"));
      const shopList = querySnapshot.docs.map((doc) =>
        doc.data().shopName
      );
      setShops(shopList);
    };

    fetchEmployees();
    fetchShops();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "employees", id));
      setEmployees(employees.filter((employee) => employee.id !== id));
      toast.success("Employee deleted successfully.");
    } catch (error) {
      toast.error("Error deleting employee. Please try again.");
    }
  };

  const handleEditClick = (employee) => {
    setEditEmployee(employee);
    setOpenEditDialog(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditEmployee((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditSubmit = async () => {
    try {
      const employeeRef = doc(db, "employees", editEmployee.id);
      await updateDoc(employeeRef, editEmployee);
      setEmployees((prev) =>
        prev.map((emp) => (emp.id === editEmployee.id ? editEmployee : emp))
      );
      toast.success("Employee updated successfully.");
      setOpenEditDialog(false);
    } catch (error) {
      toast.error("Error updating employee. Please try again.");
    }
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setEditEmployee(null);
  };

  return (
    <Container maxWidth="lg" sx={{ padding: "2rem 0" }}>
      <Typography variant="h4" align="center" sx={{ color: "#388E3C", fontWeight: "bold", marginBottom: "2rem" }}>
        Manage Employees
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Role</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Assigned Shop</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.map((employee) => (
              <TableRow key={employee.id}>
                <TableCell>{employee.name}</TableCell>
                <TableCell>{employee.role}</TableCell>
                <TableCell>{employee.email}</TableCell>
                <TableCell>{employee.assignedShop ? employee.assignedShop.toUpperCase() : 'N/A'}</TableCell>
                <TableCell align="center">
                  <Tooltip title="Edit">
                    <ActionButton onClick={() => handleEditClick(employee)}>
                      <Edit sx={{ color: "#388E3C" }} />
                    </ActionButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <ActionButton onClick={() => handleDelete(employee.id)}>
                      <Delete sx={{ color: "#F44336" }} />
                    </ActionButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Employee Dialog */}
      {editEmployee && (
        <Dialog open={openEditDialog} onClose={handleEditDialogClose} maxWidth="sm" fullWidth>
          <DialogTitle sx={{ color: "#388E3C" }}>Edit Employee</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Full Name"
                  name="name"
                  fullWidth
                  variant="outlined"
                  value={editEmployee.name}
                  onChange={handleEditChange}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Role"
                  name="role"
                  fullWidth
                  variant="outlined"
                  select
                  value={editEmployee.role}
                  onChange={handleEditChange}
                  required
                >
                  <MenuItem value="salesperson">Salesperson</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="shopmanager">Shop Manager</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  name="email"
                  fullWidth
                  variant="outlined"
                  value={editEmployee.email}
                  onChange={handleEditChange}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
  <TextField
    label="Assigned Shop"
    name="assignedShop"
    fullWidth
    variant="outlined"
    select
    value={editEmployee.assignedShop || 'none'}  // Default to 'none' if no shop is selected
    onChange={handleEditChange}
    required
  >
    <MenuItem key="none" value="none">
      None
    </MenuItem>
    {shops.map((shop) => (
      <MenuItem key={shop} value={shop.toLowerCase()}>
        {shop.toUpperCase()}
      </MenuItem>
    ))}
  </TextField>
</Grid>

            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditSubmit} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};

export default ManageEmployees;
