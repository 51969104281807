import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import DeleteIcon from '@mui/icons-material/Delete';
import { Timestamp } from 'firebase/firestore';
import { collection, addDoc, getDocs, updateDoc, query,  writeBatch, where, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { batch } from 'react-redux'; // Only needed if using redux, otherwise just set states together

import {getAuth} from 'firebase/auth';
import { getDatabase, ref, onValue, set } from 'firebase/database';

const POSPage = () => {
  const [products, setProducts] = useState([]);
  const [issell, setsale] = useState(true);
  const [salespersons, setSalespersons] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [sellingPrice, setSellingPrice] = useState('');
  const [sales, setSales] = useState([]);
  const [total, setTotal] = useState(0);
  const [email,setemail] =useState('');
  const [rolle,setRole] =useState('');
  const [bongaPoints, setBongaPoints] = useState('');
  const [discount, setDiscount] = useState(0);
  const [receiptCode, setReceiptCode] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [salesperson, setSalesperson] = useState('');
  const [customerCategory, setCustomerCategory] = useState('');
  const [openMPesaDialog, setOpenMPesaDialog] = useState(false);
  const [mpesaTransactions, setMpesaTransactions] = useState([]);
  const [shop,setshop]=useState('');
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const [salesData, setSalesData] = useState([]);

  
  const fetchProducts = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) throw new Error('No user is currently signed in.');

      setemail(user.email);

      const employeeQuery = query(
        collection(db, 'employees'),
        where('email', '==', user.email)
      );

      const employeeSnapshot = await getDocs(employeeQuery);
      if (employeeSnapshot.empty) throw new Error('Employee not found.');

      const employeeData = employeeSnapshot.docs[0].data();
      const { role, assignedShop } = employeeData;

      batch(() => {
        setshop(assignedShop);
        setRole(role);
      });

      let pendingProducts = role === 'admin'
        ? await fetchAllShopsPendingProducts()
        : await fetchShopManagerPendingProducts(assignedShop);

      setProducts(pendingProducts);
    } catch (error) {
      console.error('Error fetching data: ', error);
      // Optionally show error feedback to the user
    }
  };
   

  const fetchAllShopsPendingProducts = async () => {
    const shopsCollection = collection(db, 'shoplist');
    const shopsSnapshot = await getDocs(shopsCollection);
    const shops = shopsSnapshot.docs.map((doc) => doc.data().shopName);

    const productPromises = shops.map((shopName) =>
      fetchPendingProductsForShop(shopName)
    );

    const productsArray = await Promise.all(productPromises);
    return productsArray.flat();
  };

  const fetchShopManagerPendingProducts = async (assignedShop) => {
    return await fetchPendingProductsForShop(assignedShop);
  };

  const fetchPendingProductsForShop = async (shopName) => {
    const shopPendingProductsRef = collection(db, 'received_products', shopName, 'products');
    const shopPendingProductsQuery = query(
      shopPendingProductsRef,
      where('status', '==', 'approved')
    );
    const shopPendingProductsSnapshot = await getDocs(shopPendingProductsQuery);

    return shopPendingProductsSnapshot.docs.map((doc) => ({
      id: doc.id,
      shop: shopName,
      ...doc.data(),
    }));
  };

  useEffect(() => {
   
    fetchProducts();
  }, []);
  

  useEffect(() => {
    const fetchPendingTransactions = () => {
      const db = getDatabase();
      const dbRef = ref(db, 'transactions');
  
      onValue(dbRef, (snapshot) => {
        const transactions = snapshot.val();
        if (transactions) {
          const pending = Object.keys(transactions)
            .map(key => ({ id: key, ...transactions[key] }))
            .filter(transaction => transaction.shopname === shop && transaction.resultCode === 0 && transaction.status === "pending");
          setPendingTransactions(pending);
        }
      });
    };
  
    fetchPendingTransactions();
  }, [shop]);  // Add `shop` as a dependency to re-fetch when `shop` changes
  

  const handleAddSale = async () => {
   
    if (selectedProduct && quantity > 0 && sellingPrice) {
      const product = products.find((p) => p.id === selectedProduct.id);
      if (product) {
        
        // Check if the selling price is lower than the buying price
        if (Number(sellingPrice) < product.buyingPrice) {
          toast.error('Selling price cannot be lower than the buying price.');
          return; // Stop the function execution
        }
       
        const sale = {
          ...product,
          serial: selectedProduct.serial,
          sellingPrice: Number(sellingPrice),
          quantity,
        };

        const employeesRef = collection(db, 'employees');
        const roleQuery = query(employeesRef, where('role', '==', 'salesperson')
        ,where('assignedShop','==',shop));
        const querySnapshot = await getDocs(roleQuery);
        const salespersonsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setSalespersons(salespersonsData);

        setSales([...sales, sale]);
        setSelectedProduct(null);
        setQuantity(1);
        setSellingPrice('');
        setsale(false);
        setTotal((prevTotal) => prevTotal + (sale.sellingPrice * quantity) - discount);
      } else {
        toast.error('Product not found.');
      }
    } else {
      toast.error('Please select a product, enter a valid quantity, and provide a selling price.');
    }
};
const handleSell = async () => {
  if (customerName && customerPhone && salesperson && sales.length > 0 && customerCategory) {
    setsale(true);
    const saleTimestamp = Timestamp.fromDate(new Date());
    setReceiptCode(generateReceiptCode());
    // Generate the receipt code
  
    
    const saleData = {
      customerName,
      customerPhone,
      products: sales.map((sale) => ({
        productId: sale.id,
        name: sale.productName,
        category: sale.category,
        serial: sale.serial,
        quantity: sale.quantity,
        price: sale.sellingPrice,
        buyingprice: sale.buyingPrice,
      })),
      total,
      discount,
      paymentMethod,
      salesperson,
      saleTimestamp,
      customerCategory,
      ...(paymentMethod === 'Bonga' && { bongaAmount: bongaPoints }), // Add bongaAmount if paymentMethod is 'Bonga'
      receipt: receiptCode, // Add receipt code
    };
    setSalesData(saleData);
    
    try {
      const collectionName = customerCategory === 'Walkin' ? `sales/${shop}/mpesa_sales` : `sales/${shop}/credit_sales`;
      
      let amountToPay = total;
      
      // Subtract Bonga points if applicable
      if (paymentMethod === 'Bonga' && bongaPoints) {
        amountToPay = total - bongaPoints;
      }

      // If MPesa payment is required
      if (paymentMethod === 'MPesa' || (paymentMethod === 'Bonga' && amountToPay > 0)) {
        if (amountToPay > 0) {
          const mpesaResponse = await initiateMPesaPayment(customerPhone, amountToPay, shop);
          if (mpesaResponse.status === 'success') {
            setOpenMPesaDialog(true);
          } else {
            throw new Error('MPesa payment initiation failed');
          }
        } else {
          // If Bonga points cover the full amount, just save the sale
          await saveSaleData(saleData, collectionName);
          handlePrintInvoice(saleData);
        }
      } else {
        // For other payment methods like Credit or if Bonga points cover full amount
        await saveSaleData(saleData, collectionName);
        handlePrintInvoice(saleData);
      }

    } catch (error) {
      toast.error('Error recording sale.');
      console.error('Error recording sale: ', error);
    }
  } else {
    toast.error('Please fill in all required fields and add at least one product.');
  }
};

// Function to generate a random 6-digit receipt code
const generateReceiptCode = () => {
  return Math.floor(1 + Math.random() * 9999).toString(); // Generates a random number between 100000 and 999999
};



  const saveSaleData = async (saleData, collectionName) => {
    const batch = writeBatch(db);
    try {
      // Add the sale data to the specified collection
      await addDoc(collection(db, collectionName), saleData);
  
      sales.forEach((sale) => {
        const product = products.find((p) => p.id === sale.id);
        if (product) {
          const newQty = product.quantity - sale.quantity;
          const remainingSerials = product.serialNumbers.filter(
            (serial) => serial !== sale.serial
          );
  
          // Create a reference to the product document in the received_products collection
          const productRef = doc(db, `received_products/${sale.shop}/products`, product.id);
  
          // Batch update the product's quantity and serial numbers
          batch.update(productRef, {
            quantity: newQty,
            serialNumbers: remainingSerials,
          });
        }
      });
  
      // Commit the batch operation
      await batch.commit();
  
      // Fetch products again after the sale is completed
      await fetchProducts();
  
      // Resetting the form or state
      setSales([]);
      setTotal(0);
      setDiscount(0);
      setCustomerName('');
      setCustomerPhone('');
      setSalesperson('');
      setCustomerCategory('');
      setBongaPoints('');
  
    } catch (error) {
      console.error("Error saving sale data: ", error);
      // Handle error (e.g., show notification to user)
    }
  };

  

  const handleMPesaTransactionSelect = async (transaction) => {
    if (!transaction) {
      toast.error('Invalid transaction selected.');
      return;
    }
  
    setSelectedTransaction(transaction);
    
    // Assuming transaction.amount is the amount from the M-Pesa transaction
    let effectiveTotal = salesData.total; // The original total sale amount
  
    // If the payment method is Bonga, adjust the total
    if (salesData.paymentMethod === 'Bonga') {
      const bongaAmount = salesData.bongaAmount || 0; // Default to 0 if no Bonga amount is specified
      effectiveTotal = effectiveTotal - bongaAmount; // Subtract Bonga amount from total
    }
  
    // Check if the M-Pesa transaction amount matches the effective sale amount
    const transactionAmount = transaction.amount; // The amount from the M-Pesa transaction
  
    if (transactionAmount !== effectiveTotal) {
      toast.error('M-Pesa transaction amount does not match the adjusted sale amount.');
      return; // Do not proceed further, keep the dialog open
    }
  
    try {
      const collectionName = customerCategory === 'Walkin' ? `sales/${shop}/mpesa_sales` : `sales/${shop}/credit_sales`;
  
      // Update the transaction status to "complete" in the Firebase Realtime Database
      const db = getDatabase();
      const transactionRef = ref(db, `transactions/${transaction.id}`);
      await set(transactionRef, { ...transaction, status: 'complete' });
  
      // Save the sale data with the transaction ID
      await saveSaleData({ ...salesData, mpesaTransactionId: transaction.mpesaReceiptNumber }, collectionName);
      
      // Print the invoice
      handlePrintInvoice({ ...salesData, mpesaTransactionId: transaction.mpesaReceiptNumber });
    } catch (error) {
      toast.error('Error recording M-Pesa sale.');
      console.error('Error recording M-Pesa sale: ', error);
    }
  };
  

  const handlePrintInvoice = (saleData) => {
    const printerWidth = localStorage.getItem('printerWidth') || 80; // Default width if not set

    // Get the current date and time
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString(); // Format the date as needed
    const formattedTime = currentDate.toLocaleTimeString(); // Format the time as needed

    const printContent = `
      <div style="text-align:center;">
        <h2>Safaricom Customer Care</h2>
        <h3>Cowtrack ${shop.substring(0,1).toUpperCase()}${shop.substring(1)} Shop</h3>
        <p>Tel: 0728262444</p>
       <div style="display:flex">
       <p><strong>Customer Name: </strong></p>
       <p style="margin-start: 5px">${saleData.customerName}</p>
       </div>
      <div style="display:flex">
       <p style=""><strong>Customer Phone: </strong></p>
       <p style="margin-start: 5px">${saleData.customerPhone}</p>
      </div>
      </div>
       <div style=" text-align: center; justify-content: space-between; ">
      
      
      <div style="display:flex; justify-content: space-between; margin-top: 10px;">
        <p style="margin-right: 10px;"><strong>Receipt No: </strong>${receiptCode}</p>
        <p style="margin-right: 10px;"><strong>Date: </strong>${formattedDate}</p>
        <p><strong>Time: </strong>${formattedTime}</p>
      </div>
      </div>
      <table style="width:100%; text-align:center; margin-top:20px; border-collapse: collapse;">
        <thead>
          <tr style="border-bottom: 1px solid #000;">
            <th style="border-bottom: 1px solid #000;">Item Name</th>
            <th style="border-bottom: 1px solid #000;">Serial Number</th>
            <th style="border-bottom: 1px solid #000;">Quantity</th>
            <th style="border-bottom: 1px solid #000;">Price (ksh)</th>
          </tr>
        </thead>
        <tbody>
          ${saleData.products
            .map(
              (product) => `<tr>
                <td style="border: none;">${product.name}</td>
                <td style="border: none;">${product.serial}</td>
                <td style="border: none;">${product.quantity}</td>
                <td style="border: none;">${product.price}</td>
              </tr>`
            )
            .join('')}
        <tr>
          <td colspan="2" style="text-align: right;"><strong>Total Quantity:</strong></td>
          <td><strong>${saleData.products.reduce((acc, product) => acc + product.quantity, 0)}</strong></td>
          <td></td>
        </tr>
        <tr>
          <td colspan="2" style="text-align: right;"><strong>Total Price:</strong></td>
          <td><strong>ksh${saleData.total.toFixed(2)}</strong></td>
          <td></td>
        </tr>
      </table>
      <p>Served By: ${saleData.salesperson}</p>
      <p style="text-align:center; margin-top:20px;">Thank you for shopping with us!</p>
    `;

    const printWindow = window.open('', '', `width=${printerWidth * 10}, height=800`);
    printWindow.document.write(`
      <html>
      <head>
        <title>Invoice</title>
        <style>
          @media print {
            body {
              margin: 0;
              padding: 0;
            }
          }
        </style>
      </head>
      <body>${printContent}</body>
      </html>
    `);
    printWindow.document.close();

    // Dynamically adjust the height based on content size
    const updateHeight = () => {
        const body = printWindow.document.body;
        const contentHeight = body.scrollHeight; // Get the total height of the content
        printWindow.resizeTo(printerWidth * 10, contentHeight);
    };

    printWindow.onload = () => {
        updateHeight(); // Adjust the window height after loading content
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };
};




  const handleRemoveSale = (serial) => {
    const newSales = sales.filter((sale) => sale.serial !== serial);
    const removedSale = sales.find((sale) => sale.serial === serial);
    setSales(newSales);
    setTotal((prevTotal) => prevTotal - (removedSale.sellingPrice * removedSale.quantity) + discount);
    toast.info(`Removed product: ${removedSale.productName}`);
  };

  const initiateMPesaPayment = async (phoneNumber, amount, shopname) => {
    try {
      // Normalize the phone number to '2547xxxxxxxx'
      let normalizedPhoneNumber = phoneNumber.trim();
  
      if (normalizedPhoneNumber.startsWith('0')) {
        // Replace leading '0' with '254'
        normalizedPhoneNumber = '254' + normalizedPhoneNumber.slice(1);
      } else if (normalizedPhoneNumber.startsWith('+')) {
        // Remove leading '+' and keep the rest
        normalizedPhoneNumber = normalizedPhoneNumber.slice(1);
      }
    
    
    
  
      // Proceed with M-PESA payment initiation
      const response = await axios.post('https://us-central1-mathare-f7e84.cloudfunctions.net/processTransactions', {
        phone: normalizedPhoneNumber,
        amount: amount,
        shopname: shopname
      });
  
      if (response.data && response.data.ResponseCode === '0') {
        toast.success('MPESA Payment initiation successful');
        setOpenMPesaDialog(true);
        return { status: 'success', data: response.data };
      } else {
        toast.error('MPESA Payment initiation failed');
        throw new Error('MPESA Payment initiation failed');
      }
    } catch (error) {
      toast.error('MPESA Payment initiation failed');
      console.error('Error initiating MPESA payment:', error);
      throw new Error('Failed to initiate MPESA payment');
    }
  };
  

  return (
    <Box padding="24px">
      <ToastContainer />
      <Typography
      variant="h5"
      gutterBottom
      align="center"
      sx={{
        fontFamily: 'sans-serif,Roboto', // Set the font family to Roboto
        background: 'linear-gradient(45deg, green, lightgreen)', // Define the gradient
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text', // Needed for non-WebKit browsers
      }}
    >
      COWTRACK MULTI-STORE SYSTEM
    </Typography>
      <Typography
  variant="h6"
  gutterBottom
  align="center"
  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
>
  <LocalMallIcon style={{ marginRight: '8px', fontSize: '32px', color: '#FF5722' }} />
  {rolle !== 'shopmanager' ?  '':`${shop.toUpperCase()} SHOP` }
</Typography>

      <Typography
        variant="subtitle1"
        gutterBottom
        align="center"
        style={{ fontStyle: 'italic', color: '#607D8B' }}
      >
        "Quality products, exceptional service!"
      </Typography>
      <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6">Customer Information</Typography>
            <TextField
              label="Customer Name"
              variant="outlined"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Customer Phone"
              variant="outlined"
              value={customerPhone}
              onChange={(e) => setCustomerPhone(e.target.value)}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel>Customer Category</InputLabel>
              <Select
                value={customerCategory}
                onChange={(e) => setCustomerCategory(e.target.value)}
                label="Customer Category"
              >
                <MenuItem value="LMM">Lipa Mdogo Mdogo (LMM)</MenuItem>
                <MenuItem value="Watu Simu">Watu Simu</MenuItem>
                <MenuItem value="WABY">WABY</MenuItem>
                <MenuItem value="Onfone">OnPhone</MenuItem>
                <MenuItem value="EasyBuy">EasyBuy</MenuItem>
                <MenuItem value="Walkin">Walkin</MenuItem>
                
              </Select>
            </FormControl>
            <TextField
              type="number"
              label="Discount (ksh)"
              variant="outlined"
              value={discount}
              onChange={(e) => setDiscount(Number(e.target.value))}
              fullWidth
              margin="normal"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6">Select Products</Typography>
            <Autocomplete
              options={products.flatMap((product) =>
                product.serialNumbers.map((serial) => ({
                  id: product.id,
                  serial,
                  productName: product.productName,
                  sellingPrice: product.sellingPrice,
                }))
              )}
              getOptionLabel={(option) =>
                `${option.productName.toUpperCase()} - Ksh. ${option.sellingPrice} (SERIAL: ${option.serial})`
              }
              renderInput={(params) => (
                <TextField {...params} label="Products" variant="outlined" fullWidth />
              )}
              value={selectedProduct}
              onChange={(event, newValue) => setSelectedProduct(newValue)}
            />
            <TextField
              type="number"
              label="Quantity"
              variant="outlined"
              value={quantity}
              onChange={(e) => setQuantity(Number(e.target.value))}
              fullWidth
              margin="normal"
              InputProps={{ inputProps: { min: 1 } }}
            />
             <TextField
                type="number"
                label="Selling Price"
                variant="outlined"
                value={sellingPrice}
                onChange={(e) => setSellingPrice(e.target.value)}
                fullWidth
                margin="normal"
                InputProps={{ inputProps: { min: 1 } }}
              />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddSale}
              fullWidth
              style={{ marginTop: '16px' }}
            >
              
              Add to Sale
            </Button>
          </Paper>
        </Grid>

      

        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '16px' }}>
            <Typography variant="h6">Sales</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Category</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Serial Number</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price (ksh)</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sales.map((sale) => (
                    <TableRow key={sale.serial}>
                      <TableCell>{sale.category.toUpperCase()}</TableCell>
                      <TableCell>{sale.productName.toUpperCase()}</TableCell>
                      <TableCell>{sale.serial}</TableCell>
                      <TableCell>{sale.quantity}</TableCell>
                      <TableCell>{(sale.sellingPrice * sale.quantity).toFixed(2)}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleRemoveSale(sale.serial)}>
                          <DeleteIcon color="secondary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="h6" align="right" marginTop={2}>
              Total: ksh{total.toFixed(2)}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
        <Paper elevation={3} style={{ padding: '16px' }}>
  <Typography variant="h6">Payment</Typography>
  <FormControl fullWidth variant="outlined" margin="normal">
  <InputLabel>Mode of Payment</InputLabel>
  <Select
    value={paymentMethod}
    onChange={(e) => { setPaymentMethod(e.target.value); toast.done(e.target.value); }}
    label="Mode of Payment"
  >
    {customerCategory !== 'Walkin' ? (
      <MenuItem value="Credit">Credit</MenuItem>
    ) : (
      [
        <MenuItem value="MPesa" key="mpesa">MPesa</MenuItem>,
        <MenuItem value="Bonga" key="bonga">Lipa na Bonga</MenuItem>
      ]
    )}
  </Select>
</FormControl>

  {/* Conditionally show Bonga Points TextField */}
  {paymentMethod === 'Bonga' && (
    <FormControl fullWidth variant="outlined" margin="normal">
      <TextField
        label="Bonga Points"
        variant="outlined"
        value={bongaPoints}
        onChange={(e) => setBongaPoints(e.target.value)}
        fullWidth
      />
    </FormControl>
  )}

  <FormControl fullWidth variant="outlined" margin="normal">
    <InputLabel>Salesperson</InputLabel>
    <Autocomplete
      options={salespersons}
      getOptionLabel={(option) => option.name.toUpperCase()}
      renderInput={(params) => (
        <TextField {...params} label="Salesperson" variant="outlined" fullWidth />
      )}
      value={salespersons.find((sp) => sp.id === salesperson) || null}
      onChange={(event, newValue) => setSalesperson(newValue.name)}
    />
  </FormControl>

  <Button
    variant="contained"
    color="primary"
    disabled={issell}
    onClick={handleSell}
    style={{ marginTop: '16px' }}
  >
    {paymentMethod === 'MPesa'
      ? 'Initiate Payment'
      : paymentMethod === 'Bonga'
      ? 'Lipa na Bonga'
      : 'Sell'}
  </Button>
</Paper>

</Grid>

      </Grid>
        {/* MPESA Transactions Dialog */}
        <Dialog 
        open={openMPesaDialog} 
        onClose={() => setOpenMPesaDialog(false)} 
        maxWidth="md" 
        fullWidth
      >
        <DialogTitle>Select MPESA Transaction from ${shop}</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Transaction Id</TableCell>
                  <TableCell>Amount </TableCell>
                  <TableCell>PhoneNumber</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingTransactions.map((transaction) => (
                  <TableRow key={1}>
                    <TableCell>{transaction.transactionDate}</TableCell>
                    <TableCell>{transaction.mpesaReceiptNumber}</TableCell>
                    <TableCell>{transaction.amount}</TableCell>
                    <TableCell>{transaction.phoneNumber}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleMPesaTransactionSelect(transaction)}
                      >
                        Confirm
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMPesaDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default POSPage;
