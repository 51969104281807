import React, { useState, useEffect, useCallback } from 'react';
import {
  collection,
  getDocs,
  query,
  where,
  deleteDoc,
  addDoc,
  doc,
  getDoc,
  increment,
  arrayUnion,
  updateDoc,
  Timestamp,
  serverTimestamp,
} from 'firebase/firestore';
import { auth, db } from '../firebase';
import './SalesReturnPage.css';
import { toast } from 'react-toastify';

const SalesReturnPage = () => {
  const [userData, setUserData] = useState({
    role: '',
    selectedShop: '',
    shopList: [],
  });
  const [salesData, setSalesData] = useState([]);
  const [formState, setFormState] = useState({
    returnDate: '',
    reason: '',
    customReason: '',
    selectedCategory: '',
    returnBy: '',
    customerCategory: '',
    receipt: '',
    saleType: '',
    docId:'',
    selectedProduct: null,
    selectedSerial: '', // Added for serial selection (optional)
  });
  const [filteredSales, setFilteredSales] = useState({
    categories: [],
    products: [],
    // serials: [], // Uncomment if using separate serial dropdown
  });

  // Fetch user role and assigned shop (modified for admin fetching all shops)
  const fetchUserRole = useCallback(async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userEmail = user.email;
        const employeeRef = await getDocs(
          query(collection(db, 'employees'), where('email', '==', userEmail))
        );
        if (!employeeRef.empty) {
          const employee = employeeRef.docs[0].data();
          const updatedUserData = { role: employee.role };
          if (employee.role === 'shopmanager') {
            // Shopmanager: Fetch sales for the assigned shop only
            updatedUserData.selectedShop = employee.assignedShop;
            await fetchSalesData([employee.assignedShop]);
          } else if (employee.role === 'admin') {
            // Admin: Fetch sales for all shops in the shoplist
            const shopListRef = await getDocs(collection(db, 'shoplist'));
            const shopNames = shopListRef.docs.map((doc) => doc.data().shopName);
            updatedUserData.shopList = shopNames;

            // Fetch sales for all shops
            await fetchSalesData(shopNames);
          }
          setUserData(updatedUserData);
        }
      }
    } catch (error) {
      console.error('Error fetching user role:', error);
    }
  }, []);

  // Fetch sales data for the specified shops (modified to handle multiple shops for admin)
  const fetchSalesData = useCallback(async (shopNames) => {
    try {
      let allSales = [];

      for (const shopName of shopNames) {
        const mpesaSalesRef = collection(db, `sales/${shopName}/mpesa_sales`);
        const creditSalesRef = collection(db, `sales/${shopName}/credit_sales`);

        const [mpesaSalesSnapshot, creditSalesSnapshot] = await Promise.all([
          getDocs(mpesaSalesRef),
          getDocs(creditSalesRef),
        ]);

        const mpesaSales = mpesaSalesSnapshot.docs.map((doc) => ({
          ...doc.data(),
          docId: doc.id,
          saleType: 'mpesa_sales',
          shopName, // Add shopName to the sales data
        }));

        const creditSales = creditSalesSnapshot.docs.map((doc) => ({
          ...doc.data(),
          docId: doc.id,
          saleType: 'credit_sales',
          shopName, // Add shopName to the sales data
        }));

        allSales = [...allSales, ...mpesaSales, ...creditSales];
      }

      setSalesData(allSales);
    } catch (error) {
      console.error('Error fetching sales data:', error);
    }
  }, []);

  // Handle shop selection (for admins)
  const handleShopSelect = async (shopName) => {
    setUserData((prevState) => ({
      ...prevState,
      selectedShop: shopName,
    }));

    await fetchSalesData([shopName]); // Fetch sales for the selected shop
    setFilteredSales({ categories: [], products: [] /*, serials: []*/ });
    setFormState((prevState) => ({
      ...prevState,
      selectedCategory: '',
      selectedProduct: null,
      selectedSerial: '', // Reset selected serial if using separate serial dropdown
    }));
  };

  // Handle date change and filter sales by shop and date
  const handleDateChange = (date) => {
    setFormState((prevState) => ({ ...prevState, returnDate: date }));

    const salesByShopAndDate = salesData.filter((sale) => {
      const saleDate = sale.saleTimestamp.toDate().toISOString().split('T')[0];
      return saleDate === date;
    });

    const uniqueCategories = [
      ...new Set(
        salesByShopAndDate.flatMap((sale) =>
          sale.products.map((product) => product.category)
        )
      ),
    ];

    setFilteredSales({ categories: uniqueCategories, products: [] /*, serials: []*/ });
    setFormState((prevState) => ({
      ...prevState,
      selectedCategory: '',
      selectedProduct: null,
      selectedSerial: '', // Reset selected serial if using separate serial dropdown
    }));
  };

  // Handle category selection and filter unique products
  const handleCategorySelect = (category) => {
    // Filter products by the selected category and return date
    const filteredProducts = salesData
      .flatMap((sale) =>
        sale.products
          ? sale.products.map((product) => ({
              ...product,
              returnBy: sale.customerName,
              customerCategory: sale.customerCategory,
              receipt: sale.receipt || '',
              saleType: sale.saleType,
              docId: sale.docId, // Ensure docId from sale is passed to product
              saleTimestamp: sale.saleTimestamp,
            }))
          : []
      )
      .filter(
        (product) =>
          product.category === category &&
          new Date(product.saleTimestamp.seconds * 1000)
            .toISOString()
            .split('T')[0] === formState.returnDate
      );
  
    // Log or toast filtered products to ensure docId is present
    console.log('Filtered Products:', filteredProducts);
    filteredProducts.forEach((product) => {
     // toast.info(`Product Name: ${product.name}, DocId: ${product.docId}`);
    });
  
    setFilteredSales((prevState) => ({
      ...prevState,
      products: filteredProducts,
    }));
  
    setFormState((prevState) => ({
      ...prevState,
      selectedCategory: category,
      selectedProduct: null,
      selectedSerial: '',
    }));
  };
  const handleProductSelect = (productId) => {
    const selectedProduct = filteredSales.products.find((p) => p.productId === productId);
  
    if (selectedProduct) {
   //   toast.info(`Selected Product Name: ${selectedProduct.name}, DocId: ${selectedProduct.docId}`);
  
      setFormState((prevState) => ({
        ...prevState,
        returnBy: selectedProduct.returnBy,
        customerCategory: selectedProduct.customerCategory,
        receipt: selectedProduct.receipt,
        saleType: selectedProduct.saleType,
        docId: selectedProduct.docId, // Firestore document ID
        selectedProduct,
        selectedSerial: '',
      }));
    } else {
      toast.error(`Product not found for the given productId: ${productId}`);
    }
  };
    
// Handle form submission for sale return
const handleSubmit = async (e) => {
  e.preventDefault();
  const { selectedProduct } = formState;

  // Ensure userData.selectedShop is set
  if (!userData.selectedShop) {
    toast.error('Shop is not selected.');
    return;
  }

  if (selectedProduct) {
    // Ensure the selectedProduct has a valid docId and saleType
    const productId = selectedProduct.docId;
    const saleType = selectedProduct.saleType;
    const productName = selectedProduct.name;
    const serialNumber = selectedProduct.serial;

    if (!saleType) {
      toast.error('Sale type is missing for the selected product.');
      return;
    }

    const salesDocRef = doc(db, `sales/${userData.selectedShop}/${saleType}/${productId}`);

    // Log the document path to check if it's correct
    toast.info(`Attempting to delete document at path: sales/${userData.selectedShop}/${saleType}/${productId}`);

    try {
      // Check if the document exists before deleting
      const docSnapshot = await getDoc(salesDocRef);

      if (docSnapshot.exists()) {
        // Document exists, proceed with deletion
        await deleteDoc(salesDocRef);
        toast.success('Sale document deleted successfully');

        // Proceed with the rest of the process (adding to sales_return, updating received_products, etc.)
        await addDoc(collection(db, `sales/${userData.selectedShop}/sales_return`), {
          ...selectedProduct,
          returnReason: formState.reason === 'specify' ? formState.customReason : formState.reason,
          returnDate: serverTimestamp(), // Set returnDate to the current date and time
          shopName: userData.selectedShop,
        });

        // Handle received_products logic
        const shopProductRef = collection(db, 'received_products', userData.selectedShop, 'products');
        const shopProductQuery = query(shopProductRef, where('productName', '==', productName));
        const shopProductSnapshot = await getDocs(shopProductQuery);

        if (!shopProductSnapshot.empty) {
          const existingShopProductDoc = shopProductSnapshot.docs[0];
          await updateDoc(existingShopProductDoc.ref, {
            serialNumbers: arrayUnion(serialNumber),
            quantity: increment(1),
            totalItems: increment(1),
          });
        } else {
          await addDoc(shopProductRef, {
            productName: productName,
            serialNumbers: [serialNumber],
            quantity: 1,
            totalItems: 1,
            status: 'approved',
          });
        }

        // Reset form and filtered sales
        toast.success('Sale returned successfully');
        setFormState({
          returnDate: '',
          reason: '',
          customReason: '',
          selectedCategory: '',
          selectedProduct: null,
          selectedSerial: '',
        });
        setFilteredSales({ categories: [], products: [] });
        fetchSalesData();

      } else {
        toast.error('Sale document not found in Firestore');
        console.error('Sale document not found:', salesDocRef.path);
      }
    } catch (error) {
      console.error('Error returning sale:', error);
      toast.error('Failed to return sale. Please try again.');
    }
  } else {
    toast.error('Please select a product to return.');
  }
};


  useEffect(() => {
    fetchUserRole();
  }, [fetchUserRole]);

  return (
    <div className="sales-return-page">
      <h1>Sales Return</h1>
      <form onSubmit={handleSubmit} className="return-form">
        {/* Shop Selection (Only for Admins) */}
        {userData.role === 'admin' && (
          <div className="form-group">
            <label htmlFor="shop-select">Shop</label>
            <select
              id="shop-select"
              value={userData.selectedShop}
              onChange={(e) => handleShopSelect(e.target.value)}
              required
            >
              <option value="" disabled>
                Select Shop
              </option>
              {userData.shopList.map((shop, index) => (
                <option key={index} value={shop}>
                  {shop}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Date of Return Input */}
        <div className="form-group">
          <label htmlFor="return-date">Date of Sale</label>
          <input
            type="date"
            id="return-date"
            value={formState.returnDate}
            onChange={(e) => handleDateChange(e.target.value)}
            required
          />
        </div>

        {/* Category Selection */}
        <div className="form-group">
          <label htmlFor="category-select">Category</label>
          <select
            id="category-select"
            value={formState.selectedCategory}
            onChange={(e) => handleCategorySelect(e.target.value)}
            required
            disabled={!formState.returnDate || filteredSales.categories.length === 0}
          >
            <option value="" disabled>
              Select Category
            </option>
            {filteredSales.categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        {/* Product Selection */}
        <div className="form-group">
          <label htmlFor="product-select">Product Name</label>
          <select
            id="product-select"
            value={formState.selectedProduct ? formState.selectedProduct.productId : ''}
            onChange={(e) => handleProductSelect(e.target.value)}
            required
            disabled={!formState.selectedCategory || filteredSales.products.length === 0}
          >
            <option value="" disabled>
              Select Product
            </option>
            {filteredSales.products.map((product, index) => (
              <option key={index} value={product.productId}>
                {`${product.name} (Serial: ${product.serial})`}
              </option>
            ))}
          </select>
        </div>

        {/* Optional: Serial Number Selection */}
        {/* {formState.selectedProduct && (
          <div className="form-group">
            <label htmlFor="serial-select">Serial Number</label>
            <select
              id="serial-select"
              value={formState.selectedSerial}
              onChange={(e) => setFormState({ ...formState, selectedSerial: e.target.value })}
              required
            >
              <option value="" disabled>
                Select Serial Number
              </option>
              {filteredSales.products
                .filter((p) => p.productId === formState.selectedProduct.productId)
                .map((product, index) => (
                  <option key={index} value={product.serial}>
                    {product.serial}
                  </option>
                ))}
            </select>
          </div>
        )} */}

        {formState.selectedProduct && (
          <>
            <div className="form-group">
              <label>Buying Price</label>
              <input
                type="number"
                value={formState.selectedProduct.buyingprice}
                readOnly
              />
            </div>

            <div className="form-group">
              <label>Selling Price</label>
              <input
                type="number"
                value={formState.selectedProduct.price}
                readOnly
              />
            </div>

            <div className="form-group">
              <label>Serial Number</label>
              <input
                type="text"
                value={formState.selectedProduct.serial}
                readOnly
              />
            </div>
          </>
        )}

        {/* Return Reason */}
        <div className="form-group">
          <label htmlFor="reason-select">Reason for Return</label>
          <select
            id="reason-select"
            value={formState.reason}
            onChange={(e) =>
              setFormState({ ...formState, reason: e.target.value, customReason: '' })
            }
            required
          >
            <option value="" disabled>
              Select Reason
            </option>
            <option value="damaged">Damaged Product</option>
            <option value="wrong_item">Wrong Item</option>
            <option value="specify">Specify</option>
          </select>
        </div>

        {/* Custom Reason Input */}
        {formState.reason === 'specify' && (
          <div className="form-group">
            <label htmlFor="custom-reason">Specify Reason</label>
            <input
              type="text"
              id="custom-reason"
              value={formState.customReason}
              onChange={(e) =>
                setFormState({ ...formState, customReason: e.target.value })
              }
              required
            />
          </div>
        )}

        {/* Submit Button */}
        <button type="submit" disabled={!formState.selectedProduct /* || !formState.selectedSerial */}>
          Return Sale
        </button>
      </form>
    </div>
  );
};

export default SalesReturnPage;
