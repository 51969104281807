import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

function POSSettings() {
  return (
    <Box component="form" noValidate autoComplete="off">
      <TextField
        fullWidth
        label="Tax Rate"
        variant="outlined"
        margin="normal"
      />
      <TextField
        fullWidth
        label="Discount Rate"
        variant="outlined"
        margin="normal"
      />
      <Button variant="contained" color="primary" sx={{ mt: 2 }}>
        Save Changes
      </Button>
    </Box>
  );
}

export default POSSettings;
