import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DevicesIcon from '@mui/icons-material/Devices';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ReplayIcon from '@mui/icons-material/Replay';

// Initialize Firebase and Firestore
const auth = getAuth();
const db = getFirestore();

// Custom button styling for square buttons with icons
const FancyButton = styled(Button)({
  backgroundColor: '#ffffff', // White background for contrast
  color: '#000000', // Black text color
  width: '120px', // Width of the button
  height: '120px', // Height of the button to make it square
  fontSize: '14px', // Font size
  fontWeight: 'bold', // Bold text
  textTransform: 'none', // Disable uppercase transformation
  borderRadius: '20px', // Rounded corners
  boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.15)', // Box shadow for a lifted effect
  '&:hover': {
    backgroundColor: '#f0f0f0', // Light gray on hover
  },
  display: 'flex', // Align content within the button
  flexDirection: 'column', // Align items vertically
  justifyContent: 'center', // Center the content horizontally
  alignItems: 'center', // Center the content vertically
  gap: '10px', // Space between icon and text
  padding: '15px', // Padding inside the button
  border: '2px solid #00CC00', // Border color
});

// Icons for each report
const icons = {
  dailySales: <ShoppingCartIcon fontSize="large" />,
  mpesaTill: <AttachMoneyIcon fontSize="large" />,
  devicesPurchases: <DevicesIcon fontSize="large" />,
  // New icon for transferred products
  salesReturn: <ReplayIcon fontSize="large" />, // New icon for sales return
  approvedProducts: <AssignmentTurnedInIcon fontSize="large" />, // New icon for approved products
};

function ReportsPage() {
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      const user = auth.currentUser;
      if (user) {
        const email = user.email;
        try {
          const q = query(collection(db, 'employees'), where('email', '==', email));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const role = userDoc.data().role;
            setUserRole(role);
            return userDoc.data().role;
          } else {
            console.log('No such document!');
            setUserRole('guest'); // Default role or handle as necessary
          }
        } catch (error) {
          console.error('Error fetching user role:', error);
        }
      }
    };

    fetchUserRole();
  }, []);

  if (userRole === null) {
    return <Typography>Loading...</Typography>; // Or any loading indicator
  }

  return (
    <Box padding={4}>
      <Typography variant="h4" gutterBottom>
        Reports
      </Typography>
      <Box display="flex" flexDirection="row" gap={3} flexWrap="wrap">
        {/* Render buttons based on user role */}
        {(userRole === 'admin' || userRole === 'salesperson' || userRole === 'shopmanager') && (
          <>
            <FancyButton variant="contained" component={Link} to="/reports/DailyReportsPage">
              {icons.dailySales}
              All Sales
            </FancyButton>

            <FancyButton variant="contained" component={Link} to="/reports/MpesaTillStatement">
              {icons.mpesaTill}
              Mpesa Till
            </FancyButton>
            <FancyButton variant="contained" component={Link} to="/reports/SalesReturnReports">
              {icons.salesReturn}
              Sales Return
            </FancyButton>
          </>
        )}

        {userRole === 'admin' && (
          <>
            <FancyButton variant="contained" component={Link} to="/reports/DevicesPurchases">
              {icons.devicesPurchases}
              All Purchases
            </FancyButton>
          </>
        )}
      </Box>
    </Box>
  );
}

export default ReportsPage;
