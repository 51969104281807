// src/reports/ApprovedProductsReportPage.js
import React, { useState } from 'react';
import {
  TextField, Button, Grid, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton,
  Select, MenuItem, InputLabel, FormControl, Tooltip
} from '@mui/material';
import { SaveAlt as PdfIcon, TableChart as ExcelIcon } from '@mui/icons-material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const ApprovedProductsReportPage = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedStore, setSelectedStore] = useState('');
  const [reportData, setReportData] = useState([]);

  // Example stores
  const stores = ['Store 1', 'Store 2', 'Store 3'];

  // Dummy report data for demonstration
  const sampleReportData = [
    {
      date: '2024-10-01',
      from: 'Nairobi',
      to: 'Mombasa',
      category: 'Electronics',
      productName: 'Samsung TV',
      serials: '12345, 54321',
      transferredBy: 'John Doe',
    },
    {
      date: '2024-10-02',
      from: 'Kisumu',
      to: 'Nairobi',
      category: 'Appliances',
      productName: 'LG Refrigerator',
      serials: '67890, 09876',
      transferredBy: 'Jane Smith',
    },
    // Add more data as needed
  ];

  // Handler to filter report data based on selected criteria
  const handleFilter = () => {
    // In a real application, fetch data from an API based on filters
    // Here, we'll use the sample data for demonstration
    const filteredData = sampleReportData.filter((item) => {
      const itemDate = new Date(item.date);
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;

      const isWithinDateRange =
        (!start || itemDate >= start) && (!end || itemDate <= end);
      const isStoreSelected = selectedStore ? item.to === selectedStore || item.from === selectedStore : true;

      return isWithinDateRange && isStoreSelected;
    });

    setReportData(filteredData);
  };

  // Handler to export report data as PDF
  const handleExportPDF = () => {
    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text('Approved Products Report', 14, 22);
    doc.setFontSize(11);
    doc.setTextColor(100);

    const tableColumn = ['Date', 'From', 'To', 'Category', 'Product Name', 'Serials', 'Transferred By'];
    const tableRows = [];

    reportData.forEach(item => {
      const rowData = [
        item.date,
        item.from,
        item.to,
        item.category,
        item.productName,
        item.serials,
        item.transferredBy,
      ];
      tableRows.push(rowData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30,
      theme: 'striped',
      headStyles: { fillColor: [10, 126, 7] }, // Safaricom green
    });

    doc.save('approved_products_report.pdf');
  };

  // Handler to export report data as Excel
  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(reportData);
    const workbook = { Sheets: { 'Approved Products': worksheet }, SheetNames: ['Approved Products'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'approved_products_report.xlsx');
  };

  return (
    <div style={{ padding: '24px', backgroundColor: '#F5F5F5', minHeight: '100vh' }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{
          fontFamily: 'Roboto, sans-serif',
          background: 'linear-gradient(45deg, #0A7E07, #4CAF50)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundClip: 'text',
        }}
      >
        Approved Products Report
      </Typography>

      {/* Filters Section */}
      <Paper elevation={3} style={{ padding: '16px', marginBottom: '24px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Select Store</InputLabel>
              <Select
                value={selectedStore}
                onChange={(e) => setSelectedStore(e.target.value)}
                label="Select Store"
              >
                <MenuItem value="">
                  <em>All Stores</em>
                </MenuItem>
                {stores.map((store, index) => (
                  <MenuItem key={index} value={store}>
                    {store}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Button
          variant="contained"
          color="primary"
          onClick={handleFilter}
          style={{ marginTop: '16px' }}
          fullWidth
        >
          Filter Report
        </Button>
      </Paper>

      {/* Report Table */}
      <Paper elevation={3}>
        <TableContainer>
          <Table>
            <TableHead style={{ backgroundColor: '#0A7E07' }}>
              <TableRow>
                <TableCell style={{ color: '#FFFFFF' }}>Date</TableCell>
                <TableCell style={{ color: '#FFFFFF' }}>From</TableCell>
                <TableCell style={{ color: '#FFFFFF' }}>To</TableCell>
                <TableCell style={{ color: '#FFFFFF' }}>Category</TableCell>
                <TableCell style={{ color: '#FFFFFF' }}>Product Name</TableCell>
                <TableCell style={{ color: '#FFFFFF' }}>Serials</TableCell>
                <TableCell style={{ color: '#FFFFFF' }}>Transferred By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData.length > 0 ? (
                reportData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>{row.from}</TableCell>
                    <TableCell>{row.to}</TableCell>
                    <TableCell>{row.category}</TableCell>
                    <TableCell>{row.productName}</TableCell>
                    <TableCell>{row.serials}</TableCell>
                    <TableCell>{row.transferredBy}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Export Buttons */}
      {reportData.length > 0 && (
        <div style={{ marginTop: '24px', textAlign: 'center' }}>
          <Tooltip title="Download as PDF">
            <IconButton onClick={handleExportPDF}>
              <PdfIcon sx={{ color: '#D32F2F', fontSize: '40px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download as Excel">
            <IconButton onClick={handleExportExcel}>
              <ExcelIcon sx={{ color: '#0A7E07', fontSize: '40px' }} />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default ApprovedProductsReportPage;
