import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Tooltip,
  Button,
  Checkbox,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { collection,increment, arrayUnion, getDocs,getDoc,serverTimestamp, runTransaction,query, where, doc, updateDoc, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path according to your file structure
import { toast } from 'react-toastify';
import {getAuth} from 'firebase/auth';
import DoneIcon from "@mui/icons-material/Done";
import * as colors from "@mui/material/colors";

// Import the stamp image (assumed to be in your assets folder)
import stampImage from "../assets/approved.jpg"; // Adjust the path as needed
import NotificationService from "../NotificationService";

const theme = (darkMode) => createTheme({
  palette: {
    mode: darkMode ? 'dark' : 'light',
    primary: {
      main: '#4CAF50',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#ffffff',
          '&:hover': {
            backgroundColor: '#388E3C',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px 8px',
          fontSize: '0.725rem',
        },
        head: {
          fontSize: '0.78rem',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: darkMode ? '#333333' : 'rgba(0, 0, 0, 0.04)',
          },
          '&:nth-of-type(even)': {
            backgroundColor: darkMode ? '#444444' : '#ffffff',
          },
        },
      },
    },
  },
});

const ApproveProducts = () => {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedShop, setSelectedShop] = useState('');
  const [email, setemail] = useState('');
  const [selectedFabrics, setSelectedFabrics] = useState([]);
  const [shopDetails, setShopDetails] = useState({});
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [stockDetails, setStockDetails] = useState({});
  const [pendingProducts, setPendingProducts] = useState([]);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [shops, setShops] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedSerialNumbers, setSelectedSerialNumbers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false); // Confirmation dialog state

  useEffect(() => { 
    fetchData();
  }, []);
 
  const fetchData = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const employeeQuery = query(
        collection(db, 'employees'),
        where('email', '==', user.email)
      );
      setemail(user.email);
      const employeeSnapshot = await getDocs(employeeQuery);
      const employeeData = employeeSnapshot.docs[0].data();
  
      const { role, assignedShop } = employeeData;
  
      let inventoryProducts = [];
      role === 'admin' ? setIsAdmin(true) : setIsAdmin(false);
  
      if (role === 'admin') {
        // Fetch all products from the inventory collection
        const inventoryCollectionRef = collection(db, 'inventory');
        const inventorySnapshot = await getDocs(inventoryCollectionRef);
        inventoryProducts = inventorySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      } else if (role === 'shopmanager' || role === 'salesperson') {
        // Fetch products where status is 'pending' or 'rejected' and shop matches assignedShop
        const inventoryCollectionRef = collection(db, 'inventory');
        const shopManagerQuery = query(
          inventoryCollectionRef,
          where('shop', '==', assignedShop),
          where('status', 'in', ['pending', 'rejected'])
        );
        const shopManagerSnapshot = await getDocs(shopManagerQuery);
        inventoryProducts = shopManagerSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      }
  
      // Set the fetched inventory products to state
      setPendingProducts(inventoryProducts);
  
      // Fetch and set shops with shopName
      const shopsCollection = collection(db, 'shoplist');
      const shopsSnapshot = await getDocs(shopsCollection);
      setShops(shopsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  
      // Fetch and set employees
      const employeesCollection = collection(db, 'employees');
      const employeesSnapshot = await getDocs(employeesCollection);
      setEmployees(employeesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  
      // Fetch and set products
      const productCollection = collection(db, 'products');
      const productSnapshot = await getDocs(productCollection);
      setProducts(productSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  
      // Fetch and set categories
      const categoryCollection = collection(db, 'categories');
      const categorySnapshot = await getDocs(categoryCollection);
      setCategories(categorySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error("Error fetching data: ", error);
      // Optionally, display an error message or toast notification
    }
  };

  const handleSelectAll = (event) => {
    setAllSelected(event.target.checked);
    setSelectedProducts(event.target.checked ? products.map((p) => p) : []);
  };

  const handleProductSelect = (product, event) => {
    if (event.target.checked) {
      setSelectedProducts([...selectedProducts, product]);
    } else {
      setSelectedProducts(selectedProducts.filter((p) => p !== product));
    }
  };

  const handleApprove = () => {
    if (selectedProducts.length > 0) {
      setShowConfirmDialog(true); // Show confirmation dialog
    }
  };

  const handleProceed = () => {
    // Code for final approval submission can go here
    console.log("Products approved:", selectedProducts);
    setShowConfirmDialog(false);
  };

  const columns = [
   
    {
      name: 'shop',
      label: 'Shop',
    },
    {
      name: 'productName',
      label: 'Product Name',
    },
    {
      name: 'quantity',
      label: 'Total Items',
    },
    {
      name: 'serialNumbers',
      label: 'S/Numbers',
      options: {
        customBodyRender: (value) => {
          if (!Array.isArray(value)) {
            return <div>No serial numbers</div>; // Handle case where value is not an array or undefined
          }
          return value.map((serial, index) => (
            <div key={index}>
              {index + 1}. {serial}
            </div>
          ));
        },
      },
    },
    {
      name: 'buyingPrice',
      label: 'Buying Price',
      options: {
        sort: true,
        setCellProps: () => ({ align: 'right' }),
      },
    },
    {
      name: 'sellingPrice',
      label: 'Selling Price',
      options: {
        sort: true,
        setCellProps: () => ({ align: 'right' }),
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        sort: true,
        setCellProps: () => ({ align: 'right' }),
      },
    },
   
  ];

   // Individual Approve Product
   const handleApproveProduct = async (productId, shopName, productName, quantity, serialNumbers) => {
    try {
      const inventoryProductRef = doc(db, 'inventory', productId);
      const shopProductRef = collection(db, 'received_products', shopName, 'products');
      const shopProductQuery = query(shopProductRef, where('productName', '==', productName));
      const shopProductSnapshot = await getDocs(shopProductQuery);
      const productDoc = await getDoc(inventoryProductRef);
      const productData = productDoc.data();

      if (!shopProductSnapshot.empty) {
        const existingShopProductDoc = shopProductSnapshot.docs[0];
        await updateDoc(existingShopProductDoc.ref, {
          serialNumbers: arrayUnion(...serialNumbers),
          quantity: increment(quantity),
          totalItems: increment(quantity),
        });
      } else {
        await addDoc(shopProductRef, {
          ...productData,
          status: 'approved',
        });
      }
      await NotificationService.addNotification('admin', `Product for ${shopName} shop have been approved.`);
      await updateDoc(inventoryProductRef, { status: 'approved' });
      toast.success('Product approved and added to the shop successfully!');
    } catch (error) {
      console.error('Error approving product: ', error);
      toast.error('Failed to approve product.', error.message);
    }
  };
  // Open Reject Dialog
 

  // Individual Reject Product
 
  

  const options = {
    filterType: "checkbox",
    selectableRows: "multiple",
    download: false,
    print: false,
    rowsPerPage: 5,
    customToolbarSelect: (selectedRows) => {
      return (
        <div>
          {/* Approve Button */}
          <Tooltip title="Approve selected products">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
              
                const indexesToPrint = selectedRows.data.map((row, k) => row.dataIndex);
                let temp = [];
                
                
                for (let i = 0; i < pendingProducts.length; i++) {
                  if (indexesToPrint.includes(i)) {
                    temp.push(pendingProducts[i]['id']);
                    handleApproveProduct(pendingProducts[i]['id'],pendingProducts[i]['shop'],pendingProducts[i]['productName'],pendingProducts[i]['quantity'],pendingProducts[i]['serialNumbers']);
                  }
                 //toast.info(temp[0]);
                }
                fetchData();
                setSelectedFabrics(temp);
              }}
              startIcon={<DoneIcon />}
              sx={{ mr: 2 }}
            >
              Approve
            </Button>
          </Tooltip>
        </div>
      );
    },
  };

  return (
    <ThemeProvider theme={theme(false)}>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h6" gutterBottom>
          Approve Products
        </Typography>
        <MUIDataTable title="Products for Approval" data={pendingProducts} columns={columns} options={options} />
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
         
        </Box>

        {/* Confirmation Dialog */}
        <Dialog open={showConfirmDialog} onClose={() => setShowConfirmDialog(false)}>
          <DialogTitle>Verify Approval</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Do you want to verify and proceed with the approval of the selected products?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowConfirmDialog(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleProceed} color="primary" variant="contained">
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default ApproveProducts;
