import React, { useState ,useEffect} from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { collection,increment, arrayUnion, getDocs,getDoc,serverTimestamp, runTransaction,query, where, doc, updateDoc, addDoc } from 'firebase/firestore';
import {auth, db } from '../firebase';
import {
  Box,
  Button,
  Tooltip,
  Checkbox,
  ListItem,
  CssBaseline,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
  ListItemText,
  Chip,
} from "@mui/material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import RefreshIcon from "@mui/icons-material/Refresh";
import { toast } from "react-toastify";

const getMuiTheme = () =>
  createTheme({
    palette: {
      primary: {
        main: "#4CAF50", // Safaricom green
      },
    },
  });

const StockTransfer = () => {
  const [transferData, setTransferData] = useState([]);
  const [fromShop, setFromShop] = useState("");
  const [toShop, setToShop] = useState("");
  const [product, setProduct] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [buyingPrice, setBuyingPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [transferOfficer, setTransferOfficer] = useState("");
  const [recipient, setRecipient] = useState("");
  const [transferDate, setTransferDate] = useState("");
  const [totalAmountTransferred, setTotalAmountTransferred] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);  // Assuming you have some logic to set this
  const [rejectReason, setRejectReason] = useState('');
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState('');
  const [selectedShopName, setSelectedShopName] = useState('');

  const [shops, setShops] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedShop, setSelectedShop] = useState('');
  const [email, setemail] = useState('');
  const [selectedFabrics, setSelectedFabrics] = useState([]);
  const [shopDetails, setShopDetails] = useState({});
  const [openProductDialog, setOpenProductDialog] = useState(false);
  const [openStockDialog, setOpenStockDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [stockDetails, setStockDetails] = useState({});
  const [pendingProducts, setPendingProducts] = useState([]);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [selectedSerialNumbers, setSelectedSerialNumbers] = useState([]);
 

  useEffect(() => {
  
    
  
    fetchData();
  }, []);
 
  const fetchData = async () => {
    try {
     
    /*  const user = auth.currentUser;
      const employeeQuery = query(
        collection(db, 'employees'),
        where('email', '==', user.email)
      );
      setemail(user.email);
      const employeeSnapshot = await getDocs(employeeQuery);
      const employeeData = employeeSnapshot.docs[0].data();
  
      const { role, assignedShop } = employeeData;
  
      let inventoryProducts = [];
      role === 'admin' ? setIsAdmin(true) : setIsAdmin(false);
  
      if (role === 'admin') {
        // Fetch all products from the inventory collection
        const inventoryCollectionRef = collection(db, 'inventory');
        const inventorySnapshot = await getDocs(inventoryCollectionRef);
        inventoryProducts = inventorySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      } else if (role === 'shopmanager' || role === 'salesperson') {
        // Fetch products where status is 'pending' or 'rejected' and shop matches assignedShop
        const inventoryCollectionRef = collection(db, 'inventory');
        const shopManagerQuery = query(
          inventoryCollectionRef,
          where('shop', '==', assignedShop),
          where('status', 'in', ['pending', 'rejected'])
        );
        const shopManagerSnapshot = await getDocs(shopManagerQuery);
        inventoryProducts = shopManagerSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      }
  
      // Set the fetched inventory products to state
      setPendingProducts(inventoryProducts);
  */
      // Fetch and set shops with shopName
      const shopsCollection = collection(db, 'shoplist');
      const shopsSnapshot = await getDocs(shopsCollection);
      setShops(shopsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  
      // Fetch and set employees
    
      // Fetch and set products
      const productCollection = collection(db, 'products');
      const productSnapshot = await getDocs(productCollection);
      setProducts(productSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  
      // Fetch and set categories
      const categoryCollection = collection(db, 'categories');
      const categorySnapshot = await getDocs(categoryCollection);
      setCategories(categorySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error("Error fetching data: ", error);
      // Optionally, display an error message or toast notification
    }
  };
 
  const getEmployeeByEmail = async () => {
    try {
      // Get the current user's email from the authentication state
      const currentUserEmail = auth.currentUser?.email;
      
      if (!currentUserEmail) {
        console.error('No authenticated user found');
        return;
      }
  
      // Reference to the employees collection
      const employeesCollection = collection(db, 'employees');
  
      // Create a query to filter by email
      const q = query(employeesCollection, where('email', '==', currentUserEmail));
  
      // Execute the query
      const employeesSnapshot = await getDocs(q);
  
      // Get the employee data from the query results
      const employees = employeesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
  
      // Assuming each email is unique, get the first result
      if (employees.length > 0) {
        const employee = employees[0];
        console.log('Employee Found:', employee.name); // Get employee's name
        return employee.name; // Return the employee name if needed
      } else {
        console.log('No employee found for the current user email');
        return null;
      }
    } catch (error) {
      console.error('Error getting employee by email:', error);
    }
  };
  
  const handleAddRow = () => {
    const newRow = {
      fromShop,
      toShop,
      product,
      serialNumber,
      buyingPrice: parseFloat(buyingPrice),
      sellingPrice,
    };
    setTransferData([...transferData, newRow]);
    setTotalAmountTransferred(
      (prevTotal) => prevTotal + parseFloat(buyingPrice)
    );
    // Clear form fields
    setFromShop("");
    setToShop("");
    setProduct("");
    setSerialNumber("");
    setBuyingPrice("");
    setSellingPrice("");
  };

  // PDF generation
  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text("Stock Transfer Report", 10, 10);
    doc.text(`Transfer Date: ${transferDate}`, 10, 20);
    doc.text(`Transfer Officer: ${transferOfficer}`, 10, 30);
    doc.text(`Recipient: ${recipient}`, 10, 40);
    doc.text(`Total Amount Transferred: Ksh. ${totalAmountTransferred}`, 10, 50);

    autoTable(doc, {
      head: [
        [
          "From Shop",
          "To Shop",
          "Product",
          "Serial Number",
          "Buying Price",
          "Selling Price",
        ],
      ],
      body: stockDetails.map((item) => [
        item.fromShop,
        item.toShop,
        item.product,
        item.serialNumber,
        `Ksh. ${item.buyingPrice.toFixed(2)}`,
        `Ksh. ${item.sellingPrice}`,
      ]),
    });

    const lastY = doc.lastAutoTable.finalY;
    doc.text("Transfer Officer Signature: ___________________", 10, lastY + 20);
    doc.text("Recipient Signature: ___________________", 10, lastY + 30);
    doc.save("stock_transfer_report.pdf");
  };

  // Excel generation
  const generateExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      transferData.map((item) => ({
        "From Shop": item.fromShop,
        "To Shop": item.toShop,
        Product: item.product,
        "Serial Number": item.serialNumber,
        "Buying Price": `Ksh. ${item.buyingPrice.toFixed(2)}`,
        "Selling Price": `Ksh. ${item.sellingPrice}`,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stock Transfer");
    XLSX.writeFile(workbook, "stock_transfer.xlsx");
  };

   // Handle change in serial number selection
   const handleSerialChange = (event) => {
    const {
      target: { value },
    } = event;

    // If value is a string, it means a single selection, else multiple selections
    setSelectedSerialNumbers(typeof value === 'string' ? value.split(',') : value);
  };

  // Handle deletion of selected chips
  const handleDelete = (serialToDelete) => () => {
    setSelectedSerialNumbers((serials) => serials.filter((serial) => serial !== serialToDelete));
  };

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    download: false,
    print: false,
    viewColumns: false,
    customToolbar: () => (
      <React.Fragment>
        <Tooltip title="Download PDF">
          <IconButton onClick={generatePDF}>
            <i className="fas fa-file-pdf" style={{ color: "red" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download Excel">
          <IconButton onClick={generateExcel}>
            <i className="fas fa-file-excel" style={{ color: "green" }} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    ),
  };

  const handleShopChange = (event) => {
    const selectedShopId = event.target.value;
    setSelectedShop(selectedShopId);
    const shop = shops.find((shop) => shop.id === selectedShopId);
    setShopDetails(shop || {});
    
  };

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setStockDetails((prev) => ({ ...prev, category: selectedCategory }));
    setFilteredProducts(products.filter((product) => product.category === selectedCategory));
  };

  const handleProductChange = async (event) => {
    const selectedProductName = event.target.value;
    
    const selectedProduct = products.find((product) => product.name === selectedProductName);
    
    setStockDetails((prev) => ({
      ...prev,
      product: selectedProductName,
      buyingPrice: selectedProduct?.buyingPrice || '',
      sellingPrice: selectedProduct?.sellingPrice || '',
    }));

    if (selectedProduct && stockDetails.category && stockDetails.fromStore) {
      const serialNumbersRef = collection(
        db,
        'received_products',
        stockDetails.fromStore,
        'products'
      );
      const serialNumbersQuery = query(
        serialNumbersRef,
        where('category', '==', stockDetails.category),
        where('productName', '==', selectedProductName)
      );
      const serialNumbersSnapshot = await getDocs(serialNumbersQuery);
      if (!serialNumbersSnapshot.empty) {
        const productData = serialNumbersSnapshot.docs[0].data();
        setSerialNumbers(productData.serialNumbers || []);
      }
    }
  };

 

  // Function to handle deletion of a serial number (for interactivity)
  const handleDeleteSerial = (serial, serialList) => {
    const updatedSerials = serialList.filter((s) => s !== serial);
    console.log("Updated Serial Numbers:", updatedSerials);
    // You can update the backend or state with updatedSerials
  };

  const handleStockDetailsChange = (key, value) => {
    setStockDetails((prevDetails) => ({
      ...prevDetails,
      [key]: value,
    }));
  };

  const transferProduct = async (fromStore, toStore, productName, selectedSerialNumbers) => {
    const fromStoreRef = collection(db, `received_products/${fromStore}/products`);
    const toStoreRef = collection(db, `inventory`);
    const transferredStockRef = collection(db, `received_products/${fromStore}/transferred_stock`);
    const employeename= await getEmployeeByEmail();
    try {
      await runTransaction(db, async (transaction) => {
        // Get the product from the fromStore
        const fromStoreQuery = query(fromStoreRef, where('productName', '==', productName));
        const fromStoreSnapshot = await getDocs(fromStoreQuery);
  
        if (fromStoreSnapshot.empty) {
          toast.error('Product does not exist in the fromStore');
          throw new Error('Product does not exist in the fromStore');
        }
  
        const fromStoreDoc = fromStoreSnapshot.docs[0];
        const fromStoreData = fromStoreDoc.data();
        const remainingSerialNumbers = fromStoreData.serialNumbers.filter(
          (serial) => !selectedSerialNumbers.includes(serial)
        );
        const quantityTransferred = selectedSerialNumbers.length;
  
        // Update fromStore document
        transaction.update(fromStoreDoc.ref, {
          serialNumbers: remainingSerialNumbers,
          totalItems: fromStoreData.totalItems - quantityTransferred,
          quantity: fromStoreData.quantity - quantityTransferred,
        });
  
        // Search for the product in the toStore
      
          // If the product doesn't exist in toStore, create a new document
          const newDocRef = doc(toStoreRef); // Create a new document reference
          transaction.set(newDocRef, {
            ...fromStoreData,
            productName: productName,
            serialNumbers: selectedSerialNumbers,
            totalItems: quantityTransferred,
            quantity: quantityTransferred,
            shop: toStore,
            receivedBy: employeename,
            status: 'pending',
            receivedDate: serverTimestamp(), // Use Firebase server timestamp
          });
       
  
        // Save to transferred_stock collection
        const newTransferDocRef = doc(transferredStockRef); // Create a new document reference
        transaction.set(newTransferDocRef, {
          fromStore,
          toStore,
          productName: fromStoreData.productName,
          serialNumbers: selectedSerialNumbers,
          status: 'approved',
          timestamp: serverTimestamp(), // Use Firebase server timestamp
        });
      });
  
      toast.success('Product transfer successful');
      // Clear the categories state
     // Clear the products state
      setStockDetails([]); // Reset stock details
  
    } catch (error) {
      toast.error(`Error during product transfer: ${error.message}`);
    }
  };

  const handleTransfer = async () => {
    
      const product = products.find((p) => p.name === stockDetails.product);
      const productName = product ? product.name : '';
  
      if (stockDetails.fromStore && stockDetails.toStore && productName && selectedSerialNumbers.length > 0) {
        await transferProduct(stockDetails.fromStore, stockDetails.toStore, productName, selectedSerialNumbers);
      } else {
        toast("Please select all required fields and serial numbers.",productName);
      }
 
    
  };

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <CssBaseline />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h6" gutterBottom>
          Stock Transfer
        </Typography>

        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel>From Shop</InputLabel>
            <Select
              value={stockDetails.fromStore}
              onChange={(e) => setStockDetails((prev) => ({ ...prev, fromStore: e.target.value }))}
              label="From Shop"
            >
                {shops.map((shop) => (
                    <MenuItem key={shop.id} value={shop.shopName.toLowerCase()}>
                      {shop.shopName.toUpperCase()}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>To Shop</InputLabel>
            <Select
              value={stockDetails.toStore}
              onChange={(e) => setStockDetails((prev) => ({ ...prev, toStore: e.target.value }))}
              label="To Shop"
            >
               {shops
                    .filter((shop) => shop.shopName !== stockDetails.fromStore)
                    .map((shop) => (
                      <MenuItem key={shop.id} value={shop.shopName.toLowerCase()}>
                        {shop.shopName.toUpperCase()}
                      </MenuItem>
                    ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
          <InputLabel>Category</InputLabel>
                <Select
                  value={stockDetails.category}
                  onChange={handleCategoryChange}
                  label="Category"
                  sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#2e7d32' } } }}
                >
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.name.toLowerCase()}>
                      {category.name.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>

              
        </Box>

        {/* Second Row (Serial Number, Buying Price, Selling Price) */}
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <FormControl fullWidth disabled={!stockDetails.category}>
                <InputLabel>Product</InputLabel>
                <Select
                  value={stockDetails.product}
                  onChange={handleProductChange}
                  label="Product"
                  sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#2e7d32' } } }}
                >
                  {filteredProducts.map((product) => (
                    <MenuItem key={product.id} value={product.name.toLowerCase()}>
                      {product.name.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
      
          <TextField
                fullWidth
                label="Buying Price"
                variant="outlined"
                value={stockDetails.buyingPrice}
                
                sx={{ marginBottom: '16px', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#2e7d32' } } }}
              />

           <TextField
                fullWidth
                label="Selling Price"
                variant="outlined"
                value={stockDetails.sellingPrice}
                sx={{ marginBottom: '16px', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#2e7d32' } } }}
              />
        </Box>

        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <FormControl fullWidth>
      <InputLabel>Serial Numbers</InputLabel>
                     
      <Select
        multiple
        value={selectedSerialNumbers}
        onChange={handleSerialChange}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} onDelete={handleDelete(value)} />
            ))}
          </Box>
        )}
      >
        {serialNumbers.map((serial) => (
          <MenuItem key={serial} value={serial}>
            <Checkbox checked={selectedSerialNumbers.indexOf(serial) > -1} />
            <ListItemText primary={serial} />
          </MenuItem>
        ))}
      </Select>

      {/* Display selected serials as chips below the dropdown */}
     
     {/* <Box sx={{ mt: 2 }}>
        {selectedSerialNumbers.map((serial) => (
          <Chip
            key={serial}
            label={serial}
            onDelete={handleDelete(serial)}
            sx={{ margin: 0.5 }}
          />
        ))}
      </Box>*/}
   
              </FormControl>


        </Box>
         <Box sx={{ mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleTransfer}>
                Transfer
              </Button>
            </Box>

        {/* Transfer Details */}
      
      </Box>
    </ThemeProvider>
  );
};

export default StockTransfer;
