import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Adjust the import according to your Firebase configuration file
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  onSnapshot
} from 'firebase/firestore';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const TillSettings = () => {
  const [shops, setShops] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentShop, setCurrentShop] = useState(null);
  const [formData, setFormData] = useState({
    shopname: '',
    consumerKey: '',
    consumerSecret: '',
    businessShortCode: '',
    tillShortCode: '',
    passkey: '',
    accountReference: '',
    transactionDesc: '',
  });

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'shops'), (snapshot) => {
      const data = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setShops(data);
    });

    return () => unsubscribe();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOpen = (shop = null) => {
    setCurrentShop(shop);
    setFormData(shop || {
      shopname: '',
      consumerKey: '',
      consumerSecret: '',
      businessShortCode: '',
      tillShortCode: '',
      passkey: '',
      accountReference: '',
      transactionDesc: '',
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentShop(null);
  };

  const handleSubmit = async () => {
    try {
      if (currentShop) {
        const shopRef = doc(db, 'shops', currentShop.id);
        await updateDoc(shopRef, formData);
      } else {
        await addDoc(collection(db, 'shops'), formData);
      }
      handleClose();
    } catch (error) {
      console.error("Error adding/updating document: ", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const shopRef = doc(db, 'shops', id);
      await deleteDoc(shopRef);
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={() => handleOpen()}>
        Add Shop
      </Button>
      <List>
        {shops.map(shop => (
          <ListItem key={shop.id}>
            <ListItemText
              primary={shop.shopname}
              secondary={`${shop.businessShortCode} - ${shop.tillShortCode}`}
            />
            <IconButton edge="end" aria-label="edit" onClick={() => handleOpen(shop)}>
              <EditIcon />
            </IconButton>
            <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(shop.id)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentShop ? 'Edit Shop' : 'Add Shop'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="shopname"
            label="Shop Name"
            type="text"
            fullWidth
            value={formData.shopname}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="consumerKey"
            label="Consumer Key"
            type="text"
            fullWidth
            value={formData.consumerKey}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="consumerSecret"
            label="Consumer Secret"
            type="text"
            fullWidth
            value={formData.consumerSecret}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="businessShortCode"
            label="Business Short Code"
            type="text"
            fullWidth
            value={formData.businessShortCode}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="tillShortCode"
            label="Till Short Code"
            type="text"
            fullWidth
            value={formData.tillShortCode}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="passkey"
            label="Passkey"
            type="text"
            fullWidth
            value={formData.passkey}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="accountReference"
            label="Account Reference"
            type="text"
            fullWidth
            value={formData.accountReference}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="transactionDesc"
            label="Transaction Description"
            type="text"
            fullWidth
            value={formData.transactionDesc}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {currentShop ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TillSettings;
