import React, { useState } from "react";
import { Box, Button, FormControl, FormGroup, FormControlLabel, Checkbox, Typography } from "@mui/material";

// Reusable Sales Categories Selection Component
const SalesCategorySelection = ({ title, selectedCategories, handleCategoryChange }) => (
  <>
    <Typography variant="h6" sx={{ mb: 2, color: "#0A7E07" }}>{title}</Typography>
    <FormControl component="fieldset" sx={{ mb: 4 }}>
      <FormGroup row>
        {Object.keys(selectedCategories).map((category) => (
          <FormControlLabel
            key={category}
            control={
              <Checkbox
                checked={selectedCategories[category]}
                onChange={handleCategoryChange}
                name={category}
                sx={{ color: "#0A7E07", '&.Mui-checked': { color: "#0A7E07" } }}
              />
            }
            label={category.replace('_', ' ')}
          />
        ))}
      </FormGroup>
    </FormControl>
  </>
);

function GeneralSettings() {
  // Separate states for each sales category selection
  const [selectedCategoriesToday, setSelectedCategoriesToday] = useState({
    WALKIN: false,
    WATU_SIMU: false,
    ONFONE: false,
    LMM: false,
    WABEH: false,
  });

  const [selectedCategoriesWeek, setSelectedCategoriesWeek] = useState({
    WALKIN: false,
    WATU_SIMU: false,
    ONFONE: false,
    LMM: false,
    WABEH: false,
  });

  const [selectedCategoriesMonth, setSelectedCategoriesMonth] = useState({
    WALKIN: false,
    WATU_SIMU: false,
    ONFONE: false,
    LMM: false,
    WABEH: false,
  });

  const [selectedCategoriesProfit, setSelectedCategoriesProfit] = useState({
    WALKIN: false,
    WATU_SIMU: false,
    ONFONE: false,
    LMM: false,
    WABEH: false,
  });

  // Sidebar Permissions
  const [selectedSidebarItems, setSelectedSidebarItems] = useState({
    dashboard: false,
    sales: false,
    operations: false,
    shop: false,
    reports: false,
    systemSettings: false,
  });

  // Handlers for sales categories selection
  const handleCategoryChange = (setCategories) => (event) => {
    setCategories((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };

  // Handler for sidebar items selection
  const handleSidebarChange = (event) => {
    setSelectedSidebarItems({
      ...selectedSidebarItems,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ p: 4, backgroundColor: "#F0F4F7", borderRadius: 2 }}>
      {/* Sales Category Selection for Today's Sales */}
      <SalesCategorySelection
        title="Select Sales Categories for Today's Sales"
        selectedCategories={selectedCategoriesToday}
        handleCategoryChange={handleCategoryChange(setSelectedCategoriesToday)}
      />

      {/* Sales Category Selection for Weekly Sales */}
      <SalesCategorySelection
        title="Select Sales Categories for This Week's Sales"
        selectedCategories={selectedCategoriesWeek}
        handleCategoryChange={handleCategoryChange(setSelectedCategoriesWeek)}
      />

      {/* Sales Category Selection for Monthly Sales */}
      <SalesCategorySelection
        title="Select Sales Categories for This Month's Sales"
        selectedCategories={selectedCategoriesMonth}
        handleCategoryChange={handleCategoryChange(setSelectedCategoriesMonth)}
      />

      {/* Sales Category Selection for Monthly Profits */}
      <SalesCategorySelection
        title="Select Sales Categories for This Month's Profit"
        selectedCategories={selectedCategoriesProfit}
        handleCategoryChange={handleCategoryChange(setSelectedCategoriesProfit)}
      />

      {/* Sidebar Permissions */}
      <Typography variant="h6" sx={{ mb: 2, color: "#0A7E07" }}>Customize User Sidebar Permissions</Typography>
      <FormControl component="fieldset" sx={{ mb: 4 }}>
        <FormGroup row>
          {Object.keys(selectedSidebarItems).map((item) => (
            <FormControlLabel
              key={item}
              control={
                <Checkbox
                  checked={selectedSidebarItems[item]}
                  onChange={handleSidebarChange}
                  name={item}
                  sx={{ color: "#0A7E07", '&.Mui-checked': { color: "#0A7E07" } }}
                />
              }
              label={item.charAt(0).toUpperCase() + item.slice(1)}
            />
          ))}
        </FormGroup>
      </FormControl>

      {/* Save Button */}
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#0A7E07",
          color: "#fff",
          '&:hover': { backgroundColor: "#056705" },
        }}
      >
        Save Changes
      </Button>
    </Box>
  );
}

export default GeneralSettings;
