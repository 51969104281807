// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Badge,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth, realtimedb } from '../firebase';
import logo from '../assets/logo1-removebg-preview.png';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationService from '../NotificationService';
import getUserRole from '../getUserRole';
import { equalTo, onValue, orderByChild, query, ref } from 'firebase/database';
import { getUserShop } from './dataService';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#4CAF50',
  zIndex: theme.zIndex.drawer + 1,
  height: '64px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
}));

const Navbar = () => {
  const [openNotificationsDialog, setOpenNotificationsDialog] = useState(false); // State for notifications dialog
  const [notifications, setNotifications] = useState([]); // Notifications fetched from DB
  const [notificationLoading, setNotificationLoading] = useState(true); // Loading state for notifications
  const [error, setError] = useState(''); // Error state for notifications
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(''); // User's role

 
  useEffect(() => {
    const fetchUserRoleAndNotifications = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error('No authenticated user found.');
          setError('No authenticated user found.');
          setNotificationLoading(false);
          return;
        }
  
        // Get the single role of the user
        const role = await getUserRole(currentUser.email);
        if (!role) {
          console.error('User role not found:', role);
          setError('User role not found.');
          setNotificationLoading(false);
          return;
        }
  
        setUserRole(role);  // Store the role
  
        // Only fetch shopAssigned if the user is a shopmanager
        let shopAssigned = null;
        if (role === 'shopmanager') {
          shopAssigned = await getUserShop(currentUser.email);
        }
        console.log('Role:', role, 'Shop Assigned:', shopAssigned);
  
        // Reference to notifications in Realtime Database
        const notificationsRef = ref(realtimedb, 'notifications');
  
        // Query for 'all' unread notifications
        const allNotificationsQuery = query(
          notificationsRef,
          orderByChild('receiverId'),
          equalTo('all')
        );
  
        // Role-specific unread notifications
        const roleNotificationsQuery = query(
          notificationsRef,
          orderByChild('receiverId'),
          equalTo(role)
        );
  
        // Prepare an array of queries
        const queries = [allNotificationsQuery, roleNotificationsQuery];
  
        // Add shop-specific unread notifications if the user is a shopmanager
        if (role === 'shopmanager' && shopAssigned) {
          const shopNotificationsQuery = query(
            notificationsRef,
            orderByChild('receiverId'),
            equalTo(shopAssigned)
          );
          queries.push(shopNotificationsQuery);
        }
  
        // Fetch unread notifications (assume unread notifications have `isRead: false`)
        const allNotifs = [];
        const unsubscribeFunctions = [];
  
        // Listen to each query (for 'all', role, and shop notifications)
        queries.forEach((query, index) => {
          console.log(`Setting up listener for query ${index}:`, query);
          const unsubscribe = onValue(query, (snapshot) => {
            const queryNotifs = [];
            snapshot.forEach((childSnapshot) => {
              const notification = { id: childSnapshot.key, ...childSnapshot.val() };
              
              // Filter unread notifications and push to the array
              if (notification.isUnread) {
                queryNotifs.push(notification);
              }
            });
            console.log(`Fetched notifications for query ${index}:`, queryNotifs);
            allNotifs.push(...queryNotifs);
  
            // Sort notifications by timestamp (assuming `timestamp` is available)
            const sortedNotifs = allNotifs.sort((a, b) => b.timestamp - a.timestamp);
            setNotifications(sortedNotifs);
            setNotificationLoading(false);
          }, (error) => {
            console.error(`Error fetching notifications for query ${index}:`, error);
            setError(`Failed to load notifications for query ${index}.`);
            setNotificationLoading(false);
          });
  
          unsubscribeFunctions.push(unsubscribe);
        });
  
        // Clean up the listeners when the component unmounts
        return () => {
          unsubscribeFunctions.forEach(unsub => unsub());
        };
  
      } catch (err) {
        console.error('Error fetching user role or notifications:', err);
        setError('Failed to load notifications due to an error.');
        setNotificationLoading(false);
      }
    };
  
    fetchUserRoleAndNotifications();
  }, []);  // Add any dependencies if necessary
  

  const handleOpenNotificationsDialog = () => {
    setOpenNotificationsDialog(true);
  };

  const handleCloseNotificationsDialog = () => {
    setOpenNotificationsDialog(false);
  };

  // Mark a notification as read and remove it from the list after clicking
  const handleNotificationClick = async (id) => {
    try {
      await NotificationService.markAsRead(id);
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === id ? { ...notification, isUnread: false } : notification
        )
      );
    } catch (error) {
      console.error('Error marking notification as read: ', error);
    }
  };

  // Remove a read notification from the list
  const handleNotificationRemove = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  // Count unread notifications
  const notificationCount = notifications.filter(notification => notification.isUnread).length;

  return (
    <>
      <StyledAppBar position="fixed">
        <Toolbar style={{ minHeight: '64px', display: 'flex', justifyContent: 'space-between' }}>
          <Box display="flex" alignItems="center">
            <img
              src={logo}
              alt="Oseo POS Logo"
              style={{ height: '50px', width: 'auto', marginRight: '16px' }}
            />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              COWTRACK LTD
            </Typography>
          </Box>

          <Box>
            {/* Notifications Button */}
            <IconButton color="inherit" onClick={handleOpenNotificationsDialog}>
              <Badge badgeContent={notificationCount} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            {/* Logout Button */}
            <Button
              color="inherit"
              onClick={handleLogout}
              style={{ fontSize: '0.875rem', marginLeft: '16px' }}
            >
              Log Out
            </Button>
          </Box>
        </Toolbar>
      </StyledAppBar>

      {/* Notifications Dialog */}
      <Dialog open={openNotificationsDialog} onClose={handleCloseNotificationsDialog} fullWidth maxWidth="sm">
        <DialogTitle>Notifications</DialogTitle>
        <DialogContent dividers>
          {notificationLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          ) : notifications.length === 0 ? (
            <Typography variant="body1">No notifications available.</Typography>
          ) : (
            <List>
              {notifications.map((notification, index) => (
                <div key={notification.id}>
                  <ListItem
                    button
                    onClick={() => {
                      handleNotificationClick(notification.id);
                      handleNotificationRemove(notification.id);
                    }}
                  >
                    <ListItemText
                      primary={notification.message}
                      secondary={new Date(notification.timestamp).toLocaleString()}
                      primaryTypographyProps={{
                        fontWeight: notification.isUnread ? 'bold' : 'normal',
                      }}
                    />
                  </ListItem>
                  {index < notifications.length - 1 && <Divider />} {/* Divider between notifications */}
                </div>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotificationsDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Navbar;
