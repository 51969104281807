import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
  Paper, Button
} from '@mui/material';

const TransactionsComponent = () => {
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const [openMPesaDialog, setOpenMPesaDialog] = useState(false);

  useEffect(() => {
    const fetchPendingTransactions = () => {
      const db = getDatabase();
      const dbRef = ref(db, 'transactions');

      onValue(dbRef, (snapshot) => {
        const transactions = snapshot.val();
        if (transactions) {
          const pending = Object.keys(transactions)
            .map(key => ({ id: key, ...transactions[key] }))
            .filter(transaction => transaction.resultCode === 0 && transaction.shopname === "mathare");
          setPendingTransactions(pending);
        }
      });
    };

    fetchPendingTransactions();
  }, []);

  const handleMPesaTransactionSelect = (transaction) => {
    // Handle the selected transaction

    console.log('Selected transaction:', transaction);
    setOpenMPesaDialog(false); // Close dialog after selecting transaction
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpenMPesaDialog(true)}>
        Open MPESA Transactions
      </Button>
      <Dialog 
        open={openMPesaDialog} 
        onClose={() => setOpenMPesaDialog(false)} 
        maxWidth="md" 
        fullWidth
      >
        <DialogTitle>Select MPESA Transaction</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Transaction Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingTransactions.map((transaction) => (
                  <TableRow key={transaction.id}>
                    <TableCell>{transaction.id}</TableCell>
                    <TableCell>{transaction.phoneNumber}</TableCell>
                    <TableCell>{transaction.amount}</TableCell>
                    <TableCell>{transaction.transactionDate}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleMPesaTransactionSelect(transaction)}
                      >
                        Confirm
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMPesaDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TransactionsComponent;
