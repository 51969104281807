
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
} from '@mui/material';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { getUserShop, getSalesPeopleSales, getSalesData, getTopSellingProducts,populateSalesOverview, getUserRole } from './dataService';
import { styled } from '@mui/material/styles';
import './userdashboard.css';
import { endOfMonth, startOfMonth } from 'date-fns';
import { toast } from 'react-toastify';

// Safaricom Theme Colors
const safaricomGreen = '#00a859';
const safaricomWhite = '#ffffff';
const safaricomBlack = '#000000';

// Custom styles for the dashboard components
const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: safaricomWhite,
  padding: theme.spacing(2),
  textAlign: 'center',
  borderRadius: '12px',
  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '500',
  color: safaricomBlack,
}));

function Dashboard() {
  const [salesData, setSalesData] = useState({
    today: 0,
    thisWeek: 0,
    thisMonth: 0,
    thisMonthProfit: 0,
  });
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [loading, setLoading] = useState(true);
  const [shop, setShop] = useState('');
  const [personnelData, setPersonnelData] = useState([]);
  const [salesOverview, setSalesOverview] = useState({
    Walkin: 0,
    'Watu Simu': 0,
    Onfon: 0,
    LMM: 0,
    Wabeh: 0,
  });

 
  useEffect(() => {
    const fetchData = async (email) => {
      try {
        // Get user role, shop, sales, and top products data
        const role = await getUserRole(email);
        setUserRole(role);

        const [shop, sales, topProducts] = await Promise.all([
          getUserShop(),
          getSalesData(),
          getTopSellingProducts(),
        ]);

        // Set state with the fetched data
        setShop(shop);
        setSalesData(sales);
        setTopSellingProducts(topProducts);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchSalePeopleData = async () => {
      try {
        const salesData = await getSalesPeopleSales();
        setPersonnelData(salesData);
      } catch (error) {
        console.error('Error fetching personnel sales data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSalePeopleData();

    const calculateSalesOverview = async () => {
      try {
        const salesOverviewData = await populateSalesOverview();

        // Initialize the new sales overview state
        const newSalesOverview = {
          Walkin: salesOverviewData.walkinSales || 0,
         'Watu Simu': salesOverviewData.watuSimuSales || 0,
          Onfon: salesOverviewData.onphoneSales || 0,
          LMM:  salesOverviewData.lnmSales || 0,
          Wabeh: salesOverviewData.wabehSales || 0,
        };
       
      
        // Update the sales overview state immutably
        setSalesOverview(newSalesOverview);
      } catch (error) {
        toast.error('Error calculating sales overview:', error);
      }
    };
    calculateSalesOverview();
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
         // Calculate sales overview
        fetchData(user.email);     // Fetch user-specific data
      } else {
        setLoading(false);
        console.error('No user is signed in');
      }
    });
  }, []);  // Empty dependency array ensures this runs once on component mount


 

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress color="primary" />
      </Box>
    );
  }


  const salesCards = [
    {
      key: 'today',
      label: "Today's Sales",
      icon: <ShoppingCartOutlinedIcon style={{ color: safaricomGreen, fontSize: '2rem' }} />,
      value: salesData.today,
    },
    {
      key: 'thisWeek',
      label: "This Week's Sales",
      icon: <StorefrontOutlinedIcon style={{ color: safaricomGreen, fontSize: '2rem' }} />,
      value: salesData.thisWeek,
    },
    {
      key: 'thisMonth',
      label: "This Month's Sales",
      icon: <PointOfSaleOutlinedIcon style={{ color: safaricomGreen, fontSize: '2rem' }} />,
      value: salesData.thisMonth,
    },
    {
      key: 'profit',
      label: "This Month's Profit",
      icon: <MonetizationOnOutlinedIcon style={{ color: safaricomGreen, fontSize: '2rem' }} />,
      value: salesData.thisMonthProfit,
    },
  ];

  return (
    <div className="dashboard-container">
      <Box
        className="header"
        sx={{
          mb: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: safaricomGreen,
          color: safaricomWhite,
          padding: '16px',
          borderRadius: '8px',
        }}
      >
        <div className="shop-info" style={{ display: 'flex', alignItems: 'center' }}>
          <LocalMallOutlinedIcon style={{ fontSize: '3rem' }} />
          <Typography variant="h5" sx={{ ml: 1 }}>
            {shop.toUpperCase()} SHOP
          </Typography>
        </div>
        <Typography variant="subtitle1" sx={{ fontStyle: 'italic' }}>
          "Quality products, exceptional service!"
        </Typography>
      
      </Box>

      <Grid container spacing={3}>
        {salesCards.map(({ key, label, icon, value }, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StyledPaper>
              <div className="icon-wrapper">{icon}</div>
              <StyledTypography variant="h6">{label}</StyledTypography>
              <StyledTypography variant="h6">{value} ksh</StyledTypography>
            </StyledPaper>
          </Grid>
        ))}
      </Grid>

      {/* Align Sales Overview and Top Selling Products horizontally */}
      <Grid container spacing={3} sx={{ mt: 5 }}>
        {/* Sales Overview by Customer Category */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" sx={{ mb: 2, color: safaricomBlack }}>
              Sales Overview by Customer Category
            </Typography>
            <TableContainer component={Paper}>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Category</TableCell>
        <TableCell align="right">Sales (Ksh)</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {Object.entries(salesOverview).map(([category, sales], index) => (
        <TableRow key={index}>
          <TableCell>{category}</TableCell>
          <TableCell align="right">{sales || 0} Ksh</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>

          </StyledPaper>
        </Grid>

        {/* Top Selling Products Table */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" sx={{ mb: 2, color: safaricomBlack }}>
              Top Selling Products
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell align="right">Sales (Ksh)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topSellingProducts.map((product, index) => (
                    <TableRow key={index}>
                      <TableCell>{product.name.toUpperCase()}</TableCell>
                      <TableCell align="right">{product.sold || 0} ksh</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </Grid>
      </Grid>

      {/* Personnel Report */}
     {/* <Grid container spacing={3} sx={{ mt: 5 }}>
        <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h6" sx={{ mb: 2, color: safaricomBlack }}>
              Personnel Report
            </Typography>
            <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Personnel</TableCell>
            <TableCell align="start" >Role</TableCell>
            <TableCell align="start">Sales (Ksh)</TableCell>
            <TableCell align="right">Profit (Ksh)</TableCell>
            <TableCell align="right">Commission (Ksh)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {personnelData.map((person, index) => (
            <TableRow key={index}>
              <TableCell>{person.name.toUpperCase()}</TableCell>
              <TableCell align="start">{person.role.toUpperCase()}</TableCell>
              <TableCell align="start">{person.totalSales || 0} ksh</TableCell> 
              <TableCell align="right">{person.totalProfit || 0} ksh</TableCell> 
              <TableCell align="right">{person.commission || 0} ksh</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

          </StyledPaper>
        </Grid>
      </Grid>*/}
    </div>
  );
}

export default Dashboard;