import { useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';

const useSessionTimeout = (timeoutDuration) => {
  useEffect(() => {
    const auth = getAuth(); // Get the auth instance
    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        try {
          await signOut(auth); // Sign out the user
          window.location.href = '/'; // Redirect to login page after sign-out
        } catch (error) {
          console.error('Sign out error:', error.message);
          window.location.href = '/'; // Redirect to login page even if sign-out fails
        }
      }, timeoutDuration);
    };

    const handleActivity = () => {
      resetTimer(); // Reset timer on user activity
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleActivity); // Adding scroll as another activity
    window.addEventListener('click', handleActivity); // Adding click as another activity

    // Initialize the timer when the component mounts
    resetTimer();

    return () => {
      clearTimeout(timer);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      window.removeEventListener('click', handleActivity);
    };
  }, [timeoutDuration]);
};

export default useSessionTimeout;
