import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
} from '@mui/material';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path according to your file structure
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddShopPage() {
  const [shopName, setShopName] = useState('');
  const [address, setAddress] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const handleAddShop = async (e) => {
    e.preventDefault();

    const shopNameLowerCase = shopName.toLowerCase();
    
    const shopQuery = query(collection(db, 'shoplist'), where('shopName', '==', shopNameLowerCase));
    const shopSnapshot = await getDocs(shopQuery);
    if (!shopSnapshot.empty) {
      toast.error('Shop name already exists. Please choose a different name.');
      return;
    }

    const newShop = {
      shopName: shopNameLowerCase,
      address,
      contactNumber,
    };

    try {
      await addDoc(collection(db, 'shoplist'), newShop);
      setShopName('');
      setAddress('');
      setContactNumber('');
      toast.success('Shop added successfully!');
    } catch (error) {
      console.error('Error adding shop: ', error);
      toast.error('Failed to add shop.');
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: '32px', backgroundColor: '#f0f8ff', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom align="center" style={{ color: '#2e7d32' }}>
        Add Shop
      </Typography>
      <form onSubmit={handleAddShop}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Shop Name"
              variant="outlined"
              value={shopName}
              onChange={(e) => setShopName(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address"
              variant="outlined"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Contact Number"
              variant="outlined"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              fullWidth
              required
              margin="normal"
              type="tel"
            />
          </Grid>
        </Grid>

        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Button type="submit" variant="contained" color="primary">
            Add Shop
          </Button>
        </Box>
      </form>
      <ToastContainer />
    </Box>
  );
}

export default AddShopPage;
