import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, CircularProgress, Select, MenuItem, FormControl, InputLabel, TextField, Button } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { collection, getDocs, query, where, Timestamp } from 'firebase/firestore';
import { db, auth } from '../firebase';  // Adjust import path based on your file structure
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const ProfitReportPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [profitData, setProfitData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [employeeFilter, setEmployeeFilter] = useState('');
  const [employees, setEmployees] = useState([]);
  const [analyticsData, setAnalyticsData] = useState({
    totalProfit: 0,
    profitByCategory: {},
    profitTrends: {
      labels: [],
      data: []
    }
  });

  useEffect(() => {
    const fetchEmployees = async () => {
      const employeeSnapshot = await getDocs(collection(db, 'employees'));
      setEmployees(employeeSnapshot.docs.map(doc => doc.data().name));
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const salesCollection = collection(db, 'sales');
      const creditSalesCollection = collection(db, 'credit_sales');

      const salesQuery = query(salesCollection);
      const creditSalesQuery = query(creditSalesCollection);

      const [salesSnapshot, creditSalesSnapshot] = await Promise.all([
        getDocs(salesQuery),
        getDocs(creditSalesQuery)
      ]);
      const salesData = salesSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      const creditSalesData = creditSalesSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

      const combinedSalesData = [...salesData, ...creditSalesData];

      const products = combinedSalesData.flatMap(sale =>
        sale.products.map(product => ({
          ...product,
          saleId: sale.id,
          category: sale.category,
          salesperson: sale.salesperson,
          price: sale.total,
          cost: sale.buyingprice,
          quantity: product.quantity,
          saleTimestamp:   sale.saleTimestamp , // Assuming timestamp is in seconds
         
        }))
      );

      setAllData(products);
      setLoading(false);
      filterData(products, tabValue);
    };

    fetchData();
  }, []);

  const filterData = (data, tabValue) => {
    let startDate, endDate;
    const today = new Date();

    if (tabValue === 0) { // Daily
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
    } else if (tabValue === 1) { // Weekly
      const day = today.getDay();
      const mondayOffset = (day === 0 ? -6 : 1) - day;
      startDate = new Date(today);
      startDate.setDate(today.getDate() + mondayOffset);
      startDate.setHours(0, 0, 0, 0);

      // Calculate the end of the week (Sunday)
      endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);
      endDate.setHours(23, 59, 59, 999);
    } else if (tabValue === 2) { // Monthly
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    }
   
    const filteredData = data.filter(sale =>
      sale.saleTimestamp >= Timestamp.fromDate(startDate) && sale.saleTimestamp <= Timestamp.fromDate(endDate),
    );

    setProfitData(filteredData);
    updateAnalytics(filteredData);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    filterData(allData, newValue);
  };

  const updateAnalytics = (data) => {
    const totalProfit = data.reduce((total, sale) => total + (sale.price - sale.buyingprice) * sale.quantity, 0);

    const profitByCategory = data.reduce((acc, sale) => {
      acc[sale.category] = (acc[sale.category] || 0) + (sale.price - sale.buyingprice) * sale.quantity;
      return acc;
    }, {});

    const profitTrends = data.reduce((acc, sale) => {
      const month = sale.saleTimestamp.toDate().getMonth() + 1;
      const year = sale.saleTimestamp.toDate().getFullYear();
      const key = `${year}-${month}`;
      acc[key] = (acc[key] || 0) + (sale.price - sale.buyingprice) * sale.quantity;
      return acc;
    }, {});

    const labels = Object.keys(profitTrends);
    const trendData = Object.values(profitTrends);

    setAnalyticsData({
      totalProfit,
      profitByCategory,
      profitTrends: {
        labels,
        data: trendData
      }
    });
  };

  const filteredProfitData = profitData.filter(sale => {
    const searchTermMatch = Object.values(sale).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
    const employeeMatch = employeeFilter ? sale.salesperson === employeeFilter : true;

    return searchTermMatch && employeeMatch;
  });

  const totalProfit = filteredProfitData.reduce((total, sale) => total + (sale.price - sale.buyingprice) * sale.quantity, 0);

  const columns = [
    {
      name: "saleTimestamp",
      label: "DateTime",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const date = new Date(value * 1000); // Assuming timestamp is in seconds
          date.setYear(2024);
          return date.toLocaleString(); // Convert to locale string
        },
        customFilterListOptions: {
          render: (value) => {
            return `Date: ${new Date(value * 1000).toLocaleString()}`;
          },
        },
      },
    },
    { name: 'name', label: 'Item Name' },
    { name: 'salesperson', label: 'Salesperson' },
    { name: 'price', label: 'Price' },
    { name: 'buyingprice', label: 'Cost' },
    { name: 'quantity', label: 'Quantity' },
    { name: 'serial', label: 'Serial No.' },
    {
      name: 'total',
      label: 'Profit',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const sale = filteredProfitData[tableMeta.rowIndex];
          return `Ksh${(sale.price - sale.buyingprice) * sale.quantity}`;
        }
      }
    }
  ];

  return (
    <Box>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Daily" />
        <Tab label="Weekly" />
        <Tab label="Monthly" />
      </Tabs>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <FormControl variant="outlined" size="small">
          <InputLabel>Salesperson</InputLabel>
          <Select
            value={employeeFilter}
            onChange={(e) => setEmployeeFilter(e.target.value)}
            label="Salesperson"
          >
            <MenuItem value=""><em>All</em></MenuItem>
            {employees.map((employee, index) => (
              <MenuItem key={index} value={employee}>{employee}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>
      {loading ? <CircularProgress /> : (
        <>
          <MUIDataTable
            title={"Profit Report"}
            data={filteredProfitData}
            columns={columns}
            options={{
              filterType: 'checkbox',
              selectableRows: 'none'
            }}
          />
          <Box mt={2}>
            <h6>Total Profit: Ksh {totalProfit}</h6>
            <Box>
              <h6>Profit by Category</h6>
              <Doughnut data={{
                
                labels: Object.keys(analyticsData.profitByCategory),
                datasets: [{
                  data: Object.values(analyticsData.profitByCategory),
                  backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#4BC0C0',
                    '#9966FF',
                    '#FF9F40'
                  ]
                }]
              }} />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ProfitReportPage;
