import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import GeneralSettings from "./GeneralSettings";
import InventorySettings from "./InventorySettings";
import POSSettings from "./POSSettings";
import UserManagement from "./UserManagement";
import TillSettings from "./TillSettings";
import TransactionsComponent from "./Transactions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function SystemSettingsPage() {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="settings tabs"
      >
        <Tab label="DASHBOARD" />
        <Tab label="Inventory" />
        <Tab label="POS" />
        <Tab label="User Management" />
        <Tab label="Till Management" />
        <Tab label="Transactions" />
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <GeneralSettings />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <InventorySettings />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <POSSettings />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <UserManagement />
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        <TillSettings />
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        <TransactionsComponent />
      </TabPanel>
    </Box>
  );
}

export default SystemSettingsPage;
