import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import UploadIcon from "@mui/icons-material/Upload";
import Divider from "@mui/material/Divider";

function UserManagement() {
  // State to hold uploaded document names
  const [passport, setPassport] = useState("");
  const [idDocument, setIdDocument] = useState("");
  const [kra, setKra] = useState("");
  const [goodConduct, setGoodConduct] = useState("");

  const handleFileChange = (e, setDocument) => {
    const file = e.target.files[0];
    if (file) {
      setDocument(file.name);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        p: 3,
        borderRadius: 2,
        backgroundColor: "#f9f9f9",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        maxWidth: 600,
        margin: "0 auto",
      }}
    >
      {/* User Details Section */}
      <TextField
        fullWidth
        label="Name"
        variant="outlined"
        margin="normal"
        sx={{ bgcolor: "white" }}
      />

      <TextField
        fullWidth
        label="ID Number"
        variant="outlined"
        margin="normal"
        sx={{ bgcolor: "white" }}
      />

      <TextField
        fullWidth
        label="Email"
        variant="outlined"
        margin="normal"
        type="email"
        sx={{ bgcolor: "white" }}
      />

      <TextField
        fullWidth
        label="Phone Number"
        variant="outlined"
        margin="normal"
        sx={{ bgcolor: "white" }}
      />

      <TextField
        fullWidth
        label="Role"
        variant="outlined"
        margin="normal"
        sx={{ bgcolor: "white" }}
      />

      {/* Document Uploads Section */}
      <Divider sx={{ my: 3 }}>Documents</Divider>

      <TextField
        fullWidth
        label="Passport"
        variant="outlined"
        margin="normal"
        value={passport}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="contained"
                component="label"
                color="success"
                sx={{
                  bgcolor: "#00b341",
                  "&:hover": { bgcolor: "#008b2d" },
                }}
              >
                Upload
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleFileChange(e, setPassport)}
                />
              </Button>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        fullWidth
        label="ID Document"
        variant="outlined"
        margin="normal"
        value={idDocument}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="contained"
                component="label"
                color="success"
                sx={{
                  bgcolor: "#00b341",
                  "&:hover": { bgcolor: "#008b2d" },
                }}
              >
                Upload
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleFileChange(e, setIdDocument)}
                />
              </Button>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        fullWidth
        label="KRA"
        variant="outlined"
        margin="normal"
        value={kra}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="contained"
                component="label"
                color="success"
                sx={{
                  bgcolor: "#00b341",
                  "&:hover": { bgcolor: "#008b2d" },
                }}
              >
                Upload
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleFileChange(e, setKra)}
                />
              </Button>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        fullWidth
        label="Good Conduct"
        variant="outlined"
        margin="normal"
        value={goodConduct}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="contained"
                component="label"
                color="success"
                sx={{
                  bgcolor: "#00b341",
                  "&:hover": { bgcolor: "#008b2d" },
                }}
              >
                Upload
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleFileChange(e, setGoodConduct)}
                />
              </Button>
            </InputAdornment>
          ),
        }}
      />

      {/* Change Password Section */}
      <Divider sx={{ my: 3 }}>Change Password</Divider>

      <TextField
        fullWidth
        label="Old Password"
        variant="outlined"
        margin="normal"
        type="password"
        sx={{ bgcolor: "white" }}
      />

      <TextField
        fullWidth
        label="New Password"
        variant="outlined"
        margin="normal"
        type="password"
        sx={{ bgcolor: "white" }}
      />

      <TextField
        fullWidth
        label="Confirm New Password"
        variant="outlined"
        margin="normal"
        type="password"
        sx={{ bgcolor: "white" }}
      />

      {/* Save Changes Button */}
      <Button
        variant="contained"
        color="success"
        sx={{
          mt: 3,
          bgcolor: "#00b341", // Safaricom green
          "&:hover": {
            bgcolor: "#008b2d",
          },
        }}
        fullWidth
      >
        Save Changes
      </Button>
    </Box>
  );
}

export default UserManagement;
