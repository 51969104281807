import React, { useEffect, useState } from 'react';
import { Container, Row,Card, Col } from 'react-bootstrap';

import BarChart from './BarChart';
import ShopCard from './ShopCard';
import SalesTable from './SalesTable';
import DashboardDataService from './DashboardDataService';
import './dashboard.css';



const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [salesOverview, setSalesOverview] = useState([]);
  const [salesOverviewDatas, setSalesOverviewData] = useState([]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      const data = await DashboardDataService.populateDashboard();
      setDashboardData(data);
      
    };
   
    const fetchDashboardDatas = async () => {
      try {
        const data = await DashboardDataService.populateDashboard();
        
        // Transform data into the format needed for BarChart
        const formattedData = data.map((shopData) => ({
          shopName: shopData.name,
          week1Sales: shopData.weeklySales.week1Sales,
          week2Sales: shopData.weeklySales.week2Sales,
          week3Sales: shopData.weeklySales.week3Sales,
          week4Sales: shopData.weeklySales.week4Sales,
        }));

        setSalesOverviewData(formattedData);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardDatas();
    

    const fetchSalesOverview = async () => {
      const data = await DashboardDataService.populateSalesOverview();
      setSalesOverview(data);
      setSalesOverviewData(data);
      
    };

    fetchDashboardData();
    fetchDashboardDatas();
    fetchSalesOverview();
  }, []);

 

  return (
    <Container className="dashboard-container">
      <Row className="dashboard-header">
        <Col>
          <h1>COWTRACK LTD</h1>
          <p className="lead">"Quality products, exceptional service!"</p>
        </Col>
      </Row>
      <Row>
        {dashboardData.map((shop, index) => (
          <Col key={index} xs={12} md={6} lg={3} className="mb-4">
            <ShopCard shop={shop} />
          </Col>
        ))}
      </Row>
      <Row className="mt-4">
        <Col>
          <h2>Credits Overview</h2>
          <SalesTable salesData={salesOverview} className="sales-table" />
        </Col>
      </Row>
      <Row className="mt-4">
      <Col md={12} lg={10} className="mb-4"justify-content="center" style={{ margin: '30px' }}>
      <Card className="p-3 shadow-sm">
        <Card.Body>
          <Card.Title as="h5" className="mb-3">Sales Overview Bar Chart</Card.Title>
          {salesOverviewDatas && salesOverviewDatas.length > 0 ? (
            <BarChart salesOverview={salesOverviewDatas} className="weekly-sales" />
          ) : (
            <p>No sales data available.</p>
          )}
        </Card.Body>
      </Card>
    </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
