import React, { useState } from 'react';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const MonthlyReportsPage = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const productsSold = [
    { date: '2024-07-01', category: 'Category1', name: 'Product1', code: '001', quantity: 10, price: 100 },
    { date: '2024-07-03', category: 'Category2', name: 'Product2', code: '002', quantity: 5, price: 50 },
    { date: '2024-07-05', category: 'Category3', name: 'Product3', code: '003', quantity: 20, price: 200 },
  ];

  const filteredProducts = productsSold.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    product.date >= startDate && product.date <= endDate
  );

  const totalSales = filteredProducts.reduce((total, product) => total + (product.price * product.quantity), 0);

  const printReport = () => {
    window.print();
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text("Monthly Sales", 10, 10);
    let yPosition = 20;
    filteredProducts.forEach((product, index) => {
      doc.text(`${index + 1}. ${product.date} - ${product.category} - ${product.name} - ${product.code} - ${product.quantity} - $${product.price}`, 10, yPosition);
      yPosition += 10;
    });
    doc.text(`Total Sales: $${totalSales}`, 10, yPosition + 10);
    doc.save("monthly_report.pdf");
  };

  const exportExcel = () => {
    const data = [
      ["Date", "Category", "Name", "Serial Number", "Quantity", "Price"],
      ...filteredProducts.map(product => [product.date, product.category, product.name, product.code, product.quantity, product.price]),
      ["", "", "", "", "Total Sales", totalSales]
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Monthly Sales");
    XLSX.writeFile(workbook, "monthly_report.xlsx");
  };

  return (
    <div className="container">
      <header className="d-flex justify-content-between align-items-center my-3">
        <h1>Monthly Sales</h1>
        <div>
          <button className="btn btn-primary mx-1" onClick={printReport}>
            <i className="fas fa-print"></i> Print
          </button>
          <button className="btn btn-danger mx-1" onClick={exportPDF}>
            <i className="fas fa-file-pdf"></i> Export as PDF
          </button>
          <button className="btn btn-success mx-1" onClick={exportExcel}>
            <i className="fas fa-file-excel"></i> Export as Excel
          </button>
        </div>
      </header>
      <div className="mb-3 d-flex">
        <input
          type="date"
          className="form-control me-2"
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
        />
        <input
          type="date"
          className="form-control me-2"
          value={endDate}
          onChange={e => setEndDate(e.target.value)}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Search products..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>
      <main>
        <section id="reportSection">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Category</th>
                <th>Name</th>
                <th>Serial Number</th>
                <th>Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((product, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{product.date}</td>
                  <td>{product.category}</td>
                  <td>{product.name}</td>
                  <td>{product.code}</td>
                  <td>{product.quantity}</td>
                  <td>{product.price}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="6" className="text-end"><strong>Total Sales:</strong></td>
                <td><strong>{totalSales}</strong></td>
              </tr>
            </tfoot>
          </table>
        </section>
      </main>
      <footer className="text-center mt-4">
        <p>&copy; 2024 Monthly Reports</p>
      </footer>
    </div>
  );
};

export default MonthlyReportsPage;


