import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  LinearProgress,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { toast } from "react-toastify";
import { AddCircleOutline, CheckCircle } from '@mui/icons-material';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path according to your file structure

// Create a theme with green as the primary color
const theme = createTheme({
  palette: {
    primary: {
      main: '#388E3C', // Green color
    },
  },
});

function CreateProductPage() {
  const [category, setCategory] = useState('');
  const [name, setName] = useState('');
  const [storageCapacity, setStorageCapacity] = useState('');
  const [buyingPrice, setBuyingPrice] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');
  const [discountRange, setDiscountRange] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      const categoryCollection = collection(db, 'categories');
      const categorySnapshot = await getDocs(categoryCollection);
      const categoryList = categorySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoryList);
    };

    fetchCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Convert all fields to lowercase
    const newProduct = {
      category: category.toLowerCase(),
      name: name.toLowerCase(),
      storage: storageCapacity.toLowerCase(),
      buyingPrice: buyingPrice.toLowerCase(),
      sellingPrice: sellingPrice.toLowerCase(),
      discountRange: discountRange.toLowerCase(),
    };

    // Validate selling price is greater than buying price
    if (parseFloat(newProduct.sellingPrice) <= parseFloat(newProduct.buyingPrice)) {
      toast.error('Selling price must be greater than buying price.');
      setLoading(false);
      return;
    }

    // Check for duplicate product name
    const productQuery = query(collection(db, 'products'), where('name', '==', newProduct.name));
    const productSnapshot = await getDocs(productQuery);
    if (!productSnapshot.empty) {
      toast.error('Product with this name already exists.');
      setLoading(false);
      return;
    }

    saveProduct(newProduct);
  };

  const saveProduct = async (product) => {
    try {
      await addDoc(collection(db, 'products'), product);
      toast.success('Product created successfully!');
      resetForm();
    } catch (error) {
      console.error('Error adding product: ', error);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setCategory('');
    setName('');
    setStorageCapacity('');
    setBuyingPrice('');
    setSellingPrice('');
    setDiscountRange('');
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh', backgroundColor: '#f7f7f7' }}>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} style={{ padding: '32px', borderRadius: '12px' }}>
            <Typography variant="h4" gutterBottom align="center" style={{ color: '#388E3C' }}>
              Create Product
            </Typography>
            {successMessage && (
              <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                <CheckCircle style={{ color: '#388E3C', marginRight: '8px' }} />
                <Typography variant="h6" style={{ color: '#388E3C' }}>
                  {successMessage}
                </Typography>
              </Box>
            )}
            <form onSubmit={handleSubmit}>
              <Box marginBottom={2}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    label="Category"
                    required
                  >
                    {categories.map((cat) => (
                      <MenuItem key={cat.id} value={cat.name.toLowerCase()}>
                        {cat.name.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  required
                  margin="normal"
                />
                <TextField
                  label="Storage (e.g., 64GB)"
                  variant="outlined"
                  value={storageCapacity}
                  onChange={(e) => setStorageCapacity(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Buying Price"
                  variant="outlined"
                  value={buyingPrice}
                  onChange={(e) => setBuyingPrice(e.target.value)}
                  fullWidth
                  required
                  margin="normal"
                />
                <TextField
                  label="Selling Price"
                  variant="outlined"
                  value={sellingPrice}
                  onChange={(e) => setSellingPrice(e.target.value)}
                  fullWidth
                  required
                  margin="normal"
                />
                <TextField
                  label="Discount Range (e.g., 10-20%)"
                  variant="outlined"
                  value={discountRange}
                  onChange={(e) => setDiscountRange(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Box>
              <Box textAlign="center" marginTop={4}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={loading ? <CircularProgress size={24} color="inherit" /> : <AddCircleOutline />}
                  disabled={loading}
                  style={{ backgroundColor: '#388E3C', color: 'white' }}
                >
                  {loading ? 'Creating...' : 'Create Product'}
                </Button>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default CreateProductPage;
