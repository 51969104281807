import React, { useState } from 'react';

const MonthlyProfits = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const weeklyProfits = [
    { week: '2024-07-01 to 2024-07-07', category: 'Category1', product: 'Product1', serialNumber: '001', buyingPrice: 100, sellingPrice: 150, dateSold: '2024-07-02', profit: 50 },
    { week: '2024-07-08 to 2024-07-14', category: 'Category2', product: 'Product2', serialNumber: '002', buyingPrice: 200, sellingPrice: 250, dateSold: '2024-07-09', profit: 50 },
    { week: '2024-07-15 to 2024-07-21', category: 'Category3', product: 'Product3', serialNumber: '003', buyingPrice: 300, sellingPrice: 400, dateSold: '2024-07-16', profit: 100 },
  ];

  const filteredProfits = weeklyProfits.filter(profit =>
    profit.dateSold >= startDate && profit.dateSold <= endDate
  );

  const totalProfit = filteredProfits.reduce((total, record) => total + record.profit, 0);

  return (
    <div className="container">
      <header className="my-3">
        <h1>Monthly Profits</h1>
      </header>
      <div className="mb-3 d-flex">
        <input
          type="date"
          className="form-control me-2"
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
        />
        <input
          type="date"
          className="form-control me-2"
          value={endDate}
          onChange={e => setEndDate(e.target.value)}
        />
      </div>
      <main>
        <section>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Week</th>
                <th>Category</th>
                <th>Product</th>
                <th>Serial Number</th>
                <th>Buying Price</th>
                <th>Selling Price</th>
                <th>Date Sold</th>
                <th>Profit</th>
              </tr>
            </thead>
            <tbody>
              {filteredProfits.map((record, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{record.week}</td>
                  <td>{record.category}</td>
                  <td>{record.product}</td>
                  <td>{record.serialNumber}</td>
                  <td>{record.buyingPrice}</td>
                  <td>{record.sellingPrice}</td>
                  <td>{record.dateSold}</td>
                  <td>{record.profit}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="8" className="text-end"><strong>Total Profit:</strong></td>
                <td><strong>{totalProfit}</strong></td>
              </tr>
            </tfoot>
          </table>
        </section>
      </main>
      <footer className="text-center mt-4">
        <p>&copy; 2024 Monthly Profits</p>
      </footer>
    </div>
  );
};

export default MonthlyProfits;

