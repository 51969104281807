// src/NotificationService.js
import { ref, push, onValue, query, orderByChild, equalTo,set, update } from 'firebase/database';
import { realtimedb} from './firebase';

class NotificationService {
  constructor() {
    this.notificationsRef = ref(realtimedb, 'notifications');
  }

  /**
   * Adds a new notification to the Realtime Database.
   * @param {string} receiverId - ID of the receiver ('shopmanager', 'admin', or 'all').
   * @param {string} message - The notification message.
   * @returns {Promise<string>} - The key of the created notification.
   */
  addNotification(receiverId, message) {
    return new Promise((resolve, reject) => {
      const newNotificationRef = ref(realtimedb,'notifications'); // This creates a new reference
      const notificationData = {
        receiverId,
        message,
        timestamp: Date.now(),
        isUnread: true,
      };
      // Use set() on the new notification reference with the data
      push(newNotificationRef, notificationData)
        .then(() => resolve(newNotificationRef.key))
        .catch((error) => reject(error));
    });
  }


  /**
   * Marks a notification as read.
   * @param {string} notificationId - The ID/key of the notification to mark as read.
   * @returns {Promise<void>}
   */
  markAsRead(notificationId) {
    return new Promise((resolve, reject) => {
      const notificationRef = ref(realtimedb, `notifications/${notificationId}`);
      update(notificationRef, { isUnread: false })
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  }

  /**
   * Retrieves all notifications for a specific receiver in real-time.
   * @param {string} receiverId - ID of the receiver.
   * @param {function} callback - Function to call with the list of notifications.
   * @returns {function} - Unsubscribe function to stop listening.
   */
  getNotifications(receiverId, callback) {
    const notificationsQuery = query(
      this.notificationsRef,
      orderByChild('receiverId'),
      equalTo(receiverId)
    );

    const unsubscribe = onValue(notificationsQuery, (snapshot) => {
      const notifications = [];
      snapshot.forEach((childSnapshot) => {
        notifications.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      // Sort notifications by timestamp descending
      notifications.sort((a, b) => b.timestamp - a.timestamp);
      callback(notifications);
    }, (error) => {
      console.error("Error fetching notifications: ", error);
    });

    return unsubscribe;
  }

  /**
   * Retrieves all unread notifications for a specific receiver in real-time.
   * @param {string} receiverId - ID of the receiver.
   * @param {function} callback - Function to call with the list of unread notifications.
   * @returns {function} - Unsubscribe function to stop listening.
   */
  getUnreadNotifications(receiverId, callback) {
    const notificationsQuery = query(
      this.notificationsRef,
      orderByChild('receiverId'),
      equalTo(receiverId)
    );

    const unsubscribe = onValue(notificationsQuery, (snapshot) => {
      const unreadNotifications = [];
      snapshot.forEach((childSnapshot) => {
        const notif = childSnapshot.val();
        if (notif.isUnread) {
          unreadNotifications.push({ id: childSnapshot.key, ...notif });
        }
      });
      // Sort notifications by timestamp descending
      unreadNotifications.sort((a, b) => b.timestamp - a.timestamp);
      callback(unreadNotifications);
    }, (error) => {
      console.error("Error fetching unread notifications: ", error);
    });

    return unsubscribe;
  }
}

export default new NotificationService();
