// src/getUserRole.js
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase';

/**
 * Fetches the role of the current user based on their email.
 * @param {string} userEmail - The email of the current user.
 * @returns {Promise<string>} - The role of the user.
 */
const getUserRole = async (userEmail) => {
  try {
    const employeesRef = collection(db, 'employees');
    const userQuery = query(employeesRef, where('email', '==', userEmail));
    const querySnapshot = await getDocs(userQuery);

    if (querySnapshot.empty) {
      throw new Error('User role not found.');
    }

    let role = '';
    querySnapshot.forEach((doc) => {
      role = doc.data().role; // Access the role from the document data
    });

    return role;
  } catch (error) {
    console.error("Error fetching user role: ", error);
    throw error; // Re-throw the error for further handling
  }
};

export default getUserRole;
