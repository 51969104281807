import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Paper,
  MenuItem,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase"; // Adjust the path according to your file structure
import { toast } from "react-toastify"; // Ensure you have react-toastify installed

const StyledPaper = styled(Paper)({
  padding: "2rem",
  borderRadius: "15px",
  backgroundColor: "#F1F8F6",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
});

const SubmitButton = styled(Button)({
  backgroundColor: "#4CAF50", // Safaricom green
  color: "#FFF",
  padding: "0.75rem",
  marginTop: "1.5rem",
  "&:hover": {
    backgroundColor: "#388E3C", // Darker green on hover
  },
});

const StyledTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#4CAF50", // Safaricom green for focus state
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#4CAF50", // Safaricom green for border
    },
    "&:hover fieldset": {
      borderColor: "#388E3C", // Darker green for hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#4CAF50",
    },
  },
});

const AddSalesperson = () => {
  const [salesperson, setSalesperson] = useState({
    name: "",
    id: "",
    dob: "",
    email: "",
    role: "",
    assignedShop: "",
  });

  const [shops, setShops] = useState([]);

  useEffect(() => {
    const fetchShops = async () => {
      const q = query(collection(db, "shoplist"));
      const querySnapshot = await getDocs(q);
      const shopList = querySnapshot.docs.map((doc) =>
        doc.data().shopName.toUpperCase()
      );
      setShops(shopList);
    };

    fetchShops();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSalesperson({ ...salesperson, [name]: value });
  };

  const validateForm = () => {
    const { name, id, dob, email, role, assignedShop } = salesperson;
    if (!name || !id || !dob || !email || !role || !assignedShop) {
      toast.error("Please fill out all fields.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const emailLower = salesperson.email.toLowerCase();
    const q = query(collection(db, "employees"), where("email", "==", emailLower));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      toast.error("Employee with this email already exists.");
      return;
    }

    try {
      await addDoc(collection(db, "employees"), {
        ...salesperson,
        email: emailLower,
        assignedShop: salesperson.assignedShop.toLowerCase(),
      });

      toast.success("Employee added successfully.");

      // Clear form data
      setSalesperson({
        name: "",
        id: "",
        dob: "",
        email: "",
        role: "",
        assignedShop: "",
      });
    } catch (error) {
      console.error("Error adding employee:", error);
      toast.error("Error adding employee. Please try again.");
    }
  };

  return (
    <Container maxWidth="md" sx={{ padding: "2rem 0" }}>
      <StyledPaper>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ color: "#388E3C", fontWeight: "bold" }}
        >
          Add Employee
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="Full Name"
                name="name"
                fullWidth
                variant="outlined"
                value={salesperson.name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="ID Number"
                name="id"
                fullWidth
                variant="outlined"
                value={salesperson.id}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="Date of Birth"
                name="dob"
                type="date"
                fullWidth
                variant="outlined"
                value={salesperson.dob}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="Email"
                name="email"
                fullWidth
                variant="outlined"
                value={salesperson.email}
                onChange={handleChange}
                type="email"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="Role"
                name="role"
                fullWidth
                variant="outlined"
                select
                value={salesperson.role}
                onChange={handleChange}
                required
              >
                <MenuItem value="salesperson">Salesperson</MenuItem>
                <MenuItem value="shopmanager">Shop Manager</MenuItem>
              </StyledTextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="Assigned Shop"
                name="assignedShop"
                fullWidth
                variant="outlined"
                select
                value={salesperson.assignedShop}
                onChange={handleChange}
                required
              >
                {shops.map((shop) => (
                  <MenuItem key={shop} value={shop}>
                    {shop}
                  </MenuItem>
                ))}
              </StyledTextField>
            </Grid>
          </Grid>
          <Box textAlign="center">
            <SubmitButton type="submit" variant="contained">
              Add Employee
            </SubmitButton>
          </Box>
        </form>
      </StyledPaper>
    </Container>
  );
};

export default AddSalesperson;
