import React from 'react';
import Card from 'react-bootstrap/Card';
import '../components/shopCard.css'; // Import the CSS file

const ShopCard = ({ shop }) => {
  // Define a color scheme for the shops
  const shopColors = {
    shop1: 'teal',
    shop2: 'maroon',
    shop3: 'green',
    shop4: 'lightgreen',
    shop5: 'blue',
    shop6: 'orange',
    shop7: 'purple',
    shop8: 'red',
    default: 'black' // Fallback color if the shop isn't defined
  };

  // Use the shop name to determine the color, fallback to 'black' if not found
  const color = shopColors[shop.name] || shopColors.default;

  return (
    <Card className="shop-card">
      <Card.Body>
        {/* Shop name with dynamic color */}
        <Card.Title className="shop-card-title" style={{ color: color }}>
          {shop.name}
        </Card.Title>
        {/* Revenue, Profit, Daily Sales with Arial font */}
        <Card.Text className="shop-card-text">Revenue: Ksh {shop.revenue}</Card.Text>
        <Card.Text className="shop-card-text">Profit: Ksh {shop.profit}</Card.Text>
        <Card.Text className="shop-card-text">Daily Sales: Ksh {shop.dailySales}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ShopCard;
