// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // If using Firestore
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD89a1y6i10SSm_td4uVgyGO5unMEqO7qE",
  authDomain: "mathare-f7e84.firebaseapp.com",
  projectId: "mathare-f7e84",
  storageBucket: "mathare-f7e84.appspot.com",
  messagingSenderId: "66488273317",
  databaseurl:"https://mathare-f7e84-default-rtdb.firebaseio.com",
  appId: "1:66488273317:web:05c46ba886abfb67fe804c",
  measurementId: "G-JCVHJ4YE6D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore or Realtime Database
const db = getFirestore(app); // For Firestore
 const realtimedb = getDatabase(app); // For Realtime Database
const auth = getAuth(app);
const storage = getStorage(app);

export { db,auth,realtimedb,storage };

