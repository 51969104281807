// src/components/SalesTable.js

import React from 'react';
import { Table } from 'react-bootstrap';

const SalesTable = ({ salesData }) => (
  <Table striped bordered hover>
    <thead>
      <tr>
        <th>Shop Name</th>
        <th>LNM Sales</th>
        <th>Watu Simu Sales</th>
        <th>OnPhone Sales</th>
      </tr>
    </thead>
    <tbody>
      {salesData.map((data, index) => (
        <tr key={index}>
          <td>{data.shopName}</td>
          <td>Ksh {data.lnmSales}</td>
          <td>Ksh {data.watuSimuSales}</td>
          <td>Ksh {data.onphoneSales}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default SalesTable;
