import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db, auth } from "../firebase";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Tooltip,
  CssBaseline,
  Switch,
  FormControlLabel,
  IconButton,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";

const getMuiTheme = (darkMode) =>
  createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        main: "#4CAF50",
      },
    },
  });

const ViewProducts = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [categories, setCategories] = useState(["All"]);
  const [shops, setShops] = useState(["All"]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedShop, setSelectedShop] = useState("All");
  const [darkMode, setDarkMode] = useState(false);
  const [role, setRole] = useState("");

  const fetchUserRoleAndShop = async () => {
    const userEmail = auth.currentUser.email;
    const employeeSnapshot = await getDocs(
      query(collection(db, "employees"), where("email", "==", userEmail))
    );
    const employeeData = employeeSnapshot.docs[0].data();
    const { role, assignedShop } = employeeData;

    const shoplistSnapshot = await getDocs(collection(db, "shoplist"));
    const shopNames = shoplistSnapshot.docs.map((doc) => doc.data().shopName);
    setShops(["All", ...shopNames]);

    // Fetch products after getting the role and assigned shop
    await fetchData(role, assignedShop);
    setRole(role);
  };

  const fetchData = async (role, assignedShop) => {
    let productsData = [];

    if (role === "shopmanager") {
      const productsQuery = query(
        collection(db, `received_products/${assignedShop}/products`)
      );
      const querySnapshot = await getDocs(productsQuery);
      productsData = querySnapshot.docs.map((doc, index) => ({
        id: index + 1,
        ...doc.data(),
      }));
    } else if (role === "admin") {
      const shopNamesToFetch =
        selectedShop === "All" ? shops.slice(1) : [selectedShop];

      for (const shopName of shopNamesToFetch) {
        const productsQuery = query(
          collection(db, `received_products/${shopName}/products`)
        );
        const querySnapshot = await getDocs(productsQuery);
        const shopProducts = querySnapshot.docs.map((doc, index) => ({
          id: productsData.length + index + 1,
          ...doc.data(),
          shopName,
        }));
        productsData = [...productsData, ...shopProducts];
      }
    }

    setData(productsData);
  };
  useEffect(() => {
    fetchUserRoleAndShop();
    fetchData()
  }, []); // This runs once when the component mounts
  
  useEffect(() => {
  filterData(data, selectedCategory, selectedShop);
}, [data, selectedCategory, selectedShop]);
 // This runs when data, selectedCategory, or selectedShop changes
  
  const fetchCategories = async () => {
    const querySnapshot = await getDocs(collection(db, "categories"));
    const categoryData = querySnapshot.docs.map((doc) => doc.data().name);
    setCategories(["All", ...categoryData]);
  };
  
  // Call fetchCategories in the first useEffect if needed, or use another useEffect
  useEffect(() => {
    fetchCategories();
  }, []); // Call this once when the component mounts
  

  const filterData = (products, category, shop) => {
    let filtered = products;
  
    // Filter by category if it's not 'All'
    if (category !== "All") {
      filtered = filtered.filter((product) =>
        product.category.toLowerCase() === category.toLowerCase()
      );
    }
  
    // Filter by shop if it's not 'All'
    if (shop !== "All") {
      filtered = filtered.filter((product) =>
        product.shopName.toLowerCase() === selectedShop.toLowerCase()
      );
    }
  
    // Set the filtered data
    setFilteredData(filtered);
  };
  
  useEffect(() => {
    filterData(data, selectedCategory, selectedShop);
  }, [selectedCategory, selectedShop]);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleShopChange = (event) => {
    setSelectedShop(event.target.value);
  };

  // PDF generation - includes Serial Numbers in the report
  const generatePDF = () => {
    const doc = new jsPDF();
    
    // Header text
    doc.text(`${selectedShop} Products Report`, 10, 10);
  
    // Calculate total items and total amount
    let totalItems = 0;
    let totalAmount = 0;
  
    // Table configuration with data
    autoTable(doc, {
      head: [
        ["#", "Product Name", "Category", "Buying Price", "Selling Price", "In Stock", "Status", "Serial Numbers", "Total Amount"],
      ],
      body: filteredData.map((item, index) => {
        const totalAmountPerRow = item.quantity * item.buyingPrice;
        totalItems += item.quantity;
        totalAmount += totalAmountPerRow;
  
        return [
          index + 1,
          item.productName,
          item.category,
          item.buyingPrice,
          item.sellingPrice,
          item.quantity,
          item.status,
          item.serialNumbers.join(", "),
          totalAmountPerRow.toFixed(2),  // Display the total for the row
        ];
      }),
    });
  
    // Add summary at the end of the table
    autoTable(doc, {
      body: [
        // Empty row for space
        ["", "", "", "", "", "", "", "", ""],
        // Total items and total amount
        ["", "", "", "", "Total Items:", totalItems, "", "", ""],
        ["", "", "", "", "Total Amount:", totalAmount.toFixed(2), "", "", ""],
      ],
      theme: 'plain', // No borders for the summary
    });
  
    // Save the PDF
    doc.save(`${selectedShop}_products_report.pdf`);
  };
  

  // Excel generation - includes Serial Numbers in the export
  const generateExcel = () => {
    // Calculate total items and total amount
    let totalItems = 0;
    let totalAmount = 0;
  
    // Create the worksheet data
    const data = filteredData.map((item, index) => {
      const totalAmountPerRow = item.quantity * item.buyingPrice;
      totalItems += item.quantity;
      totalAmount += totalAmountPerRow;
  
      return {
        "#": index + 1,
        "Product Name": item.productName,
        Category: item.category,
        "Buying Price": item.buyingPrice,
        "Selling Price": item.sellingPrice,
        "In Stock": item.quantity,
        Status: item.status,
        "Serial Numbers": item.serialNumbers.join(", "),
        "Total Amount": totalAmountPerRow.toFixed(2),  // Total per product
      };
    });
  
    // Add the summary row
    data.push({
      "#": "",
      "Product Name": "",
      Category: "",
      "Buying Price": "",
      "Selling Price": "",
      "In Stock": "Total Items: " + totalItems, // Total quantity
      Status: "",
      "Serial Numbers": "",
      "Total Amount": "Total Amount: " + totalAmount.toFixed(2), // Total stock value
    });
  
    // Convert data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
  
    // Apply styling to the summary row (last row)
    const lastRow = filteredData.length + 1;
    const totalRowRange = `A${lastRow + 1}:H${lastRow + 1}`; // Assuming 8 columns
    worksheet[totalRowRange] = { s: { fill: { fgColor: { rgb: "FFFF00" } } } }; // Yellow background
  
    // Create workbook and append worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `${selectedShop}`);
  
    // Write the file
    XLSX.writeFile(workbook, `${selectedShop}_products_report.xlsx`);
  };
  

  // Table columns without Serial Numbers
  const columns = [
    { name: "id", label: "#", options: { filter: false, sort: false, setCellHeaderProps: () => ({ style: { width: '50px' } }) } },
    { name: "shopName", label: "Shop", options: { filter: false, sort: false } },
    {
      name: "productName",
      label: "Product Name",
      options: { filter: true, sort: false, setCellProps: () => ({ style: { width: '200px' } }) },
    },
    {
      name: "category",
      label: "Category",
      options: { filter: true, sort: false, setCellProps: () => ({ style: { width: '150px' } }) },
    },
    {
      name: "buyingPrice",
      label: "Buying Price",
      options: { filter: true, sort: false, setCellProps: () => ({ style: { width: '120px' } }) },
    },
    {
      name: "sellingPrice",
      label: "Selling Price",
      options: { filter: true, sort: false, setCellProps: () => ({ style: { width: '120px' } }) },
    },
    {
      name: "quantity",
      label: "In Stock",
      options: { filter: true, sort: false, setCellProps: () => ({ style: { width: '80px' } }) },
    },
    {
      name: "status",
      label: "Status",
      options: { filter: true, sort: false, setCellProps: () => ({ style: { width: '100px' } }) },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    download: false,
    print: false,
    viewColumns: false,
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 20, 50, 100],
    fixedHeader: true,
    customToolbar: () => (
      <React.Fragment>
        <Tooltip title="Download PDF">
          <IconButton onClick={generatePDF}>
            <i className="fas fa-file-pdf" style={{ color: "red" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download Excel">
          <IconButton onClick={generateExcel}>
            <i className="fas fa-file-excel" style={{ color: "green" }} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    ),
  };

  return (
    <ThemeProvider theme={getMuiTheme(darkMode)}>
      <CssBaseline />
      <Box sx={{ padding: 3 }}>
        <FormControlLabel
          control={
            <Switch
              checked={darkMode}
              onChange={() => setDarkMode(!darkMode)}
            />
          }
          label="Dark Mode"
        />
        <Typography variant="h6" gutterBottom>
          View Products
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <FormControl sx={{ minWidth: 120, marginRight: 2 }}>
  <Tooltip title="Select category to view products" arrow>
    <Select
      value={selectedCategory}
      onChange={handleCategoryChange}
      label="Category"
    >
      {categories.map((category, index) => (
        <MenuItem key={index} value={category}>
          {category}
        </MenuItem>
      ))}
    </Select>
  </Tooltip>
</FormControl>



          {role === "admin" && (
            <FormControl sx={{ minWidth: 120 }}>
              <Tooltip title="Select the shop to view products" arrow>
                <Select
                  value={selectedShop}
                  onChange={handleShopChange}
                  label="Shop"
                >
                  {shops.map((shop, index) => (
                    <MenuItem key={index} value={shop}>
                      {shop}
                    </MenuItem>
                  ))}
                </Select>
              </Tooltip>
              {/* Search Button with green outline */}
<Button
  variant="outlined"
  onClick={() => fetchUserRoleAndShop()}
  sx={{
    borderColor: 'green',
    color: 'green',
    '&:hover': {
      borderColor: 'darkgreen',
    },
  }}
  // Call fetchData function on click
>
  Apply Filters
</Button>
            </FormControl>
          )}
        </Box>
        <MUIDataTable
          title="Product List"
          data={filteredData}
          columns={columns}
          options={options}
        />
      </Box>
    </ThemeProvider>
  );
};

export default ViewProducts;
