// src/components/Login.js
import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import './Login.css';
import logo from '../assets/logo2.jpg';
import { toast } from 'react-toastify';


const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const auth = getAuth();
  const db = getFirestore();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Query to get the email associated with the username
      const q = query(collection(db, 'users'), where('username', '==', username));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const email = userDoc.data().email;

        // Sign in with Firebase Authentication
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Call the onLogin function with user data
        onLogin(user);
       // await AuditLogger.logAction('task login','task1',`Logged in ${user}. email: ${email}.`);
      } else {
        toast.error('No user found with the given username');
      }
    } catch (error) {
      console.error('Error signing in:', error);
      let errorMessage = 'Error signing in: ';
      switch (error.code) {
        case 'auth/invalid-email':
          errorMessage += 'Invalid email format.';
          break;
        case 'auth/user-not-found':
          errorMessage += 'No user found with this email.';
          break;
        case 'auth/wrong-password':
          errorMessage += 'Incorrect password.';
          break;
        default:
          errorMessage += error.message;
      }
      toast.error(errorMessage);
    }
  };

  return (
    <div className="login-container">
      <img src={logo} alt="OSEO Comm Mathare Logo" className="logo" />
      <h2>Cowtrack Multi-Store</h2>
      <form onSubmit={handleLogin}>
        <div className="input-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
