import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { collection, getDocs, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Grid,
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  InputLabel,
  DialogTitle,
  MenuItem,
  InputAdornment,
  Tooltip,
  Autocomplete,
  Chip,
} from "@mui/material";
import { Edit, Delete, ArrowBack,RemoveCircleOutline,AddCircleOutline } from "@mui/icons-material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const getMuiTheme = (darkMode) =>
  createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        main: "#4CAF50",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: "#000000",
            backgroundColor: "#ffffff",
            "&:hover": {
              backgroundColor: "#388E3C",
            },
          },
        },
      },
    },
  });

const EditProductPage = () => {
  const [shopsname, setShop] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState({
    category: "",
    productName: "",
    buyingPrice: "",
    sellingPrice: "",
    serialNumbers: [],
  });
  const [editMode, setEditMode] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [shopNames, setShopNames] = useState([]);
  const [newSerial, setNewSerial] = useState([]);

  useEffect(() => {
    const fetchShopNames = async () => {
      const shoplistCollection = collection(db, "shoplist");
      const shoplistSnapshot = await getDocs(shoplistCollection);
      const shopList = shoplistSnapshot.docs.map((doc) => doc.data().shopName);
      setShopNames(shopList);
    };

    fetchShopNames();
  }, []);

  const fetchProducts = async () => {
    if (!shopsname) return;

    const productsCollection = collection(db, `received_products/${shopsname}/products`);
    const snapshot = await getDocs(productsCollection);
    const shopProducts = snapshot.docs.map((doc, index) => ({
      id: doc.id,
      ...doc.data(),
      shopName: shopsname,
      index: index + 1,
    }));

    setProducts(shopProducts);
  };

  useEffect(() => {
    fetchProducts();
  }, [shopsname]);

  const handleEditClick = (product) => {
    setSelectedProduct(product);
    setFormData({
      category: product.category,
      name: product.productName,
      total: product.quantity,
      buyingPrice: product.buyingPrice,
      sellingPrice: product.sellingPrice,
      serialNumbers: product.serialNumbers || [],
    });
    setEditMode(true);
  };

  const handleDeleteClick = (product, shopname) => {
    setCurrentProduct(product);
    setShop(shopname);
    setOpenDeleteDialog(true);
  };

  const handleBackClick = () => {
    setEditMode(false);
    setSelectedProduct(null);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSerialNumberChange = (index, value) => {
    const updatedSerialNumbers = [...formData.serialNumbers];
    updatedSerialNumbers[index] = value;
    setFormData((prevData) => ({
      ...prevData,
      serialNumbers: updatedSerialNumbers,
    }));
  };

  const handleAddSerialNumber = () => {
    if (newSerial.trim() !== '') {
      setFormData((prevData) => ({
        ...prevData,
        serialNumbers: [...prevData.serialNumbers, newSerial], // Add new serial
      }));
      setNewSerial(''); // Clear the input field after adding
    }
  };
  

  const handleRemoveSerialNumber = (index) => {
    const updatedSerialNumbers = formData.serialNumbers.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      serialNumbers: updatedSerialNumbers,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedProduct) return;

    const productRef = doc(db, `received_products/${selectedProduct.shopName}/products`, selectedProduct.id);
    await updateDoc(productRef, {
      category: formData.category,
      productName: formData.name,
      quantity: formData.serialNumbers.length,
      totalItems: formData.total,
      buyingPrice: formData.buyingPrice,
      sellingPrice: formData.sellingPrice,
      serialNumbers: formData.serialNumbers,
    });

    // Reset the form and mode
    setEditMode(false);
    setSelectedProduct(null);
    setFormData({
      category: '',
      name: '',
      total: 0,
      buyingPrice: '',
      sellingPrice: '',
      serialNumbers: [],
    });
  };


  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    download: false,
    print: false,
    viewColumns: false,
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 50],
    fixedHeader: false,
    customToolbar: () => (
      <React.Fragment>
        <Tooltip title="Download PDF">
          <IconButton onClick={generatePDF}>
            <i className="fas fa-file-pdf" style={{ color: "red" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download Excel">
          <IconButton onClick={generateExcel}>
            <i className="fas fa-file-excel" style={{ color: "green" }} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    ),
  };

  const handleDeleteSubmit = async () => {
    if (!currentProduct) return;

    const productRef = doc(db, `received_products/${shopsname}/products`, currentProduct);
    await deleteDoc(productRef);

    setOpenDeleteDialog(false);
    setCurrentProduct(null);
    setEditMode(false);
    setSelectedProduct(null);
    setFormData({
      category: "",
      productName: "",
      buyingPrice: "",
      sellingPrice: "",
      serialNumbers: [],
    });
  };

 /* const handleExport = (format) => {
    if (format === "pdf") {
      const doc = new jsPDF();
      doc.text(`Shop: ${shopsname}`, 10, 10); // Styling shop name at top

      const data = products.map((product) => [
        product.category,
        product.productName,
        product.buyingPrice,
        product.sellingPrice,
        product.quantity,
        product.serialNumbers.join(", "), // Serial numbers
      ]);

      doc.autoTable({
        head: [["Category", "Product Name", "Buying Price", "Selling Price", "In Stock", "Serial Numbers"]],
        body: data,
      });

      doc.save("products.pdf");
  
    } else if (format === "excel") {
      // Create the worksheet with serial numbers joined by newlines
      const worksheet = XLSX.utils.json_to_sheet(
        products.map((product) => ({
          Category: product.category,
          "Product Name": product.productName,
          "Buying Price": product.buyingPrice,
          "Selling Price": product.sellingPrice,
          "In Stock": product.quantity,
          "Serial Numbers": product.serialNumbers.join("\n"), // Join with newline for wrapping
        }))
      );
    
      // Set text wrapping style for the entire worksheet
      const range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let row = range.s.r; row <= range.e.r; row++) {
        const cellRef = XLSX.utils.encode_cell({ r: row, c: 5 }); // Column index 5 for Serial Numbers
        if (!worksheet[cellRef]) continue; // Skip if the cell doesn't exist
    
        // Set the wrapText property
        if (!worksheet[cellRef].s) {
          worksheet[cellRef].s = {};
        }
        worksheet[cellRef].s.alignment = { wrapText: true };
      }
    
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Products");
      XLSX.writeFile(workbook, "products.xlsx");
    }
    
  };*/
  const generateExcel = () => {
    
      // Create the worksheet with serial numbers joined by newlines
      const worksheet = XLSX.utils.json_to_sheet(
        products.map((product) => ({
          Category: product.category,
          "Product Name": product.productName,
          "Buying Price": product.buyingPrice,
          "Selling Price": product.sellingPrice,
          "In Stock": product.quantity,
          "Serial Numbers": product.serialNumbers.join("\n"), // Join with newline for wrapping
        }))
      );
    
      // Set text wrapping style for the entire worksheet
      const range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let row = range.s.r; row <= range.e.r; row++) {
        const cellRef = XLSX.utils.encode_cell({ r: row, c: 5 }); // Column index 5 for Serial Numbers
        if (!worksheet[cellRef]) continue; // Skip if the cell doesn't exist
    
        // Set the wrapText property
        if (!worksheet[cellRef].s) {
          worksheet[cellRef].s = {};
        }
        worksheet[cellRef].s.alignment = { wrapText: true };
      }
    
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Products");
      XLSX.writeFile(workbook, "products.xlsx");
    
    
   
  };

  const generatePDF = () => {
    
      const doc = new jsPDF();
      doc.text(`Shop: ${shopsname}`, 10, 10); // Styling shop name at top

      const data = products.map((product) => [
        product.category,
        product.productName,
        product.buyingPrice,
        product.sellingPrice,
        product.quantity,
        product.serialNumbers.join(", "), // Serial numbers
      ]);

      doc.autoTable({
        head: [["Category", "Product Name", "Buying Price", "Selling Price", "In Stock", "Serial Numbers"]],
        body: data,
      });

      doc.save("products.pdf");
  
    
  };

  const columns = [
    { name: "index", label: "#" },
    { name: "shopName", label: "Shop" },
    { name: "category", label: "Category" },
    { name: "productName", label: "Name" },
    { name: "quantity", label: "In Stock" },
    { name: "buyingPrice", label: "Buying Price" },
    { name: "sellingPrice", label: "Selling Price" },
    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          const shopName = tableMeta.rowData[1];
          return (
            <div>
              <IconButton onClick={() => handleEditClick(products[tableMeta.rowIndex])} sx={{ color: "green" }}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => handleDeleteClick(value, shopName)} sx={{ color: "red" }}>
                <Delete />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

 

  return (
    <ThemeProvider theme={getMuiTheme(false)}>
      {!editMode ? (
        <div>
          <Box mb={2}>
          <InputLabel> Filter by Shop</InputLabel>
            <TextField
              select
              label="Select Shop"
              value={shopsname}
              onChange={(e) => setShop(e.target.value)}
             
            >
              {shopNames.map((shop, index) => (
                <MenuItem key={index} value={shop}>
                  {shop}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <MUIDataTable title={<Typography variant="h6" sx={{ color: "#4CAF50" }}>Products</Typography>} data={products} columns={columns} options={options} />

        
        </div>
      ) : (
        <div>
          <Button startIcon={<ArrowBack />} onClick={handleBackClick} sx={{ mb: 3 }}>
            Back to Products
          </Button>
          <Typography variant="h6">Edit Product</Typography>
      
<form onSubmit={handleSubmit}>
  <Box margin={2}>
    <Grid container spacing={2}>
      {/* Category */}
      <Grid item xs={12} sm={6}>
        <TextField
          label="Category"
          variant="outlined"
          name="category"
          value={formData.category}
          onChange={handleFormChange}
          fullWidth
          required
        />
      </Grid>

      {/* Name */}
      <Grid item xs={12} sm={6}>
        <TextField
          label="Name"
          variant="outlined"
          name="name"
          value={formData.name}
          onChange={handleFormChange}
          fullWidth
          required
        />
      </Grid>

      {/* Total Items */}
      <Grid item xs={12} sm={6}>
        <TextField
          label="Total Items"
          variant="outlined"
          name="total"
          value={formData.total}
          onChange={handleFormChange}
          fullWidth
          required
          type="number"
        />
      </Grid>

      {/* Buying Price */}
      <Grid item xs={12} sm={6}>
        <TextField
          label="Buying Price"
          variant="outlined"
          name="buyingPrice"
          value={formData.buyingPrice}
          onChange={handleFormChange}
          fullWidth
          required
          type="number"
          inputProps={{ step: '0.01' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">ksh</InputAdornment>,
          }}
        />
      </Grid>

      {/* Selling Price */}
      <Grid item xs={12} sm={6}>
        <TextField
          label="Selling Price"
          variant="outlined"
          name="sellingPrice"
          value={formData.sellingPrice}
          onChange={handleFormChange}
          fullWidth
          required
          type="number"
          inputProps={{ step: '0.01' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">ksh</InputAdornment>,
          }}
        />
      </Grid>

      {/* Serial Numbers */}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Serial Numbers
        </Typography>

        {/* Display existing serial numbers as chips */}
        <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
          {formData.serialNumbers.map((serialNumber, index) => (
            <Chip
              key={index}
              label={serialNumber}
              onDelete={() => handleRemoveSerialNumber(index)}
              color="primary"
            />
          ))}
        </Box>

        {/* Input for new serial number */}
        <TextField
          label="Add Serial Number"
          variant="outlined"
          value={newSerial}
          onChange={(e) => setNewSerial(e.target.value)}
          fullWidth
          margin="normal"
        />

        <Button
          variant="outlined"
          color="primary"
          onClick={handleAddSerialNumber}
          startIcon={<AddCircleOutline />}
          fullWidth
        >
          Add Serial Number
        </Button>
      </Grid>

      {/* Submit Button */}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="secondary"
          type="submit"
          fullWidth
        >
          Save Changes
        </Button>
      </Grid>
    </Grid>
  </Box>
</form>

        </div>
      )}

      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Delete Product</DialogTitle>
        <DialogContent>Are you sure you want to delete this product?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteSubmit} variant="contained" color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default EditProductPage;
