import { getFirestore, collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { toast } from 'react-toastify';
import { startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';

const db = getFirestore();
const auth = getAuth();

const  getAssignedShop = async () => {
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User is not authenticated');
  }

  const q = query(collection(db, 'employees'), where('email', '==', user.email));
  const querySnapshot = await getDocs(q);
  
  if (!querySnapshot.empty) {
    const userDoc = querySnapshot.docs[0];
    return userDoc.data().assignedShop;
  }
  
  throw new Error('Assigned shop not found');
};

const getEmployeesData = async () => {
  try {
    // Get the assigned shop
    const shop = await getAssignedShop();

    // Query to fetch employees assigned to the shop
    const q = query(collection(db, 'employees'), where('assignedShop', '==', shop));
    const querySnapshot = await getDocs(q);
    
    // Check if the query returned any documents
    if (!querySnapshot.empty) {
      // Extract data from each document and return it as an array
      const employees = querySnapshot.docs.map(doc => doc.data());
      return employees;
    }

    // If no employees found, return an empty array or handle as needed
    return [];
  } catch (error) {
    // Handle errors (e.g., logging or re-throwing)
    console.error('Error fetching employees data:', error);
    throw error; // Re-throw the error if needed
  }
};

const getCategorySales = async (shopName, category, startOfDayTimestamp, endOfDayTimestamp) => {
  try {
    const creditSalesRef = collection(db, `sales/${shopName}/credit_sales`);
    const mpesaSalesRef = collection(db, `sales/${shopName}/mpesa_sales`);

    // Ensure timestamps are Firestore-compatible
    const startOfDay = new Date(startOfDayTimestamp);
    const endOfDay = new Date(endOfDayTimestamp);

    // Create queries to fetch sales by category only
    const creditSalesQuery = query(
      creditSalesRef,
      where("customerCategory", "==", category)
    );

    const mpesaSalesQuery = query(
      mpesaSalesRef,
      where("customerCategory", "==", category)
    );

    // Fetch data from both queries in parallel
    const [creditSalesSnapshot, mpesaSalesSnapshot] = await Promise.all([
      getDocs(creditSalesQuery),
      getDocs(mpesaSalesQuery),
    ]);

    // Function to filter sales by date and sum up the 'total' field
    const calculateTotalSales = (snapshot) => {
      return snapshot.docs.reduce((total, doc) => {
        const data = doc.data();
        const saleTimestamp = data.saleTimestamp.toDate(); // Ensure Firestore timestamp is converted to Date

        // Filter by date range
        if (saleTimestamp >= startOfDay && saleTimestamp <= endOfDay) {
          return total + (data.total || 0); // Ensure 'total' exists and sum it up
        }
        return total;
      }, 0);
    };

    // Calculate total sales for both credit and mpesa sales after filtering by date
    const totalCreditSales = calculateTotalSales(creditSalesSnapshot);
    const totalMpesaSales = calculateTotalSales(mpesaSalesSnapshot);

    // Return the combined total sales
    return totalCreditSales + totalMpesaSales;
  } catch (error) {
    if (error.code === 'failed-precondition' && error.message.includes('index')) {
      console.error(`Missing Firestore index. Create it here:`, error.message);
    } else {
      console.error(`Error fetching category sales for ${category}:`, error);
    }
    throw error;
  }
};


export const populateSalesOverview = async () => {
  try {
    const assignedShop = await getAssignedShop(); // Assuming assignedShop is a string (single shop)

    // Get today's date
    const today = new Date();

    // Get the first and last day of the current month
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);


    // Fetch sales data for the assigned shop within the current month
    const [lnmSales, watuSimuSales, onphoneSales, walkinSales, wabeySales] = await Promise.all([
      getCategorySales(assignedShop, 'LMM', startOfMonth, endOfMonth),
      getCategorySales(assignedShop, 'Watu Simu', startOfMonth, endOfMonth),
      getCategorySales(assignedShop, 'Onfone', startOfMonth, endOfMonth),
      getCategorySales(assignedShop, 'Walkin', startOfMonth, endOfMonth),
      getCategorySales(assignedShop, 'Wabey', startOfMonth, endOfMonth),
    ]);
   
    // Return the sales data for the assigned shop
    return { lnmSales, watuSimuSales, onphoneSales, walkinSales, wabeySales };
  } catch (error) {
    console.error("Error populating sales overview:", error);
    throw error;
  }
};




export const  getUserShop = async () => {
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User is not authenticated');
  }

  const q = query(collection(db, 'employees'), where('email', '==', user.email));
  const querySnapshot = await getDocs(q);
  
  if (!querySnapshot.empty) {
    const userDoc = querySnapshot.docs[0];
    return userDoc.data().assignedShop;
  }
  
  throw new Error('Assigned shop not found');
};

export const getUserRole= async()=>{
  return 'shopmanager';

};

export const getSalesData = async () => {
  try {
    const assignedShop = await getAssignedShop();
    const today = new Date();

    // Timezone offset correction for consistency
    const timezoneOffset = today.getTimezoneOffset() * 60000;

    // Start and end of the current day
    const todayStart = new Date(startOfDay(today).getTime() - timezoneOffset);
    const todayEnd = new Date(endOfDay(today).getTime() - timezoneOffset);

    // Start and end of the current week
    const weekStart = new Date(startOfWeek(today, { weekStartsOn: 1 }).getTime() - timezoneOffset); // Monday as the start of the week
    const weekEnd = new Date(endOfWeek(today, { weekStartsOn: 1 }).getTime() - timezoneOffset);

    // Start and end of the current month
    const monthStart = new Date(startOfMonth(today).getTime() - timezoneOffset);
    const monthEnd = new Date(endOfMonth(today).getTime() - timezoneOffset);

    // Firestore queries for credit_sales and mpesa_sales
    const salesTodayQuery = query(
      collection(db, `sales/${assignedShop}/credit_sales`),
      where('saleTimestamp', '>=', Timestamp.fromDate(todayStart)),
      where('saleTimestamp', '<=', Timestamp.fromDate(todayEnd))
    );

    const salesWeekQuery = query(
      collection(db, `sales/${assignedShop}/credit_sales`),
      where('saleTimestamp', '>=', Timestamp.fromDate(weekStart)),
      where('saleTimestamp', '<=', Timestamp.fromDate(weekEnd))
    );

    const salesMonthQuery = query(
      collection(db, `sales/${assignedShop}/credit_sales`),
      where('saleTimestamp', '>=', Timestamp.fromDate(monthStart)),
      where('saleTimestamp', '<=', Timestamp.fromDate(monthEnd))
    );

    const mpesaTodayQuery = query(
      collection(db, `sales/${assignedShop}/mpesa_sales`),
      where('saleTimestamp', '>=', Timestamp.fromDate(todayStart)),
      where('saleTimestamp', '<=', Timestamp.fromDate(todayEnd))
    );

    const mpesaWeekQuery = query(
      collection(db, `sales/${assignedShop}/mpesa_sales`),
      where('saleTimestamp', '>=', Timestamp.fromDate(weekStart)),
      where('saleTimestamp', '<=', Timestamp.fromDate(weekEnd))
    );

    const mpesaMonthQuery = query(
      collection(db, `sales/${assignedShop}/mpesa_sales`),
      where('saleTimestamp', '>=', Timestamp.fromDate(monthStart)),
      where('saleTimestamp', '<=', Timestamp.fromDate(monthEnd))
    );

    // Run the queries in parallel for both credit_sales and mpesa_sales
    const [
      salesTodaySnapshot,
      salesWeekSnapshot,
      salesMonthSnapshot,
      mpesaTodaySnapshot,
      mpesaWeekSnapshot,
      mpesaMonthSnapshot,
    ] = await Promise.all([
      getDocs(salesTodayQuery),
      getDocs(salesWeekQuery),
      getDocs(salesMonthQuery),
      getDocs(mpesaTodayQuery),
      getDocs(mpesaWeekQuery),
      getDocs(mpesaMonthQuery),
    ]);

    // Aggregating sales and profit data
    let todaySales = 0;
    let weekSales = 0;
    let monthSales = 0;
    let monthProfit = 0;

    const accumulateSales = (snapshot) => {
      let totalSales = 0;
      snapshot.forEach(doc => {
        const sale = doc.data();
        const saleTotal = sale.total || 0;
        totalSales += saleTotal;

        // Calculate profit for the month only
        if (snapshot === salesMonthSnapshot || snapshot === mpesaMonthSnapshot) {
          sale.products.forEach(item => {
            const { buyingprice, price, quantity } = item;
            monthProfit += (price - buyingprice) * quantity;
          });
        }
      });
      return totalSales;
    };

    // Calculate sales for credit sales and mpesa sales
    todaySales += accumulateSales(salesTodaySnapshot) + accumulateSales(mpesaTodaySnapshot);
    weekSales += accumulateSales(salesWeekSnapshot) + accumulateSales(mpesaWeekSnapshot);
    monthSales += accumulateSales(salesMonthSnapshot) + accumulateSales(mpesaMonthSnapshot);

    // Fetch received products to calculate purchases for the month
    const receivedProductsQuery = query(
      collection(db, `received_products/${assignedShop}/products`),
      where('receivedDate', '>=', Timestamp.fromDate(monthStart)),
      where('receivedDate', '<=', Timestamp.fromDate(monthEnd))
    );
    const receivedProductsSnapshot = await getDocs(receivedProductsQuery);

    let monthPurchases = 0;
    receivedProductsSnapshot.forEach(doc => {
      const product = doc.data();
      monthPurchases += product.buyingPrice * product.totalItems;
    });

    // Fetch LNM, Watu Simu, and Onfone sales
    const lnmQuery = query(collection(db, `sales/${assignedShop}/credit_sales`), where('customerCategory', '==', 'LMM'));
    const watuSimuQuery = query(collection(db, `sales/${assignedShop}/credit_sales`), where('customerCategory', '==', 'Watu Simu'));
    const onPhoneQuery = query(collection(db, `sales/${assignedShop}/credit_sales`), where('customerCategory', '==', 'Onfone'));

    const [lnmSnapshot, watuSimuSnapshot, onPhoneSnapshot] = await Promise.all([
      getDocs(lnmQuery),
      getDocs(watuSimuQuery),
      getDocs(onPhoneQuery),
    ]);

    const lnmSales = lnmSnapshot.docs.reduce((sum, doc) => sum + (doc.data().total || 0), 0);
    const watuSimuSales = watuSimuSnapshot.docs.reduce((sum, doc) => sum + (doc.data().total || 0), 0);
    const onPhoneSales = onPhoneSnapshot.docs.reduce((sum, doc) => sum + (doc.data().total || 0), 0);

    return {
      today: todaySales,
      thisWeek: weekSales,
      thisMonth: monthSales,
      thisMonthPurchases: monthPurchases,
      thisMonthProfit: monthProfit,
      lnm: lnmSales,
      watuSimu: watuSimuSales,
      onPhone: onPhoneSales,
    };
  } catch (error) {
    console.error("Error fetching sales data:", error);
    throw new Error("Unable to fetch sales data. Please try again later.");
  }
};



// Function to fetch employee names and their respective sales
export const getSalesPeopleSales = async () => {
  try {
    // 1. Get the assigned shop
    const assignedShop = await getAssignedShop();

    // 2. Fetch all employees from the assigned shop
    const employeeData = await getEmployeesData();  // Returns an array of employee objects with `name` and `role`
    
    // Extract names into an array
    const employeeNames = employeeData.map(employee => employee.name);

    // Date range for the current month
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const startOfMonth = Timestamp.fromDate(firstDayOfMonth);
    const endOfMonth = Timestamp.fromDate(new Date(lastDayOfMonth.setHours(23, 59, 59, 999)));

    // 3. Fetch all sales for the assigned shop
    const creditSalesQuery = query(
      collection(db, `sales/${assignedShop}/credit_sales`),
      where("saleTimestamp", ">=", startOfMonth),
      where("saleTimestamp", "<=", endOfMonth)
    );

    const mpesaSalesQuery = query(
      collection(db, `sales/${assignedShop}/mpesa_sales`),
      where("saleTimestamp", ">=", startOfMonth),
      where("saleTimestamp", "<=", endOfMonth)
    );

    const [creditSalesSnapshot, mpesaSalesSnapshot] = await Promise.all([
      getDocs(creditSalesQuery),
      getDocs(mpesaSalesQuery),
    ]);

    // Combine credit and mpesa sales into a single array
    const allSales = [...creditSalesSnapshot.docs, ...mpesaSalesSnapshot.docs];

    // 4. Process sales for each employee
    let totalSalesBySalespeople = 0;  // Track total sales made by salespeople
    let totalProfitBySalespeople = 0;  // Track total profit made by salespeople
    let commission = 0;
    const employeeSalesData = employeeNames.map((name) => {
      // Get the role of the current employee by searching in employeeData array
      const employee = employeeData.find(e => e.name === name);
      const { role } = employee;

      let totalSales = 0;
      let totalProfit = 0;
      

      // Filter sales by salesPerson name
      const employeeSales = allSales.filter(doc => doc.data().salesperson === name);

      // Calculate total sales and profit for each employee's sales
      employeeSales.forEach(doc => {
        const sale = doc.data();
        sale.products.forEach(product => {
          const { price, buyingprice, quantity } = product;  // Assuming there's a `buyingPrice` field
          const saleValue = price * quantity;
          totalSales += saleValue;
          totalProfit += (price - buyingprice) * quantity;  // Profit = (price - buyingPrice) * quantity sold
        });
      });

      // If the employee is a salesperson, add their total sales and profit to the shop manager's share
      if (role === 'salesperson') {
        totalSalesBySalespeople += totalSales;
        totalProfitBySalespeople += totalProfit;
      }

      // 5. Calculate commission based on the employee's role
     
      let deposit = 0;

      if (role === 'salesperson') {
        commission = (0.10 * totalProfit).toFixed(2);  // Salesperson gets 10% of profit
      } 

      // Return the sales data for the current employee
      return {
        name,
        role,
        totalSales,
        totalProfit,
        commission,
      };
    });

    // After calculating for all employees, assign the total sales and profit of salespeople to the shop manager
    employeeSalesData.forEach(employee => {
      if (employee.role === 'shopmanager') {
        employee.commission = (0.02 * totalProfitBySalespeople).toFixed(2);
        employee.totalSales += totalSalesBySalespeople;  // Shop manager gets total sales from salespeople
        employee.totalProfit += totalProfitBySalespeople;  // Shop manager gets total profit from salespeople
       
      }
    });

    // 6. Return the sales data for all employees
    return employeeSalesData;
  } catch (error) {
    console.error("Error fetching employee sales:", error);
    throw new Error("Unable to fetch employee sales. Please try again later.");
  }
};




export const getTopSellingProducts = async () => {
  try {
    const assignedShop = await getAssignedShop();
    const creditSalesQuery = query(collection(db, `sales/${assignedShop}/credit_sales`));
    const mpesaSalesQuery = query(collection(db, `sales/${assignedShop}/mpesa_sales`));

    const [creditSalesSnapshot, mpesaSalesSnapshot] = await Promise.all([
      getDocs(creditSalesQuery),
      getDocs(mpesaSalesQuery),
    ]);

    const productMap = new Map();

    creditSalesSnapshot.forEach(doc => {
      const sale = doc.data();
      sale.products.forEach(item => {
        const { name, type, quantity,price } = item;
        if (!productMap.has(name)) {
          productMap.set(name, { type, name, sold: 0 });
        }
        productMap.get(name).sold += (quantity * price);
      });
    });

    mpesaSalesSnapshot.forEach(doc => {
      const sale = doc.data();
      sale.products.forEach(item => {
        const { name, type, quantity,price } = item;
        if (!productMap.has(name)) {
          productMap.set(name, { type, name, sold: 0 });
        }
        productMap.get(name).sold += (quantity * price);
      });
    });

    const topSellingProducts = Array.from(productMap.values())
      .sort((a, b) => b.sold - a.sold)
      .slice(0, 5);

    return topSellingProducts;
  } catch (error) {
    console.error("Error fetching top-selling products:", error);
    throw new Error("Unable to fetch top-selling products. Please try again later.");
  }
};



