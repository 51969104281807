import React, { useState, useEffect, useMemo } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../firebase';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Tooltip } from '@mui/material';

const SalesReturnReports = () => {
  const [salesReturns, setSalesReturns] = useState([]);
  const [filteredReturns, setFilteredReturns] = useState([]);
  const [shop, setShop] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [shops, setShops] = useState([]);
  const [role, setRole] = useState('');
  const [assignedShop, setAssignedShop] = useState('');

  useEffect(() => {
    const loadShops = async () => {
      const shopListSnapshot = await getDocs(collection(db, 'shoplist'));
      const fetchedShops = shopListSnapshot.docs.map(doc => doc.data().shopName);
      setShops(fetchedShops);
    };

    const loadSalesReturns = async () => {
      try {
        const data = await fetchSalesReturnData();
        setSalesReturns(data);
        setFilteredReturns(data);
      } catch (error) {
        console.error("Error fetching sales return data:", error);
      }
    };

    loadShops();
    loadSalesReturns();
  }, []);

  useEffect(() => {
    const fetchUserRoleAndShop = async () => {
      const user = auth.currentUser;
      const email = user.email;

      const employeeQuery = query(collection(db, 'employees'), where('email', '==', email));
      const employeeSnapshot = await getDocs(employeeQuery);

      if (!employeeSnapshot.empty) {
        const userData = employeeSnapshot.docs[0].data();
        setRole(userData.role);
        setAssignedShop(userData.assignedShop);
        if (userData.role === 'shopmanager') {
          setShop(userData.assignedShop);
        }
      } else {
        throw new Error('User not found');
      }
    };

    fetchUserRoleAndShop();
  }, []);

  const fetchSalesReturnData = async () => {
    const user = auth.currentUser;
    const email = user.email;

    const employeeQuery = query(collection(db, 'employees'), where('email', '==', email));
    const employeeSnapshot = await getDocs(employeeQuery);
    let salesReturns = [];

    if (!employeeSnapshot.empty) {
      const userData = employeeSnapshot.docs[0].data();
      const role = userData.role;
      const assignedShop = userData.assignedShop;

      if (role === 'admin') {
        const shopListSnapshot = await getDocs(collection(db, 'shoplist'));
        const shops = shopListSnapshot.docs.map(doc => doc.data().shopName);

        for (const shop of shops) {
          const salesReturnQuery = collection(db, `sales/${shop}/sales_return`);
          const salesReturnSnapshot = await getDocs(salesReturnQuery);
          salesReturnSnapshot.forEach((doc) => {
            salesReturns.push({ ...doc.data(), shopName: shop });
          });
        }
      } else if (role === 'shopmanager') {
        const salesReturnQuery = collection(db, `sales/${assignedShop}/sales_return`);
        const salesReturnSnapshot = await getDocs(salesReturnQuery);
        salesReturnSnapshot.forEach((doc) => {
          salesReturns.push({ ...doc.data(), shopName: assignedShop });
        });
      }
    }
    return salesReturns;
  };

  const handleFilter = () => {
    const filtered = salesReturns.filter(ret => {
      const matchesShop = shop === '' || ret.shopName === shop;
      const matchesDateRange =
        (startDate === '' || ret.returnDate >= startDate) &&
        (endDate === '' || ret.returnDate <= endDate);
      const matchesSearchTerm = ret.name.toLowerCase().includes(searchTerm.toLowerCase());

      return matchesShop && matchesDateRange && matchesSearchTerm;
    });

    setFilteredReturns(filtered);
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(14);
    doc.text('Cowtrack Ltd', 10, 10);
    doc.setFontSize(12);
    doc.text(`Sales Return Report`, 10, 20);
    doc.text(`For: ${startDate} to ${endDate}`, 10, 30);
    doc.text(`Shop: ${shop || 'All Shops'}`, 10, 40);

    const columns = [
      'Customer Category',
      'Category',
      'Product',
      'Serial Number',
      'Receipt No',
      'Selling Price',
      'Return Reason',
      'Return By',
      'Date',
    ];

    const data = filteredReturns.map((ret) => [
      ret.customerCategory,
      ret.category,
      ret.name,
      ret.serial,
      ret.receiptNumber,
      ret.price,
      ret.returnReason,
      ret.returnBy,
      ret.returnDate,
    ]);

    doc.autoTable({
      startY: 50,
      head: [columns],
      body: data,
    });

    doc.save('sales_return_reports.pdf');
  };

  const exportExcel = () => {
    const data = [
      [
        'Customer Category',
        'Category',
        'Product',
        'Serial Number',
        'Receipt No',
        'Selling Price',
        'Return Reason',
        'Return By',
        'Date',
      ],
      ...filteredReturns.map((ret) => [
        ret.customerCategory,
        ret.category,
        ret.name,
        ret.serial,
        ret.receiptNumber,
        ret.price,
        ret.returnReason,
        ret.returnBy,
        ret.returnDate,
      ]),
      ['', '', '', '', 'Total Returns', filteredReturns.reduce((total, ret) => total + ret.sellingPrice, 0), '', '', ''],
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales Return Reports');
    XLSX.writeFile(workbook, 'sales_return_reports.xlsx');
  };

  const cellStyle = params => {
    if (params.data.shopName === 'kathurine') {
      return { backgroundColor: '#F0F8FF' };
    } else if (params.data.shopName === 'k-west') {
      return { backgroundColor: '#FFFACD' };
    } else {
      return { backgroundColor: '#F5F5F5' };
    }
  };

  const columnDefs = useMemo(() => [
    { headerName: 'Customer Category', field: 'customerCategory', sortable: true, filter: true, width: 150, cellStyle },
    { headerName: 'Category', field: 'category', sortable: true, filter: true, width: 120, cellStyle },
    { headerName: 'Product', field: 'name', sortable: true, filter: true, width: 150, cellStyle },
    { headerName: 'Serial Number', field: 'serial', sortable: true, filter: true, width: 130, cellStyle },
    { headerName: 'Receipt No', field: 'receipt', sortable: true, filter: true, width: 120, cellStyle },
    { headerName: 'Selling Price', field: 'price', sortable: true, filter: true, width: 120, cellStyle },
    { headerName: 'Return Reason', field: 'returnReason', sortable: true, filter: true, width: 150, cellStyle },
    { headerName: 'Return By', field: 'returnBy', sortable: true, filter: true, width: 120, cellStyle },
    { headerName: 'Date', field: 'returnDate', sortable: true, filter: true, width: 120, cellStyle },
  ], []);

  return (
    <div className="container mt-5">
      <header className="text-center mb-4">
        <h2 className="fw-bold">Cowtrack Ltd - Sales Return Reports</h2>
        <p className="text-muted">From: {startDate} To: {endDate}</p>
        <p className="text-muted">Shop: {role === 'shopmanager' ? assignedShop : (shop || 'All Shops')}</p>
      </header>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <input
          type="date"
          className="form-control me-2"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          placeholder="From Date"
        />
        <input
          type="date"
          className="form-control me-2"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          placeholder="To Date"
        />
        {role === 'admin' && (
          <select
            className="form-select me-2"
            value={shop}
            onChange={(e) => setShop(e.target.value)}
          >
            <option value="">All Shops</option>
            {shops.map((shop) => (
              <option key={shop} value={shop}>{shop}</option>
            ))}
          </select>
        )}
        <input
          type="text"
          className="form-control"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search Product"
        />
        <button className="btn btn-primary" onClick={handleFilter}>
          Filter
        </button>
      </div>

      <div
        className="ag-theme-alpine"
        style={{ height: 400, width: '100%' }}
      >
        <AgGridReact
          rowData={filteredReturns}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true,
          }}
        />
      </div>

      <div className="d-flex justify-content-end mt-3">
        <Tooltip title="Export to PDF">
          <button className="btn btn-danger me-2" onClick={exportPDF}>
            <i className="fas fa-file-pdf"></i>
          </button>
        </Tooltip>
        <Tooltip title="Export to Excel">
          <button className="btn btn-success" onClick={exportExcel}>
            <i className="fas fa-file-excel"></i>
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default SalesReturnReports;
