import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import App from './App';

const theme = createTheme({
  palette: {
    background: {
      paper: '#ffffff', // Set a default background color for paper
    },
    divider: '#e0e0e0', // Set a default divider color
  },
  // Other theme settings if needed
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);

