// dataService.js
import { collection, getDocs, query, where, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { startOfMonth, endOfMonth } from 'date-fns'; 

// Fetch the list of all shops
export const getShopList = async () => {
  try {
    const shopsSnapshot = await getDocs(collection(db, 'shoplist'));
    const shops = shopsSnapshot.docs.map(doc => doc.data().shopName);
    return shops;
  } catch (error) {
    console.error('Error fetching shop list:', error);
    throw new Error('Failed to fetch shop list.');
  }
};

// Fetch all employees for a given shop
export const getEmployeesData = async () => {
  try {
    const employeesSnapshot = await getDocs(collection(db, `employees`));
    const employees = employeesSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    return employees;
  } catch (error) {
    console.error(`Error fetching employees :`, error);
    throw new Error(`Failed to fetch employees.`);
  }
};
// Fetch sales data for all shops
export const getSalesPeopleSales = async () => {
    try {
      // 1. Fetch the list of all shops and employees
      const [shopList, employeeData] = await Promise.all([
        getShopList(), // Fetch shop list in parallel
        getEmployeesData(), // Fetch employees data in parallel
      ]);
  
      // Get the current month date range using date-fns
      const startOfMonthDate = startOfMonth(new Date());
      const endOfMonthDate = endOfMonth(new Date());
  
      const startOfMonthTimestamp = Timestamp.fromDate(startOfMonthDate);
      const endOfMonthTimestamp = Timestamp.fromDate(endOfMonthDate);
  
      // Create an object to track employee sales data and avoid duplicates
      const employeeSalesMap = {};
  
      // 2. Create queries for fetching sales for all shops in one batch
      const salesPromises = shopList.map((shop) => {
        const creditSalesQuery = query(
          collection(db, `sales/${shop}/credit_sales`),
          where('saleTimestamp', '>=', startOfMonthTimestamp),
          where('saleTimestamp', '<=', endOfMonthTimestamp)
        );
        const mpesaSalesQuery = query(
          collection(db, `sales/${shop}/mpesa_sales`),
          where('saleTimestamp', '>=', startOfMonthTimestamp),
          where('saleTimestamp', '<=', endOfMonthTimestamp)
        );
  
        // Fetch both credit and Mpesa sales for the current shop in parallel
        return Promise.all([getDocs(creditSalesQuery), getDocs(mpesaSalesQuery)]).then(
          ([creditSalesSnapshot, mpesaSalesSnapshot]) => ({
            shop,
            salesDocs: [...creditSalesSnapshot.docs, ...mpesaSalesSnapshot.docs], // Combine both types of sales
          })
        );
      });
  
      // 3. Wait for all sales data to be fetched for all shops
      const shopSalesData = await Promise.all(salesPromises);
  
      // 4. Process each shop's sales
      shopSalesData.forEach(({ shop, salesDocs }) => {
        // Filter out employees for the current shop
        const employeesInShop = employeeData.filter(employee => employee.assignedShop === shop);
  
        // Process sales for each employee within this shop
        employeesInShop.forEach((employee) => {
          const { name, role, id } = employee;
  
          let totalSales = 0;
          let totalProfit = 0;
  
          // Filter and process sales by salesperson
          salesDocs
            .filter((doc) => doc.data().salesperson === name)
            .forEach((doc) => {
              const sale = doc.data();
              sale.products.forEach(({ price, buyingprice, quantity }) => {
                const saleValue = price * quantity;
                totalSales += saleValue;
                totalProfit += (price - buyingprice) * quantity;
              });
            });
  
          // If employee already exists in the sales map, accumulate their sales and profit
          if (employeeSalesMap[id]) {
            employeeSalesMap[id].totalSales += totalSales;
            employeeSalesMap[id].totalProfit += totalProfit;
          } else {
            // Otherwise, initialize their sales and profit
            employeeSalesMap[id] = {
              name,
              role,
              totalSales,
              totalProfit,
              commission: role === 'salesperson' ? (0.10 * totalProfit).toFixed(2) : 0,
              shop, // Attach the shop name for reference
            };
          }
        });
      });
  
      // 5. Adjust for shop managers after accumulating sales from their assigned shop's salespeople
      Object.values(employeeSalesMap).forEach((employee) => {
        if (employee.role === 'shopmanager') {
          // Find all salespeople for this shop
          const shopSalespeople = Object.values(employeeSalesMap).filter(
            e => e.shop === employee.shop && e.role === 'salesperson'
          );
  
          // Sum the total sales and profit from salespeople in this shop
          const totalSalesBySalespeople = shopSalespeople.reduce(
            (acc, salesperson) => acc + salesperson.totalSales,
            0
          );
          const totalProfitBySalespeople = shopSalespeople.reduce(
            (acc, salesperson) => acc + salesperson.totalProfit,
            0
          );
  
          // Assign the accumulated sales and profit from salespeople to the shopmanager
          employee.totalSales += totalSalesBySalespeople;
          employee.totalProfit += totalProfitBySalespeople;
          employee.commission = (0.02 * totalProfitBySalespeople).toFixed(2);
        }
      });
  
      // 6. Return the final sales data (convert map to an array)
      return Object.values(employeeSalesMap);
    } catch (error) {
      console.error('Error fetching employee sales:', error);
      throw new Error('Unable to fetch employee sales. Please try again later.');
    }
  };
  
  
  

