import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  Alert,
} from '@mui/material';
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { db } from '../firebase'; // Adjust the path according to your file structure

function AddCategoryPage() {
  const [category, setCategory] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (category.trim() === '') {
      setErrorMessage('Category name cannot be empty');
      setSuccessMessage('');
    } else {
      try {
        const q = query(collection(db, "categories"), where("name", "==", category));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          setErrorMessage(`Category "${category}" already exists`);
          setSuccessMessage('');
        } else {
          await addDoc(collection(db, "categories"), {
            name: category,
          });
          setSuccessMessage(`Category "${category}" added successfully!`);
          setErrorMessage('');
          setCategory('');
        }
      } catch (error) {
        setErrorMessage('Error adding category: ' + error.message);
        setSuccessMessage('');
      }
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh', backgroundColor: '#f7f7f7' }}>
      <Grid item xs={10} sm={8} md={6}>
        <Paper elevation={4} style={{ padding: '40px', borderRadius: '12px' }}>
          <Typography variant="h4" gutterBottom align="center" style={{ color: '#388E3C' }}>
            Add Category
          </Typography>
          {successMessage && (
            <Alert severity="success" style={{ marginBottom: '16px' }}>
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="error" style={{ marginBottom: '16px' }}>
              {errorMessage}
            </Alert>
          )}
          <form onSubmit={handleSubmit}>
            <Box marginBottom={3}>
              <TextField
                label="Category Name"
                variant="outlined"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                fullWidth
                required
                InputLabelProps={{
                  style: { color: '#23CA2C' },
                }}
                InputProps={{
                  style: { borderColor: '#0BEB16' },
                }}
                focused
              />
            </Box>
            <Button type="submit" variant="contained" fullWidth style={{ backgroundColor: '#388E3C', color: 'white' }}>
              Add Category
            </Button>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AddCategoryPage;

