import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { db } from '../firebase';

const WeeklyReportsPage = () => {
  const [selectedDate, setSelectedDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [productsSold, setProductsSold] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Function to calculate the Monday and Sunday of the selected week
  const calculateWeekRange = (date) => {
    const current = new Date(date);
    if (isNaN(current)) {
      console.error("Invalid date provided for week calculation.");
      return;
    }
    const day = current.getDay(); // Sunday - Saturday : 0 - 6

    // Adjusting to get Monday (day 1) of the week
    const monday = new Date(current);
    monday.setDate(current.getDate() - ((day + 6) % 7));

    // Adjusting to get Sunday (day 7) of the week
    const sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);

    setStartDate(monday.toISOString().split('T')[0]);
    setEndDate(sunday.toISOString().split('T')[0]);
  };

  useEffect(() => {
    const fetchSalesData = async () => {
      const salesData = [];
      const creditSalesData = [];

      try {
        // Fetch sales data
        const salesSnapshot = await db.collection('sales').get();
        salesSnapshot.forEach(doc => {
          salesData.push({ id: doc.id, ...doc.data() });
        });

        // Fetch credit_sales data
        const creditSalesSnapshot = await db.collection('credit_sales').get();
        creditSalesSnapshot.forEach(doc => {
          creditSalesData.push({ id: doc.id, ...doc.data() });
        });

        // Combine both sales and credit_sales data
        const combinedData = [...salesData, ...creditSalesData];
        setProductsSold(combinedData);
      } catch (error) {
        console.error("Error fetching sales data: ", error);
      }
    };

    fetchSalesData();
  }, []);

  useEffect(() => {
    const filterProducts = () => {
      const filtered = productsSold.filter(product => {
        const saleDate = product.saleTimestamp.toDate(); // Convert db Timestamp to Date
        const saleDateString = saleDate.toISOString().split('T')[0];
        return product.products.some(p => p.name.toLowerCase().includes(searchTerm.toLowerCase())) &&
               saleDateString >= startDate &&
               saleDateString <= endDate;
      });
      setFilteredProducts(filtered);
    };

    if (startDate && endDate) {
      filterProducts();
    }
  }, [productsSold, startDate, endDate, searchTerm]);

  const totalSales = filteredProducts.reduce((total, product) => {
    return total + product.products.reduce((subtotal, p) => subtotal + (p.price * p.quantity), 0);
  }, 0);

  const printReport = () => {
    window.print();
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text("Weekly Sales", 10, 10);
    let yPosition = 20;
    filteredProducts.forEach((product, index) => {
      product.products.forEach((p, i) => {
        doc.text(`${index + 1}.${i + 1} ${product.saleTimestamp.toDate().toISOString().split('T')[0]} - ${p.category} - ${p.name} - ${p.serial} - ${p.quantity} - $${p.price}`, 10, yPosition);
        yPosition += 10;
      });
    });
    doc.text(`Total Sales: $${totalSales}`, 10, yPosition + 10);
    doc.save("weekly_report.pdf");
  };

  const exportExcel = () => {
    const data = [
      ["Date", "Category", "Name", "Serial Number", "Quantity", "Price"],
      ...filteredProducts.flatMap(product =>
        product.products.map(p => [
          product.saleTimestamp.toDate().toISOString().split('T')[0],
          p.category, p.name, p.serial, p.quantity, p.price
        ])
      ),
      ["", "", "", "", "Total Sales", totalSales]
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Weekly Sales");
    XLSX.writeFile(workbook, "weekly_report.xlsx");
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    setSelectedDate(date);
    calculateWeekRange(date);
  };

  return (
    <div className="container">
      <header className="d-flex justify-content-between align-items-center my-3">
        <h1>Weekly Sales</h1>
        <div>
          <button className="btn btn-primary mx-1" onClick={printReport}>
            <i className="fas fa-print"></i> Print
          </button>
          <button className="btn btn-danger mx-1" onClick={exportPDF}>
            <i className="fas fa-file-pdf"></i> Export as PDF
          </button>
          <button className="btn btn-success mx-1" onClick={exportExcel}>
            <i className="fas fa-file-excel"></i> Export as Excel
          </button>
        </div>
      </header>
      <div className="mb-3 d-flex">
        <input
          type="date"
          className="form-control me-2"
          value={selectedDate}
          onChange={handleDateChange}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Search products..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>
      <main>
        <section id="reportSection">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Category</th>
                <th>Name</th>
                <th>Serial Number</th>
                <th>Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.flatMap((product, index) =>
                product.products.map((p, i) => (
                  <tr key={`${index}-${i}`}>
                    <td>{index + 1}.{i + 1}</td>
                    <td>{product.saleTimestamp.toDate().toISOString().split('T')[0]}</td>
                    <td>{p.category}</td>
                    <td>{p.name}</td>
                    <td>{p.serial}</td>
                    <td>{p.quantity}</td>
                    <td>{p.price}</td>
                  </tr>
                ))
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="6" className="text-end"><strong>Total Sales:</strong></td>
                <td><strong>{totalSales}</strong></td>
              </tr>
            </tfoot>
          </table>
        </section>
      </main>
      <footer className="text-center mt-4">
        <p>&copy; 2024 Weekly Reports</p>
      </footer>
    </div>
  );
};

export default WeeklyReportsPage;
