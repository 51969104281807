import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { Box, CssBaseline, TextField, Button, MenuItem, Typography, Tooltip, IconButton } from '@mui/material';

const DevicesPurchases = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [categories, setCategories] = useState(['All']);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [shops, setShops] = useState(['All']);
  const [selectedShop, setSelectedShop] = useState('All');
  const [totalPurchases, setTotalPurchases] = useState(0);

  const columns = [
    { name: "id", label: "#" },
    { name: "shop", label: "Shop" },
    {
      name: "receivedDate",
      label: "Received Date",
      options: {
        customBodyRender: (value) => {
          if (value) {
            const date = new Date(value.seconds * 1000);
            return date.toLocaleDateString();
          }
          return "";
        },
      },
    },
    { name: "category", label: "Category" },
    { name: "productName", label: "Product" },
    { name: "buyingPrice", label: "Buying Price" },
    { name: "sellingPrice", label: "Selling Price" },
    { name: "totalItems", label: "Items Received" },
  ];

  const fetchCategories = async () => {
    const querySnapshot = await getDocs(collection(db, "categories"));
    const categoryData = querySnapshot.docs.map((doc) => doc.data().name);
    setCategories(['All', ...categoryData]);
  };

  const fetchShops = async () => {
    const querySnapshot = await getDocs(collection(db, "shops"));
    const shopData = querySnapshot.docs.map((doc) => doc.data().name);
    setShops(['All', ...shopData]);
  };

  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(db, "inventory"));
    const productData = querySnapshot.docs.map((doc, index) => ({
      id: index + 1,
      docId: doc.id,
      ...doc.data(),
    }));
    setData(productData);
    calculateTotals(productData);
  };

  const calculateTotals = (products) => {
    const filteredProducts = products
      .filter((item) => {
        const receivedDate = new Date(item.receivedDate.seconds * 1000);
        return (
          (!startDate || receivedDate >= new Date(startDate)) &&
          (!endDate || receivedDate <= new Date(endDate)) &&
          (selectedShop === 'All' || item.shop === selectedShop)
        );
      })
      .filter((item) => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return (
          item.id.toString().includes(lowerCaseSearchTerm) ||
          item.shop.toLowerCase().includes(lowerCaseSearchTerm) ||
          new Date(item.receivedDate.seconds * 1000).toLocaleDateString().includes(lowerCaseSearchTerm) ||
          item.category.toLowerCase().includes(lowerCaseSearchTerm) ||
          item.productName.toLowerCase().includes(lowerCaseSearchTerm) ||
          item.buyingPrice.toString().includes(lowerCaseSearchTerm) ||
          item.sellingPrice.toString().includes(lowerCaseSearchTerm) ||
          item.totalItems.toString().includes(lowerCaseSearchTerm)
        );
      });

    const total = filteredProducts.reduce((sum, item) => sum + ((item.buyingPrice * item.totalItems) || 0), 0);

    setFilteredData(filteredProducts);
    setTotalPurchases(total);
  };

  const handleStartDateChange = (e) => {
    const dateValue = e.target.value;
  
    if (dateValue) {
      // Split the input into year, month, and day
      const [year, month, day] = dateValue.split('-');
      // Create a date object in UTC
      const startDate = new Date(Date.UTC(year, month - 1, day, 0, 0, 0)); // Month is 0-based
      setStartDate(startDate); // Set the start date
    } else {
      setStartDate(null);
    }
  };
  
  const handleEndDateChange = (e) => {
    const date = e.target.value ? new Date(e.target.value) : null;
    
    if (date) {
      // Manually set the date to 23:59:59 without shifting due to timezones
      const localDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
      setEndDate(localDate); // End of the selected day
    } else {
      setEndDate(null);
    }
  };

  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text(`Cowtrack LT (${selectedShop})`, 10, 10);
    doc.text(`Purchase Report from ${startDate} to ${endDate}`, 10, 20);
    doc.text(`Total Purchases: ${totalPurchases}`, 10, 30);

    const columnsPDF = columns.map((col) => col.label).slice(0, -1); // Exclude the last "Actions" column
    const rowsPDF = filteredData.map((row) => [
      row.id,
      row.shop,
      new Date(row.receivedDate.seconds * 1000).toLocaleDateString(),
      row.category,
      row.productName,
      row.buyingPrice,
      row.sellingPrice,
      row.totalItems,
    ]);

    doc.autoTable({
      head: [columnsPDF],
      body: rowsPDF,
      margin: { top: 40 },
      styles: { borderColor: '#000000' },
    });

    doc.save(`Purchase_Report_${selectedShop}_${startDate}_to_${endDate}.pdf`);
  };

  const exportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      filteredData.map((row) => ({
        "#": row.id,
        Shop: row.shop,
        "Received Date": new Date(row.receivedDate.seconds * 1000).toLocaleDateString(),
        Category: row.category,
        Product: row.productName,
        "Buying Price": row.buyingPrice,
        "Selling Price": row.sellingPrice,
        "Items Received": row.totalItems,
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Purchases");
    XLSX.writeFile(wb, `Purchase_Report_${selectedShop}_${startDate}_to_${endDate}.xlsx`);
  };

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    download: false,
    print: false,
    viewColumns: false,
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 20, 50, 100],
    fixedHeader: true,
    customToolbar: () => (
      <React.Fragment>
        <Tooltip title="Download PDF">
          <IconButton onClick={exportPDF} >
            <i className="fas fa-file-pdf" style={{ color: "red" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download Excel">
          <IconButton onClick={exportExcel}>
            <i className="fas fa-file-excel" style={{ color: "green" }} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    ),
  };

  useEffect(() => {
    fetchCategories();
    fetchShops();
    fetchData();
  }, []);

  useEffect(() => {
    calculateTotals(data);
  }, [startDate, endDate, searchTerm, selectedShop]);

  return (
    <Box sx={{ p: 2 }}>
      <CssBaseline />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <TextField
          type="date"
          label="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          type="date"
          label="End Date"
          value={endDate}
          onChange={handleEndDateChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          select
          label="Select Shop"
          value={selectedShop}
          onChange={(e) => setSelectedShop(e.target.value)}
        >
          {shops.map((shop) => (
            <MenuItem key={shop} value={shop}>
              {shop}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>
      <MUIDataTable
        title={"Devices Purchases"}
        data={filteredData}
        columns={columns}
        options={options}
      />
      <Typography variant="h8" gutterBottom>
        Total Purchases: {totalPurchases}
      </Typography>
    </Box>
  );
};

export default DevicesPurchases;
